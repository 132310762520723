import { EnumDocType, EnumDocDependencyType } from '../../types/enums'
import { EnumPropertyEditorType, MetaPropertyEditor } from '../../client-srv'

export enum EnumEditorType {
  INPUT,
  NUMERIC,
  DROPDOWN,
  DATETIME,
  DROPDOWN_EDITABLE
}
export enum EnumPropertyType {
  INPUT = 50,
  NUMERIC = 20,
  NUMBER = 10,
  DATETIME = 40,
  DROPDOWN = 41,
  DROPDOWN_EDITABLE = 42
}

/** 单值或列表 */
export type ValueOrArray<T> = T | Array<T>

/** 下拉列表选项 */
export type SelectOption<T extends ValueOrArray<string> | ValueOrArray<number>> = {
  label?: string
  value: T
}

/**
 * 根据编辑器配置获取编辑控件
 * @param editor
 * @returns
 */
export const getComponentInfoByEditor = (
  editor: MetaPropertyEditor
): { componentType: EnumEditorType; options: SelectOption<string>[] } => {
  if (!editor) {
    return { componentType: EnumEditorType.INPUT, options: [] }
  }

  let options: SelectOption<string>[] = []
  let componentType: EnumEditorType
  const editorArgsObj = JSON.parse(editor.editArgs || '{}')

  switch (editor.editType) {
    case EnumPropertyEditorType.SINGLE:
    case EnumPropertyEditorType.MULTI: {
      componentType = EnumEditorType.INPUT
      break
    }
    case EnumPropertyEditorType.DATETIME: {
      componentType = EnumEditorType.DATETIME
      break
    }
    case EnumPropertyEditorType.DROPDOWN_LIST:
    case EnumPropertyEditorType.DROPDOWN_LIST_WITH_DISPLAY:
    case EnumPropertyEditorType.LIST_VIEW:
    case EnumPropertyEditorType.LIST_VIEW_SINGLE_CHECK: {
      options = editorArgsObj.options
      componentType = EnumEditorType.DROPDOWN
      break
    }
    case EnumPropertyEditorType.DROPDOWN_LIST_EDITABLE: {
      options = editorArgsObj.options
      componentType = EnumEditorType.DROPDOWN_EDITABLE
      break
    }
    case EnumPropertyEditorType.OBJECT_DROPDOWN:
    case EnumPropertyEditorType.OBJECT_MODAL: {
      const refObjCodes = editorArgsObj.refObjCodes as string[]
      if (refObjCodes && refObjCodes.length > 0) {
        const refObjNameList = editorArgsObj.refObjNameList as string[]
        refObjCodes.map((refObjCode, index) => {
          if (refObjCode) {
            options.push({
              label: refObjCode,
              value: refObjNameList[index]
            })
          }
        })
      }
      componentType = EnumEditorType.DROPDOWN
      break
    }
    default: {
      componentType = EnumEditorType.INPUT
    }
  }

  return { componentType, options }
}

export const getComponentEditor = (modeCode: string, type: any, filed: any) => {
  let options: SelectOption<string>[] = []
  let componentType: EnumPropertyType
  switch (type) {
    case EnumPropertyType.DATETIME:
      componentType = EnumPropertyType.DATETIME
      break
    case (EnumPropertyType.NUMBER, EnumPropertyType.NUMBER):
      componentType = EnumPropertyType.NUMBER
      break
    default: {
      //特殊字段特殊处理
      const column = filed.columnName
      if (SpecialFiled.includes(column)) {
        if (modeCode == 'ValidationMetrics') {
          options = optionsMap.get('metricsType')! || []
        } else {
          options = optionsMap.get(column)! || []
        }

        componentType = EnumPropertyType.DROPDOWN
      } else {
        componentType = EnumPropertyType.INPUT
      }
      break
    }
  }
  return { componentType, options }
}
export const SpecialFiled = ['source', 'phase', 'assemblyMode', 'complexity', 'businessDecisionCategory', 'type', 'activityType']

//零部件-来源
export const sourceOptions = [
  { label: '自制', value: 'Make' },
  { label: '采购', value: 'Buy' },
  { label: '购买-单一供应源', value: 'Singlesource' }
]
//零部件-阶段
export const phaseOptions = [
  { label: '设计', value: 'D' },
  { label: '试制', value: 'T' },
  { label: '量产', value: 'S' },
  { label: '废弃', value: 'X' }
]
//零部件-装配模式
export const assemblyModeOptions = [
  { label: '组件', value: 'Component' },
  { label: '可分离', value: 'Separable' },
  { label: '不可分离', value: 'Inseparable' }
]
//文档-文档类型
export const documentTypeOptions = [
  { label: 'UG数模', value: EnumDocType.UG },
  { label: 'SW数模', value: EnumDocType.SW },
  { label: 'AUTOCAD图纸', value: EnumDocType.CAD },
  { label: '签字文件', value: EnumDocDependencyType.SIGNATURE },
  { label: '浏览文件', value: EnumDocDependencyType.BROWSE },
  { label: 'SW工程图文件', value: EnumDocDependencyType.SOLIDWORKS_2D },
  { label: 'Excel文件', value: EnumDocType.EXCEL },
  { label: 'Word文件', value: EnumDocType.WORD },
  { label: '其他文件', value: EnumDocType.OTHER }
]
//变更单-复杂度
export const complexityOptions = [
  { label: '低', value: 'LOW' },
  { label: '中', value: 'MEDIUM' },
  { label: '高', value: 'HIGH' },
  { label: '紧急', value: 'EMERGNCY' }
]
//变更请求-业务决别类型
export const businessDecisionCategoryOptions = [
  { label: '已批准', value: 'APPROVED' },
  { label: '调查', value: 'INVESTIGATE' },
  { label: '已否决', value: 'REJECTED' }
]
//变更类型
export const changeTypeOptions = [
  { label: '资料修正', value: 'DOCUMENTATION_CORRECTION' },
  { label: '设计变更', value: 'DESIGN_CHANGE' },
  { label: '零部件变更', value: 'COMPONENT_CHANGE' },
  { label: '其他', value: 'OTHER' }
]
//变更活动—活动类型
export const activityTypeOptions = [
  { label: '检查', value: 'CHECK' },
  { label: '评审', value: 'REVIEW' },
  { label: '校对', value: 'PROOFREAD' }
]
//验证指标类型
export const metricsTypeOptions = [
  { label: '可靠性', value: 'Reliability' },
  { label: '准确性', value: 'Accuracy' },
  { label: '需求满足度', value: 'RequirementSatisfaction' },
  { label: '一致性', value: 'Consistency' },
  { label: '可追溯性', value: 'Traceability' },
  { label: '性能', value: 'Performance' },
  { label: '稳定性', value: 'Stability' },
  { label: '可用性', value: 'Usability' }
]
//字段和候选项映射
export const optionsMap = new Map([
  ['source', sourceOptions],
  ['phase', phaseOptions],
  ['assemblyMode', assemblyModeOptions],
  ['complexity', complexityOptions],
  ['businessDecisionCategory', businessDecisionCategoryOptions],
  ['type', changeTypeOptions],
  ['activityType', activityTypeOptions],
  ['activityType', activityTypeOptions],
  ['metricsType', metricsTypeOptions]
])

import {
  BaseViewModel,
  EnumItemChangeCancelType,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KIssueReportEditEmitsType, KIssueReportEditPropType } from './interface'
import {
  Api,
  EnumObjClassManageToolStripOptions,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { ref, watch } from 'vue'
import moment from 'moment'
import { KChangeRequestViewModel } from '../../change-request'
import { WorkflowClientSrv } from '../../../../../ebf-workflow/src/client-srv'

/** KIssueReportEdit */
export default class KIssueReportEditViewModel extends BaseViewModel<KIssueReportEditEmitsType, KIssueReportEditPropType> {
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  /** 编辑对象参数 */
  taskParam = ref<ObjBusinessParam>()
  originFormData = ref<any>()
  toolStripOptions = ref<EnumObjClassManageToolStripOptions>(EnumObjClassManageToolStripOptions.ShowEdit)
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getIssueReportToolEditStripItems())

  /**
   * 是否在编辑模式
   */
  isEditMode = ref<boolean>(false)

  constructor(options: ViewModelOptions<KIssueReportEditPropType>) {
    super(options)
    this.taskParam.value = options.props.objParam

    watch(
      () => this.props.objParam,
      () => {
        this.refObjectProperty.value?.refresh()
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {}

  async loadData() {
    const result = (await Api.post('change', 'ChangeIssue', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      // 查询和维护属性不一致, 需要转换
      const item = result.data
      const plmAffectedTargetViews = item.plmAffectedTargetViews
      if (plmAffectedTargetViews != null && plmAffectedTargetViews.length > 0) {
        item.plmChangeIssueAffectedViews = plmAffectedTargetViews
        delete item.plmAffectedTargetViews
      }

      // 只有变更问题报告已发布时, 创建变更请求按钮才可用
      if (item.lifecycleStateCode === 'Released') {
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUEST_CHANGE, false)
      }
      // 20240629 审核中时不允许使用编辑属性和保存
      if (item.lifecycleStateCode === 'Released' || item.lifecycleStateCode === 'UnderReview') {
        // 20240628 已发布时不允许使用编辑属性和保存
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH, true)
        // this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
        // this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DISCARD, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, true)
      }
      if (item.needDate) {
        item.needDate = item.needDate.slice(0, 10)
      }

      this.originFormData.value = result.data
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取问题报告失败',
        details: result.detail
      })
      return
    }
  }

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const buttonName = event.name as EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE
    const method = this[buttonName]
    if (typeof method === 'function') {
      method.call(this, event)
    }
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY) {
      if (event.value) {
        this.refObjectProperty.value?.setReadOnly(false)
        this.isEditMode.value = true
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
      } else {
        if (await this.save(false)) {
          this.refObjectProperty.value?.setReadOnly(true)
          this.isEditMode.value = false
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        }
      }
    }
  }

  /** 保存 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE](event: ToolStripItemClickedEventArgs) {
    if (this.isEditMode.value) {
      // 编辑模式下可以保存
      this.save()
    } else {
      KNotification.warn({
        message: '系统提示',
        description: '非编辑模式'
      })
    }
  }

  /** 刷新 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH](event: ToolStripItemClickedEventArgs) {
    this.refObjectProperty.value?.refresh()
  }

  /** 加入流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.addWorkflow(
      this.taskParam.value!.id,
      'ChangeIssue',
      this.originFormData.value.lifecycleStateCode == 'Released' ? 'Released' : '',
      this.originFormData.value.name,
      this.originFormData.value.number
    )
  }

  /** 查看流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.viewWorkflow(this.taskParam.value!.id, 'ChangeIssue')
  }

  /** 创建变更请求 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUEST_CHANGE](event: ToolStripItemClickedEventArgs) {
    KChangeRequestViewModel.prototype.showCreateFromChangeIssueEdit({
      folder: {
        id: this.originFormData.value.folder?.id
      },
      changeIssueViews: [
        {
          id: this.originFormData.value.id,
          rdmExtensionType: this.originFormData.value.rdmExtensionType
        }
      ],
      // 20240627 从变更问题报告编辑界面操作时, 需要继承受影响对象清单
      plmChangeIssueAffectedViews: this.getValue()?.plmChangeIssueAffectedViews ?? []
    })
  }

  /** 校验 */
  async validate() {
    try {
      // TODO  变更编辑验证
      return await this.refObjectProperty.value?.validate()
    } catch (error) {
      return false
    }
  }

  public getValue() {
    let editObject = this.refObjectProperty.value?.getValue()
    // 类型
    // const rdmExtensionType = this.rdmExtensionType.value
    editObject = { ...editObject } as any

    return editObject
  }

  public async save(isSave: boolean = true) {
    if (await this.validate()) {
      const modifiedValue = this.refObjectProperty.value?.getModifiedValue() as any
      const value = this.refObjectProperty.value?.getValue() as any
      if (Object.keys(modifiedValue).length) {
        if (Object.keys(modifiedValue).includes('secondary')) {
          modifiedValue.secondaryIds = [...modifiedValue.secondary]
          Reflect.deleteProperty(modifiedValue, 'secondary')
        }

        if (Reflect.has(value, 'plmChangeIssueAffectedViews')) {
          modifiedValue!.plmChangeIssueAffectedViews = (value!.plmChangeIssueAffectedViews as any[]).map(item => {
            return {
              target: {
                id: item.id,
                rdmExtensionType: item.rdmExtensionType
              }
            }
          })
        }

        const params = {
          data: [
            {
              id: this.props.objParam.id,
              ...modifiedValue
            }
          ]
        }

        const result = await Api.post('change', 'ChangeIssue', 'update', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '编辑成功'
          })
          return true
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '编辑失败',
            details: result.detail
          })
          return false
        }
      } else {
        if (isSave)
          KNotification.warn({
            message: '系统提示',
            description: '没有修改数据'
          })
        return true
      }
    } else {
      return false
    }
  }
}

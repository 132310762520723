import { Agent } from '@kmsoft/ebf-common'
import { DpMsgHandler } from '@kmsoft/ebf-doc/src/client-agent'
import { AppRunner, EnumApplicationRunnerExecutionOpportunity, utils } from '@kmsoft/upf-core'

/**
 * 协调插件独立弹窗准备插件
 * 1、启动代理
 * 2、遮罩
 */
export class CooperativePluginPrepareRunner implements AppRunner {
  /**
   * 执行时机是挂载之后
   * @returns
   */
  opportunity(): EnumApplicationRunnerExecutionOpportunity {
    return EnumApplicationRunnerExecutionOpportunity.AFTER_MOUNT
  }
  run(): Promise<void> {
    return new Promise(resolve => {
      const actionTypeObject = utils.getQueryObj(window.location.href, 'actionType')
      if (actionTypeObject) {
        const mask = document.getElementById('maskLayer') as HTMLElement
        if (mask) {
          mask.style.display = 'flex'
        }
        const actionType = actionTypeObject['actionType']
        switch (actionType) {
          case 'startAgent':
            // alert('代理启动中...')
            // 启动代理
            Agent.AgentManager.initialize().then(() => {
              resolve(void 0)
            })
            break
          case 'registerDpMsg':
            //alert('代理启动并注册dpMsg...')
            // 启动代理并注册DpMsg
            Agent.AgentManager.initialize(utils.getQueryObj(window.location.href, 'agentId')?.agentId)
              .then(() => {
                DpMsgHandler.register()
              })
              .then(() => {
                resolve(void 0)
              })
            break
          default:
            console.log('匹配不到任何行为')
            mask.style.display = 'none'
            break
        }
      }
      resolve(void 0)
    })
  }
}

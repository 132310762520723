import label from '@kmsoft/upf-core/src/web-controls/label'

/** 选中行的移动操作类型 */
export enum EnumRowMoveOperationType {
  TO_PREV = 'to_prev',
  TO_NEXT = 'to_next',
  TO_FIRST = 'to_first',
  TO_LAST = 'to_last'
}

/** 数据类型 */
export enum EnumClsDataType {
  /**未知 */
  UNKNOWN = -1,
  /** 整数 */
  INTEGER = 10,
  /** 浮点型 */
  FLOAT = 20,
  /** 长整型 */
  LONG_INTEGER = 30,
  /** 日期 */
  DATE = 40,
  /** 字符 */
  CHARACTER = 50,
  /** 图片 */
  // IMG = 60,
  /** 大文本 */
  TEXT = 70,
  /** 对象/引用属性 */
  OBJ = 80,
  /** 分类 */
  // CLASSIFY = 90,
  /** 枚举 */
  ENUMS = 100,
  USER = 110,
  /** 文件 **/
  FILE = 120
}

/** 编辑类型 */
export enum EnumClsEditorType {
  /**未知 */
  UNKNOWN = -1,
  /** 单行编辑框 */
  SINGLE_LINE_EDITOR = 1,
  /** 多行编辑框 */
  MULTI_LINE_EDITOR = 2,
  /** 日期编辑框 */
  DATE_EDITOR = 3,
  /** 图片编辑框 */
  // IMG_EDITOR = 4,
  /** 下拉列表框, label = value */
  SELECT_EDITOR = 5,
  /** 可编辑下拉列表框 */
  SELECT_EDITABLE_EDITOR = 6,
  /** 带显示值下拉列表框  */
  SELECT_VALUED_EDITOR = 7,
  /** 列表视图 */
  LIST_EDITOR = 8,
  /** 单选列表视图 */
  LIST_SINGLE_EDITOR = 9,
  /** 资源管理器 */
  // RESOURCE_EDITOR = 10,
  /** 编码器 */
  ENCODERS_EDITOR = 11,
  /** 选择填写 */
  // CHOOSE_WRITE_EDITOR = 12,
  /** 复选框 */
  CHECKBOX_EDITOR = 13,
  /** 刻度条 */
  // PROGRESS_EDITOR = 14,
  /** 超链接 */
  // LINK_EDITOR = 15,
  /** 自定义弹出对话框 */
  // DIALOG_EDITOR = 16,
  /** 自定义控件 */
  CUSTOM_EDITOR = 17,
  /** 自动计算 */
  // COMPUTED_EDITOR = 18,
  /** 公式计算 */
  // FORMULA_EDITOR = 19,
  /** 富文本框 */
  // RICH_TEXT_EDITOR = 20,
  /** 对象链接 */
  // OBJ_LINK_EDITOR = 21,
  /** 分类链接 */
  // CLASSIFY_EDITOR = 22,
  /** 对象选择器 */
  OBJ_SELECTOR = 23,
  /** 弹窗对象选择器 */
  DIALOG_OBJ_SELECTOR = 24,
  /**人员显示器 */
  CREATE_DISPLAY_EDITOR = 25,
  FOLDER_SELECTOR = 26,
  /**生命周期编辑器 */
  LIFECYCLE_EDITOR = 27,
  /**文件上传 */
  FILED_UPLOAD_EDITOR = 28,
  /**视图选择器 */
  VIEW_SELECTOR = 29,
  /**分类属性选择器 */
  CATEGORY_SELECTOR = 30,
  /**文件选择器(代理) */
  FILED_UPLOAD_AGENT_EDITOR = 31,
  /**对象类显示器 */
  OBJECT_DISPLAY = 32,
  /**数组编辑框 */
  NUMBER_EDITOR = 33,
  /**人员选择器 */
  USER_EDITOR = 34,
  /**基线主要对象选择器 */
  BASE_PRIMARY_OBJ_SELECTOR = 35,
  /**富文本编辑器 */
  RICH_TEXT_EDITOR = 36
}

/** 数据类型，对应的options */
export const allDataTypeConfig = {
  [EnumClsDataType.INTEGER]: {
    label: '整数',
    value: EnumClsDataType.INTEGER,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  [EnumClsDataType.FLOAT]: {
    label: '数值/高精度数/浮点型',
    value: EnumClsDataType.FLOAT,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  [EnumClsDataType.LONG_INTEGER]: {
    label: '长整型',
    value: EnumClsDataType.LONG_INTEGER,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  [EnumClsDataType.DATE]: {
    label: '日期',
    value: EnumClsDataType.DATE,
    dateDisplayOptions: [],
    defaultEditorType: EnumClsEditorType.DATE_EDITOR
  },
  [EnumClsDataType.CHARACTER]: {
    label: '字符',
    value: EnumClsDataType.CHARACTER,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  // [EnumClsDataType.IMG]: { label: '图片', value: EnumClsDataType.IMG, defaultEditorType: EnumClsEditorType.IMG_EDITOR },
  // [EnumClsDataType.TEXT]: { label: '文本', value: EnumClsDataType.TEXT, defaultEditorType: EnumClsEditorType.MULTI_LINE_EDITOR },
  [EnumClsDataType.OBJ]: {
    label: '对象',
    value: EnumClsDataType.OBJ,
    defaultEditorType: EnumClsEditorType.OBJ_SELECTOR
  },
  [EnumClsDataType.UNKNOWN]: {
    label: '分类',
    value: EnumClsDataType.UNKNOWN,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  [EnumClsDataType.ENUMS]: {
    label: '枚举',
    value: EnumClsDataType.ENUMS,
    defaultEditorType: EnumClsEditorType.SELECT_VALUED_EDITOR
  },
  [EnumClsDataType.TEXT]: {
    label: '大文本',
    value: EnumClsDataType.TEXT,
    defaultEditorType: EnumClsEditorType.MULTI_LINE_EDITOR
  },
  [EnumClsDataType.USER]: {
    label: '人员',
    value: EnumClsDataType.USER,
    defaultEditorType: EnumClsEditorType.USER_EDITOR
  },
  [EnumClsDataType.FILE]: {
    label: '文件夹选择',
    value: EnumClsDataType.FILE,
    defaultEditorType: EnumClsEditorType.FILED_UPLOAD_EDITOR
  }
}

/** 编辑类型，对应的options */
export const allEditorTypeConfig = {
  [EnumClsEditorType.SINGLE_LINE_EDITOR]: { label: '单行编辑框', value: EnumClsEditorType.SINGLE_LINE_EDITOR },
  [EnumClsEditorType.MULTI_LINE_EDITOR]: { label: '多行编辑框', value: EnumClsEditorType.MULTI_LINE_EDITOR },
  [EnumClsEditorType.DATE_EDITOR]: { label: '日期编辑框', value: EnumClsEditorType.DATE_EDITOR },
  // [EnumClsEditorType.IMG_EDITOR]: { label: '图片编辑框', value: EnumClsEditorType.IMG_EDITOR },
  [EnumClsEditorType.SELECT_EDITOR]: { label: '下拉列表框', value: EnumClsEditorType.SELECT_EDITOR },
  [EnumClsEditorType.SELECT_EDITABLE_EDITOR]: {
    label: '可编辑下拉列表框',
    value: EnumClsEditorType.SELECT_EDITABLE_EDITOR
  },
  [EnumClsEditorType.SELECT_VALUED_EDITOR]: { label: '带显示值下拉列表框', value: EnumClsEditorType.SELECT_VALUED_EDITOR },
  [EnumClsEditorType.LIST_EDITOR]: { label: '列表视图', value: EnumClsEditorType.LIST_EDITOR },
  [EnumClsEditorType.LIST_SINGLE_EDITOR]: { label: '单选列表视图', value: EnumClsEditorType.LIST_SINGLE_EDITOR },
  [EnumClsEditorType.CHECKBOX_EDITOR]: { label: '复选框', value: EnumClsEditorType.CHECKBOX_EDITOR },
  [EnumClsEditorType.CUSTOM_EDITOR]: { label: '自定义控件', value: EnumClsEditorType.CUSTOM_EDITOR },
  [EnumClsEditorType.OBJ_SELECTOR]: { label: '对象选择器', value: EnumClsEditorType.OBJ_SELECTOR },
  [EnumClsEditorType.DIALOG_OBJ_SELECTOR]: { label: '弹窗对象选择器', value: EnumClsEditorType.DIALOG_OBJ_SELECTOR },
  [EnumClsEditorType.USER_EDITOR]: { label: '人员选择器', value: EnumClsEditorType.USER_EDITOR },
  [EnumClsEditorType.FOLDER_SELECTOR]: { label: '文件夹选择器', value: EnumClsEditorType.FOLDER_SELECTOR },
  [EnumClsEditorType.LIFECYCLE_EDITOR]: { label: '生命周期编辑器', value: EnumClsEditorType.LIFECYCLE_EDITOR },
  [EnumClsEditorType.FILED_UPLOAD_EDITOR]: { label: '文件上传组件', value: EnumClsEditorType.FILED_UPLOAD_EDITOR },
  [EnumClsEditorType.VIEW_SELECTOR]: { label: '视图选择器', value: EnumClsEditorType.VIEW_SELECTOR },
  [EnumClsEditorType.CATEGORY_SELECTOR]: { label: '分类选择器', value: EnumClsEditorType.CATEGORY_SELECTOR },
  [EnumClsEditorType.ENCODERS_EDITOR]: { label: '编码器', value: EnumClsEditorType.ENCODERS_EDITOR },
  [EnumClsEditorType.FILED_UPLOAD_AGENT_EDITOR]: {
    label: '文件上传组件(代理)',
    value: EnumClsEditorType.FILED_UPLOAD_AGENT_EDITOR
  },
  [EnumClsEditorType.OBJECT_DISPLAY]: { label: '对象类显示器', value: EnumClsEditorType.OBJECT_DISPLAY },
  [EnumClsEditorType.NUMBER_EDITOR]: { label: '数字编辑框', value: EnumClsEditorType.NUMBER_EDITOR },
  [EnumClsEditorType.CREATE_DISPLAY_EDITOR]: { label: '人员显示器', value: EnumClsEditorType.CREATE_DISPLAY_EDITOR },
  [EnumClsEditorType.BASE_PRIMARY_OBJ_SELECTOR]: {
    label: '基线主要对象选择器',
    value: EnumClsEditorType.BASE_PRIMARY_OBJ_SELECTOR
  },
  [EnumClsEditorType.UNKNOWN]: { label: '单行编辑框', value: EnumClsEditorType.SINGLE_LINE_EDITOR },
  [EnumClsEditorType.RICH_TEXT_EDITOR]: { label: '富文本编辑框', value: EnumClsEditorType.RICH_TEXT_EDITOR }
}

/** 日期格式类型 */
export enum EnumDateFormat {
  'YY-MM-DD HH:mm:ss',
  'YY/MM/DD HH:mm:ss',
  'YY年MM月DD日HH时mm分ss秒',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY/MM/DD HH:mm:ss',
  'YYYY年MM月DD日HH时mm分ss秒',
  'YY-MM-DD',
  'YY/MM/DD',
  'YY年MM月DD日',
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'YYYY年MM月DD日',
  'HH:mm:ss',
  'HH时mm分ss秒'
}

/** 显示格式类型 */
export enum EnumDisplayType {
  'NORMAL',
  'NUMBER',
  'PERCENT',
  'SCIENCE'
}

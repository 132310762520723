import { AppContext, KNotification, request, utils } from '@kmsoft/upf-core'
import { ConfigClientSrv } from '../base'
import { EnumProtocolType } from './enums'
import {
  BatchUploadFileParams,
  CheckFileExistsParam,
  ChunkInfo,
  CopyFileParams,
  DownloadFileParams,
  FileInfo,
  FileUploadConfig,
  GetFileResult,
  RemoveFileParams,
  UploadFileDataParams,
  UploadFileParams
} from './types'

import lodash from 'lodash'
import { Agent } from '../..'

export class FileClientSrv {
  static model = 'file'

  /** 获取上传上传配置 */
  static getUploadConfig(): FileUploadConfig {
    return {
      chunkSize: ConfigClientSrv.getProperty<number>('fileUploadConfig.chunkSize'),
      threadCount: ConfigClientSrv.getProperty<number>('fileUploadConfig.threadCount'),
      uploadProtocol: ConfigClientSrv.getProperty<EnumProtocolType>('fileUploadConfig.uploadProtocol'),
      fileUploadServerUrl: ConfigClientSrv.getProperty<string>('fileUploadConfig.fileUploadServerUrl')
    }
  }

  /**
   * 文件存在的状态
   * @param params
   * @returns
   */
  static async getFileExistStatus(params: CheckFileExistsParam) {
    const url = this.getApiUrl('storage')
    return request.post(url, params) as any //FileStorageService.checkMd5(params)
  }

  /**
   * 文件已经存在时，进行copy操作
   * @param params
   * @returns
   */
  static async copyFile(params: CopyFileParams): Promise<any> {
    const payload = {
      id: params.fileId,
      location: params.fileLocation
    }
    const url = this.getApiUrl('copy')
    return request.post(url, payload)
  }

  /**
   * 文件上传
   * @param chunkInfo 分片信息
   * @returns
   */
  static async uploadFile(chunkInfo: ChunkInfo): Promise<any> {
    // 发送请求
    const formData = new FormData()
    formData.append('shard', new File([chunkInfo.blob!], chunkInfo.fileName))
    formData.append('md5', chunkInfo.fileMd5)
    formData.append('size', String(chunkInfo.fileSize))
    formData.append('total', String(chunkInfo.totalChunkCount))
    formData.append('shardIndex', String(chunkInfo.chunkIndex))
    formData.append('location', chunkInfo.fileLocation)
    formData.append('requestId', String(chunkInfo.fileUid))
    formData.append('chunkSize', String(this.getUploadConfig().chunkSize * 1024 * 1024))

    const payload = {
      shard: new File([chunkInfo.blob!], chunkInfo.fileName),
      md5: chunkInfo.fileMd5,
      size: chunkInfo.fileSize,
      total: String(chunkInfo.totalChunkCount),
      shardIndex: String(chunkInfo.chunkIndex),
      location: chunkInfo.fileLocation,
      requestId: String(chunkInfo.fileUid),
      chunkSize: String(this.getUploadConfig().chunkSize * 1024 * 1024)
    }

    return request.post('/file/file/upload/shard', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  /**
   * 移除文件
   * @param params
   * @returns
   */
  static async removeFile(params: RemoveFileParams) {
    const payload = {
      id: params.fileId,
      location: params.fileLocation
    }
    const url = this.getApiUrl('deleteFile')
    return request.post(url, payload) as any //FileStorageService.deleteFile(payload as any)
  }

  /**
   * 获取文件二进制数据
   * @param params
   * @returns
   */
  static async getFile(params: DownloadFileParams): Promise<GetFileResult> {
    /** 下载结果 */
    const downloadResult = await request.post('/file/file/download', params, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })

    if (!downloadResult) {
      return { success: false, message: '下载失败' }
    }

    return { success: true, content: (downloadResult as unknown) as Blob }
  }

  /**
   * 下载文件
   * @param params
   * @returns
   */
  static async getFileObjectUrl(params: DownloadFileParams): Promise<string | undefined> {
    /** 文件内容 */
    const fileContent = await this.getFile(params)

    if (!fileContent.success) {
      return undefined
    }

    /** 将文件流以文件的形式下载 */
    const blobUrl = window.URL.createObjectURL(fileContent.content)

    return blobUrl
  }

  /**
   * 下载二进制文件
   * @param blob 二进制对象
   * @param fileName 文件名
   */
  static async downloadBlobFile(blob: Blob, fileName: string) {
    /** 将文件流以文件的形式下载 */
    const blobUrl = window.URL.createObjectURL(blob)

    const downloadEle = document.createElement('a')
    downloadEle.style.display = 'none'
    downloadEle.download = fileName
    downloadEle.href = blobUrl
    document.body.appendChild(downloadEle)
    downloadEle.click()
    document.body.removeChild(downloadEle)
    window.URL.revokeObjectURL(blobUrl)
  }

  /**
   * 下载文件
   * @param parmas
   * @returns
   */
  static async downloadFile(parmas: DownloadFileParams) {
    // /** 下载结果 */
    // const downloadResult = await request.get(
    //   '/file/file/getDownloadShard',
    //   { id: parmas.fileId, location: parmas.fileLocation },
    //   {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     responseType: 'blob'
    //   }
    // )
    // if (!downloadResult) {
    //   return
    // }
    // console.info(downloadResult)

    const result = await this.getFile(parmas)

    if (!result.success) {
      KNotification.error({
        title: '文件下载失败',
        content: result.message
      })
      return
    }

    this.downloadBlobFile(result.content, parmas.fileName)
  }

  /**
   * 文件信息
   * @param fileIds
   * @returns
   */
  static async getFilesDetail(fileIds: Array<string>): Promise<Array<FileInfo>> {
    const payload = {
      fileIdJoinStr: fileIds.join(',')
    }
    const url = this.getApiUrl('list')
    const result = (await request.post(url, payload)) as any

    if (!result.isSuccess) {
      return []
    }

    return result.data!
  }

  /**创建 Http 客户端。*/
  private static async createHttpClient(): Promise<Agent.HttpClientBase> {
    const client = await Agent.HttpClientBase.create()
    //TODO 动态获取token
    await client.SetHeader('token', AppContext.current.getIdentity()?.token!)
    return client
  }

  /** 代理下载 */
  public static async downloadFilesByAgent(files: DownloadFileParams[], path: string) {
    if (!files || files.length < 1) return
    await Agent.Directory.CreateDirectory(path)
    const client = await this.createHttpClient()
    for (const file of files)
      if (file.id && file.location && file.fileName)
        // 代理下载
        await client.DownloadFile(
          utils.addQueryToUrl(ConfigClientSrv.baseApiUrl + '/file/file/download', {
            id: file.id,
            location: file.location,
            startIndex: file.startIndex.toString(),
            endIndex: file.endIndex.toString()
          }),
          await Agent.Path.Combine(path, file.fileName)
        )
  }

  /** 代理下载文件(get) */
  public static async downloadFileByAgentGet(url: string, path: string, fileName: string) {
    await Agent.Directory.CreateDirectory(path)
    const client = await this.createHttpClient()
    // 代理下载
    await client.DownloadFile(url, await Agent.Path.Combine(path, fileName))
  }

  /** 代理下载（post） */
  public static async downloadFilesByAgentPost(files: DownloadFileParams[], path: string) {
    if (!files || files.length < 1) return
    await Agent.Directory.CreateDirectory(path)
    const client = await this.createHttpClient()
    // 代理下载
    for (const file of files)
      if (file.id && file.fileName)
        await client.DownloadFileByPost(
          ConfigClientSrv.getFileApiBaseUrl + '/download',
          JSON.stringify({
            fileIds: file.id,
            modelName: file.modelCode
          }),
          await Agent.Path.Combine(path, file.fileName)
        )
  }

  /** 代理下载 */
  public static async downloadFileByAgent(file: DownloadFileParams, path: string) {
    return this.downloadFilesByAgent([file], path)
  }

  /** 代理上传 */
  public static async uploadFilesByAgent(files: (UploadFileParams & UploadFileDataParams)[]) {
    // 代理下载
    const client = await this.createHttpClient()
    const result = []
    for (const file of files)
      result.push(
        await client.UploadMultipartFormData(
          this.getApiUrl('upload'),
          lodash.pick(file, 'filename'),
          lodash.omit(file, 'filename')
        )
      )
    return result
  }

  /** 代理批量上传 */
  public static async batchUploadFilesByAgent(file: BatchUploadFileParams & UploadFileDataParams) {
    // 代理下载
    const client = await this.createHttpClient()
    const result = await client.BatchUploadMultipartFormData(
      this.getApiUrl('upload'),
      lodash.pick(file, 'files'),
      lodash.omit(file, 'files')
    )
    return result
  }

  /** 代理上传 */
  public static async uploadFileByAgent(file: UploadFileParams & UploadFileDataParams) {
    return (await this.uploadFilesByAgent([file]))[0]
  }

  /**获取api基地址 */
  public static getApiUrl(behavior: string) {
    const url = ConfigClientSrv.getFileApiBaseUrl
    return `${url}/${behavior}`
  }

  /**
   *  在url上增加query参数
   * @param urlStr 传入的url字符串
   * @param queryObj 要增加的query对象
   * @returns
   */
  public static addParamToUrl(urlStr: string, queryObj: Record<string, string | number | boolean>): string {
    let toAddQueryStr = ''
    let isFirst = true

    for (const key in queryObj) {
      const value = queryObj[key]
      if (isFirst) {
        isFirst = false
        toAddQueryStr += '?' + encodeURIComponent(key) + '=' + encodeURIComponent(value)
      } else {
        toAddQueryStr += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
    }

    // 检查URL是否已经有查询字符串，如果没有则添加问号，如果有则添加 &
    if (urlStr.indexOf('?') === -1 && !isFirst) {
      urlStr += toAddQueryStr
    } else {
      urlStr += toAddQueryStr.replace('?', '&')
    }

    return urlStr
  }
}

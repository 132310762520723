import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a180ae02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "tree" }
const _hoisted_3 = { class: "action-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_tooltip = _resolveComponent("k-tooltip")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_popover = _resolveComponent("k-popover")!
  const _component_k_input_group = _resolveComponent("k-input-group")!

  return (_openBlock(), _createBlock(_component_k_input_group, {
    class: _normalizeClass(['k-object-class-tree-selector', _ctx.$attrs.class]),
    style: _normalizeStyle(_ctx.$attrs.style),
    compact: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_input, {
        ref: "refClassInput",
        class: "object-class-name",
        placeholder: _ctx.placeholder,
        readonly: _ctx.readonly,
        disabled: _ctx.disabled,
        value: _ctx.$vm.code,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.code) = $event))
      }, null, 8, ["placeholder", "readonly", "disabled", "value"]),
      (!_ctx.disabled && !_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_k_popover, {
            key: 0,
            title: "选择编码规则",
            trigger: "click",
            placement: "bottomRight",
            open: _ctx.$vm.expand,
            "onUpdate:open": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.expand) = $event)),
            "arrow-point-at-center": ""
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_k_tree_view, {
                    ref: _ctx.$vm.refCodeRuleTree,
                    nodeKey: "id",
                    autoWrapRoot: "",
                    onAfterSelect: _cache[1] || (_cache[1] = (event) => _ctx.$vm.afterSelect(event)),
                    defaultExpandedLevel: 0,
                    unselectablePredicate: node => node!.id === '__root__',
                    loadData: _ctx.$vm.loadData
                  }, null, 8, ["unselectablePredicate", "loadData"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_k_button, {
                    type: "primary",
                    disabled: _ctx.$vm.isDisableConfirm,
                    block: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.onConfirm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 确认 ")
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_k_tooltip, { title: "选择编码规则" }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_button, {
                    class: "object-class-action",
                    disabled: _ctx.disabled
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_icon, { type: "apartment" })
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["open"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "style"]))
}
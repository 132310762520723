import {
  BaseViewModel,
  KTreeViewViewModel,
  TreeViewSelectEventArgs,
  KNotification,
  KDataGridViewModel,
  SelectOption,
  MemoryCacheFactory,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KRequirementTraceEmitsType, KRequirementTracePropType, ProductTreeNode } from './interface'
import { ref } from 'vue'
import {
  Api,
  EnumProductNeedsLifecycleState,
  EnumPriority,
  EnumRequestCode,
  EnumRequirementType,
  EnumTaskPlanType,
  TREE_VIEW_ROOT_NODE_KEY,
  ObjectClientSrv,
  CommonClientSrv,
  UserInfoCache
} from '@kmsoft/ebf-common'
import { KProjectPageWrapper } from '../../../../ebf-project-manage/src/components'

/** KRequirementTrace */

export default class KRequirementTraceViewModel extends BaseViewModel<KRequirementTraceEmitsType, KRequirementTracePropType> {
  refDataGridView = ref<KDataGridViewModel>()
  /**树组件 */
  refProductTree = ref<KTreeViewViewModel>()
  rootNodeId = ref<string>('')

  constructor(options: ViewModelOptions<KRequirementTracePropType>) {
    super(options)
  }

  selectValue = ref<string>('InWork')
  selectProductValue = ref<string>()
  selectClazz = ref<string>()
  selectNodeId = ref<string>()
  productOption = ref<Array<SelectOption>>([])
  selectOptions = ref<Array<SelectOption>>(EnumProductNeedsLifecycleState._list)
  requirementTypeOptions = ref<Array<SelectOption>>(EnumRequirementType._list)
  priorityOptions = ref<Array<SelectOption>>(EnumPriority._list)
  taskPlanOptions = ref<Array<SelectOption>>(EnumTaskPlanType._list)
  firstLoading = ref<boolean>(true)
  /** 人员列表 */
  userList = ref<Record<string, any>[]>([])

  viewDidMount() {
    this.getUserData()
    this.getProductNeeds()
  }

  /**
   * 行Id
   * @returns 行Id
   */
  get rowKey() {
    return this.refDataGridView.value?.getRowKey() || 'id'
  }

  async queryData() {
    if (!this.selectProductValue.value) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择产品需求'
      })
      return
    }
    await this.refProductTree.value?.refresh()
    this.refProductTree.value?.setSelectedNode(TREE_VIEW_ROOT_NODE_KEY)
    if (this.firstLoading.value) {
      this.refDataGridView.value?.showLoadingOverlay()
    }
  }

  async loadData() {
    if (this.selectNodeId.value) {
      const result = (await Api.post('requirement', 'ProductNeeds', 'trace', {
        data: [
          {
            id: this.selectNodeId.value,
            clazz: this.selectClazz.value
          }
        ]
      })) as any
      if (this.firstLoading.value) {
        this.firstLoading.value = false
        this.refDataGridView.value?.hideOverlay()
      }
      if (result && result.code == EnumRequestCode.SUCCESS) {
        return result.data
      }
    }
    return []
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  selectChange() {
    this.selectProductValue.value = ''
    this.getProductNeeds()
  }

  filterOption(inputValue: string, option: any): boolean {
    return option.label?.includes(inputValue)
  }

  async getProductNeeds() {
    const result = await Api.post('requirement', 'ProductNeeds', 'fetchProductNeeds', {
      data: [
        {
          searchMsg: '',
          lifecycleStateCode: this.selectValue.value
        }
      ]
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.productOption.value = result.data.map((item: any) => {
        return {
          label: `${item.title}(${item.number})`,
          value: item.id,
          className: item.className
        }
      })
    }
  }

  /**
   * 加载树节点
   */
  async loadTreeData() {
    if (!this.selectProductValue.value) {
      return Promise.resolve([])
    }
    const param = {
      data: [this.selectProductValue.value]
    }
    const res = await Api.post('requirement', 'ProductNeeds', 'get', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        key: TREE_VIEW_ROOT_NODE_KEY,
        name: res.data?.name,
        iconType: 'home',
        leafFlag: !res.data?.hasChildren,
        type: res.data?.rdmExtensionType
      }
      return [new ProductTreeNode(node)]
    } else {
      KNotification.error({
        title: '获取产品需求数据异常',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }

  /** 节点选择事件 */
  afterSelect(event: TreeViewSelectEventArgs<any>) {
    this.selectNodeId.value = event.node.id
    this.selectClazz.value = event.node.type
    this.refDataGridView.value?.refresh()
  }

  async openObjTab(row: any, type: string) {
    if (type == 'task') {
      CommonClientSrv.openPage(`我的项目: ${row.taskName}`, KProjectPageWrapper, {
        name: 'TaskInfoPanel',
        isAbsUrl: false,
        windowId: new Date().getTime(),
        objectInfo: {
          classCode: '',
          objectId: row.taskId,
          versionGroup: row.taskVersionGroup,
          accessMode: 1
        }
      })
      return
    }
    let className = ''
    let id = ''
    switch (type) {
      case 'productNeeds':
        id = row?.id
        className = row?.productNeedsClass
        break
      case 'customerNeeds':
        id = row?.customerNeedsId
        className = row?.customerNeedsClass
        break
      case 'obj':
        id = row?.objId
        className = row?.objClass
        break
      case 'verification':
        id = row?.verificationPlanId
        className = row?.verificationPlanClass
        break
    }
    const param = {
      id,
      modelCode: className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(className)
    }
    ObjectClientSrv.openObj(param)
  }
}

import {
  Agent,
  Api,
  CommonClientSrv,
  EBF_IOC_KEY,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  KObjectPanelPropType,
  LoginClientSrv,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { AgentManager, AgentRequestModel } from '@kmsoft/ebf-common/src/client-agent'
import {
  AppContext,
  EnumDialogResult,
  EnumDialogState,
  IIdentityStorage,
  KDialog,
  KDialogClosingEvent,
  UPF_IOC_KEYS,
  VNodeProps
} from '@kmsoft/upf-core'
import { resolveComponent } from 'vue'
import { KPersonalWorkspace, KPersonalWorkspaceViewModel } from '../../../../../../../ebf-folder'
import { DocClientSrv } from '../../../../../client-srv'
import { KDocEditPanel, KDocStructureManage } from '../../../../../controls'
import { DpMsgClientSrv } from '../DpMsgClientSrv'
import { DpMsgXml } from '../DpMsgXml'
import { EnumExApp } from '../EnumExApp'
import { Application, IOTypeEnum } from '../Parameter'
import { EnumWorkState } from '../../../../../controls/doc-edit-panel/interface'
import { EnumDocType } from '@kmsoft/ebf-common/src/types/enums'

export class SWHandler {
  public static readonly srcAppID: EnumExApp = EnumExApp.SOLIDWORKS

  private static agentLoginRequest: AgentRequestModel = (null as unknown) as AgentRequestModel

  private static initConditions() {
    const user = LoginClientSrv.getUserIdentity()
    const conditions = [
      {
        conditionName: 'master.documentType',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: [EnumDocType.SW]
      },
      {
        conditionName: 'latestVersion',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['true']
      },
      {
        conditionName: 'latestIteration',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['true']
      },
      {
        conditions: [
          {
            conditionName: 'workingState',
            conditionValues: ['CHECKED_IN'],
            operator: EnumQueryConditionOperator.EQUAL
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['CHECKED_OUT'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditions: [
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [''],
                    operator: EnumQueryConditionOperator.IS_NULL
                  },
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [user?.name || ''],
                    operator: EnumQueryConditionOperator.NOT_EQUAL
                  }
                ],
                joiner: EnumQueryConditionJoiner.Or
              }
            ],
            joiner: EnumQueryConditionJoiner.And
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['INWORK'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditionName: 'checkOutUserName',
                conditionValues: [user?.name || ''],
                operator: EnumQueryConditionOperator.EQUAL
              }
            ],
            joiner: EnumQueryConditionJoiner.And
          }
        ],
        joiner: EnumQueryConditionJoiner.Or
      }
    ]
    return conditions
  }

  /**登录操作处理 */
  public static async login(request: AgentRequestModel) {
    const mask = document.getElementById('maskLayer') as HTMLElement
    if (mask) {
      mask.style.display = 'none'
    }
    console.log('login', request)
    const srcAppID = request.Request.split('.')[0]

    SWHandler.agentLoginRequest = request

    AppContext.current.getIocContainer().registerSingleton(EBF_IOC_KEY.AGENT_LOGIN, { agentAfterLoad: SWHandler.afterLogin })
    // //登录成功或失败
    // await DpMsgClientSrv.Token(AppContext.current.getIdentity()?.token!)
    // /** 发送登录结果消息*/
    // await SWHandler.SendMsgLoginResult(parseInt(srcAppID), 'true')

    // /** 同步返回结果*/
    // const result = DpMsgXml.createXmlResult(request.Parameters, true, { cDocSymbol: '1', ErrInfo: '' })
    // await AgentManager.pushResponse<any>(request.Id, result)
  }

  public static async afterLogin(token: string) {
    const request = SWHandler.agentLoginRequest
    const srcAppID = request.Request.split('.')[0]
    //登录成功或失败
    await DpMsgClientSrv.Token(token)
    /** 发送登录结果消息*/
    await SWHandler.SendMsgLoginResult(parseInt(srcAppID), 'true')

    /** 同步返回结果*/
    const result = DpMsgXml.createXmlResult(request.Parameters, true, { cDocSymbol: '1', ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**退出处理 */
  public static async exit(request: AgentRequestModel) {
    console.log('exit', request)
    /** 同步返回结果*/
    AppContext.current
      .getIocContainer()
      .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
      .clear()
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   *
   * 获取文档信息，返回文档id
   */
  public static async getDocInfo(request: AgentRequestModel) {
    console.log('getDocInfo', request)
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    //根据检出路径获取文档id
    let cVolId = 0
    if (fileFullPath) {
      const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
        data: [{ fileFullPath: fileFullPath.toUpperCase() }]
      })
      if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
        cVolId = res.data.id
      }
    }
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { cVolId: cVolId, ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   *判断是否已经检出，用于控制插件按钮
   */
  public static async docChecked(request: AgentRequestModel) {
    console.log('docChecked', request)
    /** 同步返回结果*/
    const param = request.Parameters
    let result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 0,
      ErrInfo: '找不到对应的文档'
    })
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    //根据检出路径查询文档
    if (fileFullPath) {
      const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
        data: [{ fileFullPath: fileFullPath.toUpperCase() }]
      })
      if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
        const data = res.data
        result = DpMsgXml.createXmlResult(request.Parameters, '', {
          cCheckOut: data.workingState == 'CHECKED_IN' ? '0' : '1',
          cDocSymbol: 1,
          ErrInfo: ''
        })
      }
    }
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 工作区
   */
  public static async workspace(request: AgentRequestModel) {
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    //根据检出路径查询文档
    KDialog.show({
      title: '工作区',
      props: {
        showCADOperation: false
      },
      showApply: false,
      maximizeBox: true,
      state: EnumDialogState.Maximum,
      content: KPersonalWorkspace,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPersonalWorkspaceViewModel
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        //TODO do something
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 编辑
   */
  public static async edit(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    const user = LoginClientSrv.getUserIdentity()
    if (!user) {
      return
    }
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'As_Path', IOTypeEnum.IN) as string
    let currentDocId = undefined
    if (fileFullPath) {
      const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
        data: [{ fileFullPath: fileFullPath.toUpperCase() }]
      })
      if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
        currentDocId = res.data.id
      }
    }
    const conditions = SWHandler.initConditions()
    if (currentDocId) {
      conditions.push({
        conditionName: 'id',
        operator: EnumQueryConditionOperator.NOT_EQUAL,
        conditionValues: [currentDocId]
      })
    }
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: conditions
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const parameters = DpMsgXml.parseXml(request.Parameters)
        const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        //检出并下载
        const doc = await DocClientSrv.getDoc(targetParam?.id)
        // 判断文件状态
        const workingState = doc.workingState
        let docRes = undefined
        if (workingState == EnumWorkState.CHECKED_IN) {
          doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
          docRes = await DocClientSrv.checkOutDoc(doc)
        }
        if (workingState == EnumWorkState.CHECKED_OUT) {
          KDialog.warning({ content: '文档已被他人检出，不能编辑' })
          return
        }

        if (workingState == EnumWorkState.INWORK) {
          docRes = { data: [doc] }
          if (!docRes.data[0].checkoutPath) {
            KDialog.warning({ content: '文档已检出，但未找到检出路径' })
            event.cancel = true
            return
          }
          const isExists = await Agent.File.Exists(docRes.data[0].checkoutPath)
          if (!isExists) {
            KDialog.warning({ content: '文档已检出，但未找到本地文件' })
            event.cancel = true
            return
          }
        }
        if (docRes) {
          //3）给插件发消息打开目录下的文件
          await SWHandler.openFile(SWHandler.srcAppID, docRes.data[0].checkoutPath)
        } else {
          event.cancel = true
        }
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 从模型库引入
   */
  public static async childprtPoad(request: AgentRequestModel) {
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    const parameters = DpMsgXml.parseXml(param)
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: SWHandler.initConditions()
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        //2）下载文档
        const downloadPath = await DocClientSrv.docDownload(targetParam!.id, false)
        if (downloadPath) {
          //3）给插件发消息
          //await SWHandler.openFile(SWHandler.srcAppID, docRes.data[0].checkoutPath)
          await SWHandler.loadPart(SWHandler.srcAppID, downloadPath)
        } else {
          event.cancel = true
        }
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 浏览
   */
  public static async browser(request: AgentRequestModel) {
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'As_Path', IOTypeEnum.IN) as string
    let currentDocId = undefined
    if (fileFullPath) {
      const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
        data: [{ fileFullPath: fileFullPath.toUpperCase() }]
      })
      if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
        currentDocId = res.data.id
      }
    }
    //1）打开对象选择
    const conditions = SWHandler.initConditions()
    if (currentDocId) {
      conditions.push({
        conditionName: 'id',
        operator: EnumQueryConditionOperator.NOT_EQUAL,
        conditionValues: [currentDocId]
      })
    }
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: conditions
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        //2）下载文档
        const fileFullPath = (await DocClientSrv.docDownload(targetParam!.id)) as string
        //3）给插件发消息打开目录下的文件
        if (fileFullPath) {
          await SWHandler.openFile(SWHandler.srcAppID, fileFullPath)
        }
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 下载
   */
  public static async download(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    console.log('download', request)
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: SWHandler.initConditions()
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        //2）下载文档
        const fullFilePath = await DocClientSrv.docDownload(targetParam!.id)
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 检入
   */
  public static async checkin(request: AgentRequestModel) {
    console.log('checkin', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: fileFullPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      //给插件发消息关闭目录下的文件
      await SWHandler.closeFile(SWHandler.srcAppID, fileFullPath!)
      await DocClientSrv.docCheckIn(data!.id)
    }
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 查看批注
   */
  public static async viewRemark(request: AgentRequestModel) {
    console.log('checkin', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: fileFullPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      await DocClientSrv.doViewRemarkHistory(data!.id, () => {
        const result = DpMsgXml.createXmlResult(request.Parameters, '', {
          cDocSymbol: 1,
          ErrInfo: ''
        })
        AgentManager.pushResponse<any>(request.Id, result)
      })
      //TODO 执行检入的操作
    } else {
      await new Promise(() => {
        KDialog.info({
          content: '请先编辑文档',
          onOk: () => {
            AgentManager.pushResponse<any>(request.Id, result)
          }
        })
      })
    }
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 取消检出
   */
  public static async undoCheckout(request: AgentRequestModel) {
    console.log('undoCheckout', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: fileFullPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      await DocClientSrv.docUnCheckOut(data!.id)
      //TODO 执行取消检出的操作
      //给插件发消息关闭目录下的文件
      await SWHandler.closeFile(SWHandler.srcAppID, fileFullPath!)
    }
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 属性
   */
  public static async objectAttributes(request: AgentRequestModel) {
    console.log('objectAttributes', request)
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: fileFullPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      KDialog.show({
        title: '文档属性',
        state: EnumDialogState.Maximum,
        closable: false,
        maximizeBox: false,
        minimizeBox: false,
        rootClassName: 'agent',
        props: {
          objParam: { modelCode: 'Document', modelGroup: 'doc', id: data!.id },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false
        },
        content: KDocEditPanel,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          //TODO do something
          const param = { id: data?.id, modelCode: 'Document', modelGroup: 'doc' }
          /** 标签页标识 */
          const tabKey = `${param.id}#${param.modelCode}`
          /** 标题 */
          /** 面板参数 */
          const panelProps: VNodeProps<KObjectPanelPropType> = { objParam: param }
          // 打开页面
          CommonClientSrv.openPage('文档属性', () => resolveComponent('KObjectPanel'), panelProps, tabKey)
        },
        onClosed: async () => {
          await AgentManager.pushResponse<any>(request.Id, result)
        }
      })
    } else {
      await new Promise(() => {
        KDialog.info({
          content: '请先编辑文档',
          onOk: () => {
            AgentManager.pushResponse<any>(request.Id, result)
          }
        })
      })
      await AgentManager.pushResponse<any>(request.Id, result)
    }
  }

  /**
   * 结构
   */
  public static async structure(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: fileFullPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      KDialog.show({
        title: '文档结构',
        state: EnumDialogState.Maximum,
        closable: false,
        maximizeBox: false,
        minimizeBox: false,
        rootClassName: 'agent',
        props: {
          objParam: { modelCode: 'Document', modelGroup: 'doc', id: data!.id },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false
        },
        content: KDocStructureManage,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          //TODO do something
        },
        onClosed: async () => {
          await AgentManager.pushResponse<any>(request.Id, result)
        }
      })
    } else {
      await new Promise(() => {
        KDialog.info({
          content: '请先编辑文档',
          onOk: () => {
            AgentManager.pushResponse<any>(request.Id, result)
          }
        })
      })
      await AgentManager.pushResponse<any>(request.Id, result)
    }
  }

  /**
   * 本地工作目录
   */
  public static async localWork(request: AgentRequestModel) {
    console.log('localWork', request)
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 入库
   */
  public static async batchImport(request: AgentRequestModel) {
    /** 同步返回结果*/
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { cVolId: '1', ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /** 发送消息,登录结果*/
  public static async SendMsgLoginResult(appID: EnumExApp, isLogined: string) {
    const res = isLogined == 'true' ? 'OK' : 'CANCEL'
    // const paramters: ActualParameter[] = [
    //   { FormalParameterID: 'Res', IOType: 'In', DataType: 'string', IsArray: '0', Value: res },
    //   { FormalParameterID: 'SoftCaption', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'saas' },
    //   { FormalParameterID: 'UserName', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'One的账号' }
    // ]
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_ISLOGIN',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'Res',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'OK'
          },
          {
            FormalParameterID: 'SoftCaption',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'saas'
          },
          {
            FormalParameterID: 'UserName',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: '系统管理员'
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const result = DpMsgXml.objectToXML(application, 'Application')
    await DpMsgClientSrv.Send(appID, 'ID_PDM_APP_ISLOGIN', result, 3)
  }

  /**
   * 结构
   */
  public static async openFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_OPENFILE',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_OPENFILE', res, 3)
  }

  /**
   * 结构
   */
  public static async loadPart(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_LOADPART',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: `<file name = "${fileFulPath}"></file>`
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_LOADPART', res, 3)
  }

  /**
   * 关闭文件
   */
  public static async closeFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_CLOSEFILEDEL',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_CLOSEFILEDEL', res, 3)
  }
}

import { ConfigClientSrv, localStorageCache, sysSrv } from '@kmsoft/ebf-common'
import { IOrgUserLink, IOrganization, IUser } from './interface'
import { IKTreeNode, request } from '@kmsoft/upf-core'
export class UserClientSrv {
  static async buildOrganizationAndUserTree() {
    /** 获取当前租户下所有组织 */
    let organizations = [] as Array<IOrganization>
    let users = [] as Array<IUser>
    let organizationUserLinks = [] as Array<IOrgUserLink>
    const baseApiUrl = ConfigClientSrv.getApiBaseUrl
    const orgUrl = `${baseApiUrl}/org/listOrgByCurrentTenant`
    const organizationsRes = (await request.post(orgUrl, {})) as any //org.listOrgByCurrentTenant()
    if (organizationsRes.isSuccess) {
      organizations = organizationsRes.data!
    }

    /** 获取当前租户下所有用户 */
    const userUrl = `${baseApiUrl}/user/listUserByCurrentTenant`
    const userRes = (await request.post(userUrl, {})) as any //user.listUserByCurrentTenant()
    if (userRes.isSuccess) {
      users = userRes.data!
    }
    /** 获取当前租户下组织和人员关系 */
    const linkUrl = `${baseApiUrl}/org/listOrgUserLink`
    const organizationUserLinkRes = (await request.post(linkUrl, {})) as any //org.listOrgUserLink()
    if (organizationUserLinkRes.isSuccess) {
      organizationUserLinks = organizationUserLinkRes.data!
    }
    const tree = this.__buildOrgAndUserTree('0', organizations, users, organizationUserLinks) as Array<IKTreeNode>
    return tree
  }
  static __buildOrgAndUserTree(
    parentId: string,
    organizations: IOrganization[],
    users: IUser[],
    organizationUserLinks: IOrgUserLink[]
  ): IKTreeNode[] {
    const parentTreeNode = [] as Array<IKTreeNode>
    const userIds = organizationUserLinks.filter(link => link.orgId == parentId).map(link => link.userId)
    const userTreeNodes = users
      .filter(user => userIds.includes(user.id))
      .map(user => {
        const userTreeNode = {
          id: user.id,
          key: user.id,
          name: user.name,
          leaf: true,
          type: 'user',
          iconType: 'user'
        } as IKTreeNode
        return userTreeNode
      }) as Array<IKTreeNode>
    const orgTreeNodes = organizations
      .filter(organization => organization.parentId == parentId)
      .map(organization => {
        const childrenTreeNode = this.__buildOrgAndUserTree(organization.id!, organizations, users, organizationUserLinks)
        const orgTreeNode = {
          id: organization.id,
          key: organization.id,
          name: organization.name,
          leaf: childrenTreeNode.length == 0 ? true : false,
          type: 'org',
          iconType: 'folder',
          children: childrenTreeNode
        } as IKTreeNode
        return orgTreeNode
      })
    parentTreeNode.push(...userTreeNodes, ...orgTreeNodes)
    return parentTreeNode
  }
  static verifyPurview(purviewId: string) {
    let limitList: string[] = []
    const limitCache: string | undefined = localStorageCache.getCache('system_limits')
    if (limitCache) {
      limitList = JSON.parse(limitCache)
        .filter((item: any) => item.purviewType == 2)
        .map((item: any) => item.purviewId)
    }
    return sysSrv.isSSOLogin() ? limitList.includes(purviewId) : true
  }
}

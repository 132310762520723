import { BaseViewModel, KNotification, request, ViewModelOptions } from '@kmsoft/upf-core'
import { KCustomerNeedsSectionCreateEmitsType, KCustomerNeedsSectionCreatePropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KCustomerNeedsSectionCreate */
export default class KCustomerNeedsSectionCreateViewModel extends BaseViewModel<
  KCustomerNeedsSectionCreateEmitsType,
  KCustomerNeedsSectionCreatePropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    description: '',
    category: '',
    additionalComments: '',
    secondaryIds: null
  })

  constructor(options: ViewModelOptions<KCustomerNeedsSectionCreatePropType>) {
    super(options)
  }

  viewDidMount() {}

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()

    if (this.props.formValue.id) {
      createObject!.parent = {
        id: this.props.formValue.id,
        clazz: this.props.formValue.rdmExtensionType
      }
    }

    if(createObject?.content){
      const fileName = createObject?.title + '.html'
      const htmlText = createObject?.content
      const req = new FormData()
      req.append('modelName', 'CustomerNeedsSection')
      req.append('attributeName', 'secondary')
      req.append('files', new File([htmlText], fileName))
      const result = (await request.post('/kmsaasFileApi/upload', req, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })) as any
      if (!result || result.result != 'SUCCESS') {
        KNotification.error({
          title: '系统错误',
          content: result.message
        })
        return
      }
      const fileId = result.data[0]
      createObject.secondary = [{ id: fileId}]
      createObject.content = null
    }

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }
    return await Api.post('requirement','CustomerNeedsSection', 'create', params)
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_panel = _resolveComponent("k-object-property-panel")!

  return (_openBlock(), _createBlock(_component_k_object_property_panel, {
    ref: "refObjectCreatePanel",
    modelCode: _ctx.$vm.newModelCode,
    modelGroup: "doc",
    extendedAttributes: "",
    schemaType: "new",
    extendedPosition: 2,
    loadData: (params) => _ctx.loadData(params),
    folder: _ctx.$vm.folder,
    onLoaded: _cache[0] || (_cache[0] = () => _ctx.$vm.loaded())
  }, null, 8, ["modelCode", "loadData", "folder"]))
}
import {
  Direction,
  EnumItemClickedCancelType,
  EnumType,
  GlobalException,
  KControl,
  KDataGridRefreshParams,
  KDataGridRowDoubleClickEvent,
  KDataGridSelectionChangedEvent,
  KDialog,
  KLoading,
  KNotification,
  KSchema,
  KViewSchema,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  utils,
  ViewModelOptions
} from '@kmsoft/upf-core'
import lodash from 'lodash'
import { computed, createApp, createVNode, h, nextTick, ref, render, toRef, watch } from 'vue'
import {
  ClassMetaClientSrv,
  EnumClassTemplate,
  EnumLayoutSchemaType,
  EnumObjCopyMode,
  EnumVersionShowType,
  GridLayoutMetadata,
  LayoutClientSrv,
  MetaClass,
  MetaProperty,
  ObjBusinessParam,
  ObjBusinessSaveResult,
  ObjectClientSrv,
  ObjectIdentity,
  QueryCondition,
  messages
} from '../../client-srv'
import { EnumEditorEnvironment, processFilterGridSchema } from '../editors'
import { ObjectToolStripItem } from '../object-tool-strip'
import { defineToolStripData } from '../object-tool-strip/'
import { EnumToolStripItemKeys, ToolStripHelper, toolStripItemGlobalSearch, toolStripItemStartEdit } from '../toolstrip'
import { ObjectClassGirdBaseQueryParams } from './controls'
import { KObjectClassGridBaseViewModel } from './controls/KObjectClassGridBaseViewModel'
import {
  EnumLayoutDataSourceType,
  EnumObjClassManageToolStripOptions,
  KObjectClassGridEventEmitsType,
  KObjectClassGridPropType,
  ObjectClassGridQueryParams
} from './interface'
import { createDocFileFlagColumnSchema } from './utils'

/** 对象网格视图模型 */
export class KObjectClassGridViewModel<
  E extends KObjectClassGridEventEmitsType = KObjectClassGridEventEmitsType,
  P extends KObjectClassGridPropType = KObjectClassGridPropType
> extends KObjectClassGridBaseViewModel<E, P> {
  //#region 引用
  /** 高级搜索窗口 */
  refAdvancedSearchDialog = ref()
  //#endregion

  /** class 属性 */
  classAttr: string = 'k-object-class-grid'
  /** 显示子类 */
  includeChildren = ref<boolean>(false)
  /** 组件路径 */
  componentPath = ref('')
  /** 原始网格布局元数据 */
  layoutMetadata = ref<GridLayoutMetadata>({} as GridLayoutMetadata)
  /** 原始布局 【不包含自定义添加的列】 */
  originSchema = ref<KSchema>()
  /** 原始布局 【包含自定义列】 */
  schema = ref<KSchema>()
  /** 字段 */
  fields = ref<Array<Record<string, any>>>([])
  /** 高级查询 */
  advancedFilter: QueryCondition | undefined
  /** 对象类元数据 */
  metaClass = ref<MetaClass>()

  /**
   * 构造函数
   * @param options 选项
   */
  constructor(options: ViewModelOptions<P>) {
    super(options)

    watch(
      () => this.layoutMetadata.value.fields,
      fields => {
        this.fields.value = lodash.cloneDeep(fields)
      },
      {
        deep: true
      }
    )

    watch(
      () => options.props.modelCode,
      async (newValue, oldValue) => {
        this.refDataGrid.value?.clearSelectedRows()
        this.clearLocalFilter(false)
        await this.initFromObjClsCode()
        if (oldValue) {
          // 刷新
          this.refresh()
        }
      },
      {
        immediate: true
      }
    )

    watch(
      () => options.props.fields,
      async (newValue, oldValue) => {
        // 对象类场景下
        if (options.props.layoutDataSource == EnumLayoutDataSourceType.ObjClsCode || !newValue || newValue.length == 0) {
          return
        }
      },
      {
        immediate: true
      }
    )

    // 初始化默认布局
    this.initDefaultSchema()

    /** 工具栏配置 */
    this.defineToolStrip()
  }

  //#region 初始化
  /**
   * 通过对象类初始化
   */
  async initFromObjClsCode() {
    await this.initialization()
    this.emit('objClsCodeChange', this.props.modelCode ?? '')
  }

  /**
   * 通过列定义初始化
   */
  async initFromFields() {
    await this.initialization()
  }

  /**
   * 初始化总入口
   */
  private async initialization() {
    // 初始化布局
    await this.initLayout()

    // // 选中的ClassId赋值
    // if (this.props.modelCode) {
    //   //初始化工具栏
    //   await this.initToolStrip()
    // }

    this.setSearchParam(undefined)
  }
  //#endregion

  /**
   * 初始化布局
   */
  private async initLayout(): Promise<void> {
    /** 如果没有列配置 */
    if (!this.props.modelCode && (!this.props.fields || this.props.fields.length == 0)) {
      // 还原默认列定义
      this.initDefaultSchema()
      return
    }

    this.refDataGrid.value?.showLoadingOverlay()

    // 初始化元数据
    await this.initMetadata()

    // 加载布局
    const schemaConfig = await this.initSchema()

    // 设置布局
    this.setSchema(schemaConfig)

    this.refDataGrid.value?.hideOverlay()
  }

  //#region 元数据
  /**
   * 加载元数据定义
   * @private
   */
  private async initMetadata(): Promise<void> {
    let modelCode: string | undefined = undefined
    let fields: Array<MetaProperty> | undefined = undefined

    // 对象类场景下
    if (this.props.layoutDataSource == EnumLayoutDataSourceType.ObjClsCode) {
      modelCode = this.props.modelCode as string
    }

    if (this.props.layoutDataSource == EnumLayoutDataSourceType.Fields) {
      fields = this.props.fields as Array<MetaProperty>
    }
    /** 获取对象类属性 */
    this.layoutMetadata.value.fields = (await (ClassMetaClientSrv.getClassProperties(this.props.modelCode) as unknown)) as Array<
      MetaProperty
    >
    super.setFields(this.layoutMetadata.value.fields)
    /** 获取网格元数据 */
    // const layoutMetaDataResult = layoutSchemaClientSrv.getGirdMetadata(modelCode, fields, {
    //   includeChildren: this.props.includeChildren,
    //   linkColumn: this.props.enableDbClickOpenObj ? this.props.linkField : undefined
    // })

    // // 界面完整元数据
    // this.layoutMetadata.value = layoutMetaDataResult
  }
  //#endregion

  //#region 布局
  /** 初始化默认布局 */
  private initDefaultSchema() {
    /** 布局配置 */
    this.schema.value = this.getDefaultSchema()
    super.setSchema(this.schema.value)
  }

  /**
   * 获取默认布局
   */
  private getDefaultSchema() {
    /** 默认布局 */
    const schema: KSchema = {
      name: 'defaultGridLayout',
      title: '默认网格布局',
      type: EnumType.PAGE,
      control: 'KDynamicView',
      visible: true,
      readonly: false,
      disabled: false,
      nullable: true,
      props: { minColWidth: 260, rowGap: 10, colGap: 10, colCount: 3, labelWidth: 50, labelPosition: 'left' },
      events: [],
      styles: [],
      controls: [
        {
          name: 'filterGrid',
          title: '筛选网格',
          visible: true,
          control: 'KFilterGrid',
          disabled: false,
          readonly: false,
          dataSource: '',
          type: EnumType.CONTROL,
          events: [],
          nullable: true,
          props: {
            autoIndex: true,
            rowKey: 'id',
            isMultipleSelection: true,
            rowDrag: true,
            rowDragMutiRow: true,
            pagination: true,
            showStripe: true,
            stripeBackgroundColor: '',
            search: {
              name: '',
              title: '',
              type: EnumType.PAGE,
              control: 'KDynamicView',
              visible: true,
              readonly: false,
              disabled: false,
              props: { minColWidth: 260, rowGap: 10, colGap: 10, colCount: 3, labelWidth: 50, labelPosition: 'left' },
              events: [],
              styles: [],
              controls: [],
              subPages: []
            },
            filters: [],
            views: []
          },
          layout: { rowSpan: 1, colSpan: 1, entireRow: false, fill: true }
        }
      ],
      subPages: []
    }
    return schema
  }

  /** 获取布局 */
  private async initSchema() {
    if (this.props.modelCode) {
      /**
       * 1.优先取自己的布局
       * 2.获取当前对象编码的设计态
       */
      let schema = {} as KSchema
      if (this.props.schemaType == EnumLayoutSchemaType.Grid) {
        schema = await LayoutClientSrv.getSchemaByType(this.props.modelCode, this.props.schemaType as EnumLayoutSchemaType)
        // //若取不到布局则取设计态布局
        // if (schema && Object.keys(schema).length <= 0) {
        //   const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.props.modelCode)
        //   schema = await LayoutClientSrv.getSchemaByType(designerCode!, this.props.schemaType as EnumLayoutSchemaType)
        // }
      } else {
        schema = await LayoutClientSrv.getSchemaByCode(this.props.modelCode, this.props.schemaType as EnumLayoutSchemaType)
        // //若取不到布局则取设计态布局
        // if (schema && Object.keys(schema).length <= 0) {
        //   const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.props.modelCode)
        //   schema = await LayoutClientSrv.getSchemaByCode(designerCode!, this.props.schemaType as EnumLayoutSchemaType)
        // }
      }
      /** 网格布局 */
      const gridControl = Object.keys(schema).length > 0 && (schema.controls.find(c => c.control == 'KFilterGrid') as KControl)

      if (gridControl) {
        processFilterGridSchema(gridControl, {
          environment: EnumEditorEnvironment.DATA_GRID
        })
      }

      return schema
    }
    throw GlobalException.getException('初始化网格布局方案失败 modelCode必须给')
  }

  /**
   * 修改布局
   * @param newSchema 带过滤器的网格布局方案
   * @returns
   */
  setSchema(newSchema: KSchema) {
    /** 克隆布局 */
    const cloneSchema = lodash.cloneDeep(newSchema)

    // 如果有文档列
    if (this.layoutMetadata.value.objClsType == EnumClassTemplate.DOC || this.props.showDocFileFlagColumn) {
      // 如果有文档布局,重新插入文档布局
      const docFileFlagColumnSchema = createDocFileFlagColumnSchema()
      cloneSchema.props.views.forEach((v: KViewSchema) => {
        v.elements.unshift(docFileFlagColumnSchema)
      })
    }

    this.originSchema.value = newSchema
    this.schema.value = cloneSchema
    super.setSchema(this.schema.value)

    // 发出布局改变事件
    this.emit('schemaChange', this.schema.value!)
  }

  /**
   * 打开设计器 todo: 与余总确认，暂不提供
   */
  openLayoutDesigner() {}

  /**
   * 保存本地布局 todo: 与余总确认，暂不提供
   */
  saveLocalSchema() {}

  /**
   * 清除本地布局 todo: 与余总确认，暂不提供
   */
  removeLocalSchema() {}

  /**
   * 保存全局布局 与余总确认，暂不提供
   */
  async saveServerSchema() {}

  /**
   * 清除全局 与余总确认，暂不提供
   */
  async removeServerSchema() {
    //清除全局
    // const schema = await this.getLayoutSchema()
    // await DataSrv.layoutSchemaSrv.removeServerSchema(schema as KSchema)
    // Desktop.notification.success('清除全局布局成功')
  }
  //#endregion

  //#region 工具栏
  /** 定义工具栏 */
  defineToolStrip() {
    this.toolStripItems = defineToolStripData([], {
      extraItems: [
        { items: toRef(this.props, 'toolStripItems'), appendFront: false },
        { items: toRef(this.props, 'toolStripItemsInsert'), appendFront: true }
      ]
    })
  }
  //#endregion

  /**
   * 设置全局搜索参数
   * @param filter 高级搜索过滤器
   * @param refresh 是否从高级搜索重新加载
   */
  setSearchParam(condition: QueryCondition | undefined, needRefresh: boolean = false): void {
    this.advancedFilter = condition
    if (needRefresh) {
      this.refresh(undefined, false)
    }
  }
  //#endregion

  //#region 过滤
  //#endregion

  //#endregion

  //#region 通用方法
  /**
   * 刷新网格
   * @param clear 是否取消高级查询
   */
  async refresh(page?: KDataGridRefreshParams, clear = true) {
    // 如果清空高级查询
    if (clear === true) {
      this.advancedFilter = undefined
    }
    // 刷新网格
    await super.refresh(page)

    // // 更新工具条按钮状态
    // await this.updateToolStripState()
  }
  exportAsExcel() {
    const params = {
      fileName: this.props.modelCode,
      sheetName: this.props.modelCode,
      columnKeys: this.refDataGrid.value?.getColumnDefs().map((x: any) => x.id)
    }
    super.exportAsExcel(params)
  }

  /**
   * 删除数据
   */
  async removeSelectRow() {
    const objParams = super.getSelectedObjParams(true)!
    this.refDataGrid.value?.removeRow(objParams.map(objParam => objParam.id))
  }

  /**
   * 刷新当前行对象
   * @param objParam
   */
  async refreshRowObjects(objParams: undefined | ObjBusinessParam | Array<ObjBusinessParam>) {
    if (!objParams) {
      return
    }

    let tempArr: Array<ObjBusinessParam>
    if (lodash.isArray(objParams)) {
      if (objParams.length == 0) return
      tempArr = objParams as Array<ObjBusinessParam>
    } else {
      tempArr = [objParams]
    }

    // 如果外部传入了刷新行执行方法
    if (this.props.loadData) {
      await this.refresh({ purge: false })
    }
  }

  /**
   * 隐藏列
   * @param columnId 列唯一键
   * @param hide 是否隐藏
   */
  hideColumn(columnId: string, hide: boolean) {
    super.hideColumn(columnId, hide)
  }

  //#region 事件
  /** 工具栏点击事件 */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    super.onToolStripItemClicked(event)
  }

  /**
   * 工具栏change事件
   */
  onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    super.onToolStripItemChange(event)
  }

  /**
   * 网格就绪事件
   */
  onReady() {
    super.onReady()
    this.emit('ready')
  }

  /**
   * 行双击事件
   * @param event
   */
  onRowDoubleClick(event: KDataGridRowDoubleClickEvent) {
    super.onRowDoubleClick(event)
  }

  /**
   * 行切换事件
   * @param event
   */
  async onSelectionChanged(event: KDataGridSelectionChangedEvent) {
    super.onSelectionChanged(event)
  }

  /**
   * 对象网格数据加载函数
   * @param params 查询条件
   * @override
   */
  async onLoadData(params: ObjectClassGirdBaseQueryParams) {
    //获取当前对象编码的设计态
    const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.props.modelCode)
    const modelGroup = await ObjectClientSrv.getModelGroupByCode(designerCode!)
    /** 查询模型 */
    const queryModel: ObjectClassGridQueryParams = {
      modelCode: designerCode,
      filter: this.advancedFilter || params.filter || this.props.filter,
      sort: params.sort || { orders: [{ property: 'createTime', direction: Direction.DESC }] },
      page: params.page,
      modelGroup: modelGroup,
      versionShowType: undefined
    }

    // 如果类型为对象类 但没有传入对象类Code
    if (this.props.layoutDataSource == EnumLayoutDataSourceType.ObjClsCode && !this.props.modelCode) {
      return { total: 0, rows: [] }
    }

    // 如果外部传入了回调
    if (this.props.loadData) {
      return await this.props.loadData(queryModel)
    }

    // 如果没有配置对象类 Id
    if (!this.props.modelCode) {
      return { total: 0, rows: [] }
    }
    return await ObjectClientSrv.listObjects({
      filterModelCode: this.props.modelCode,
      modelCode: designerCode,
      modelGroup: modelGroup,
      page: queryModel.page,
      filter: queryModel.filter,
      sort: queryModel.sort?.orders
    })
  }
  getContainer() {
    return super.getContainer()
  }

  clearSelectedRows() {
    this.refDataGrid.value?.clearSelectedRows()
  }

  removeSelectedRows() {
    this.refDataGrid.value?.removeSelectedRows()
  }

  //#endregion
}

import {
  Api,
  EnumRequestCode,
  EnumSupportObjectNumMode,
  EnumToolStripItemKeys,
  KObjectClassGrid,
  KObjectToolStripViewModel,
  ObjectGroupMapping,
  ObjectToolStripItem,
  PartViewMemoryCache
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumDialogResult,
  EnumToolStripCompType,
  KDataGridModelUpdatedEvent,
  KDataGridRowSelectedEvent,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KEmpty,
  KNotification,
  MemoryCacheFactory,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { cloneDeep } from 'lodash'
import { ref, watch } from 'vue'
import { DocClientSrv } from '../../../../../ebf-doc/src/client-srv'
import { EnumWorkState } from '../../../../../ebf-doc/src/controls/doc-edit-panel/interface'
import { PartClientSrv } from '../../../../../ebf-part/src'
import { WorkflowClientSrv } from '../../../client-srv'
import { KCollectRelatedPart, KCollectRelatedPartViewModel } from './form/collect-related-part'
import { KCollectRelatedObjectEmitsType, KCollectRelatedObjectPropType } from './interface'
import _ from 'lodash'

/** KCollectRelatedObject */
export default class KCollectRelatedObjectViewModel extends BaseViewModel<
  KCollectRelatedObjectEmitsType,
  KCollectRelatedObjectPropType
> {
  refDataGrid = ref<KDataGridViewModel>()

  dataSource = ref<Array<Record<string, any>>>()
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>([
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART,
      icon: 'pic-left',
      title: '收集零部件',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC,
      icon: 'pic-left',
      title: '收集文档',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
      icon: 'DeleteObj',
      title: '移除',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    }
  ])

  constructor(options: ViewModelOptions<KCollectRelatedObjectPropType>) {
    super(options)
    watch(
      () => this.props.templateKey,
      () => {
        this.initLoadData()
      },
      {
        immediate: true
      }
    )
    watch(
      () => this.props.objParams,
      () => {
        this.initLoadData()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.initLoadData()
  }

  /**
   * 初始化网格数据
   */
  initLoadData() {
    const data = this.props.objParams.map(obj => {
      return {
        id: obj.objID,
        ...obj
      }
    })
    this.dataSource.value = cloneDeep(data)
  }

  initToolStrip() {
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, false)
  }

  /**
   * 改变工具栏
   */
  changeToolItem(event: KDataGridRowSelectedEvent<any>) {
    const selectRows = this.refDataGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      this.initToolStrip()
      return
    }
    // 当选择一个零件时，点击收集零件，显示该零件下的子（只显示非标准件、元器件、材料类型且状态在工作中或已取码的已检入零件），选择后加入列表；（收集零部件弹框不需要显示查询规则和层级，且左树和右边网格不需要互动，仅作展示，左树为最新版本）
    // 当选择多个零件时，收集零件置灰；
    // 当选择一个或多个零件时，点击收集文档（只显示工作中且已检入文档），可以收集选中零件的描述文档，选择后加入列表；
    // 当选中一个文档或多个文档时，点击收集零部件，可以收集选中文档所描述的零部件，选择后加入列表；
    // 当选中一个文档或多个文档时，收集文档置灰。

    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, true)
    // 当选中单个对象时,判断对象类型，若为零部件则显示所有按钮，若为文档则隐藏收集文档，若为其他类型则隐藏收集零部件和收集文档
    const partClassCode = ObjectGroupMapping.get('part')
    const docClassCode = ObjectGroupMapping.get('doc')
    if (selectRows.length == 1) {
      if (partClassCode?.includes(selectRows[0].objClsCode)) {
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART, true)
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC, true)
      }
      if (docClassCode?.includes(selectRows[0].objClsCode)) {
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART, true)
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC, false)
      }
      return
    }
    // 当选中多个对象时，判断选中对象是否同一个类型，如果不是同一类型则隐藏收集文档和收集零部件
    // 遍历选中数据
    let selectRowsClsCode = new Set()
    selectRows.forEach(row => {
      for (const [system, subsystems] of ObjectGroupMapping) {
        if (subsystems.includes(row.objClsCode!)) {
          selectRowsClsCode.add(system)
        }
      }
    })

    if (selectRowsClsCode.size == 1) {
      if (selectRowsClsCode?.has('part')) {
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART, false)
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC, true)
        return
      }
      if (selectRowsClsCode?.has('doc')) {
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART, true)
        this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC, false)
        return
      }
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC, false)
    } else {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC, false)
    }
  }

  /**
   * 工具栏点击事件
   */
  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_PART: {
        this.collectPart()
        break
      }
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECT_RELATED_DOC: {
        this.collectDoc()
        break
      }
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.delete()
        break
      default:
        break
    }
  }

  collectPart=_.debounce(()=>{this.collectRelatedPart()},300)
  
  collectDoc=_.debounce(()=>{this.collectRelatedDoc()},300)

  /**
   * 收集零部件
   */
  async collectRelatedPart() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    const partClassCode = ObjectGroupMapping.get('part')
    const docClassCode = ObjectGroupMapping.get('doc')
    // 判断对象类型，如果是零部件则打开零部件收集相关零部件弹框
    if (partClassCode?.includes(rows[0].objClsCode)) {
      await this.partCollectRelatedPart()
      return
    }
    if (docClassCode?.includes(rows[0].objClsCode)) {
      await this.docCollectRelatedPart()
      return
    }
  }

  /**
   * 文档收集零部件
   */
  async docCollectRelatedPart() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    const docBranchIds = rows.map(row => row.id)
    const doc = await DocClientSrv.getLatestByBranchIds(docBranchIds)
    const docs = doc.map((row: any) => {
      return {
        id: row.id,
        clazz: row.objClsCode
      }
    })
    let dataSource = [] as any[]
    const reqParam = {
      data: [docs]
    }
    const result = await Api.post('part', 'PartDescribeLink', 'listPartByDocumentIds', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      dataSource = result.data
    } else {
      KNotification.error({
        title: '查询失败',
        content: result.message,
        details: result.detail
      })
    }

    // 如果未查询到数据，则显示空页面
    if (dataSource.length == 0) {
      KDialog.show({
        title: '相关零部件',
        size: { width: 1200, height: 800 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        getContainer: this.refDataGrid.value?.getContainer(),
        content: KEmpty,
        props: {
          description: '未查询到零部件信息'
        },
        onClosing: async (event: KDialogClosingEvent) => {
          return
        }
      })
    } else {
      KDialog.show({
        title: '相关零部件',
        size: { width: 1200, height: 800 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        getContainer: this.refDataGrid.value?.getContainer(),
        content: KObjectClassGrid,
        props: {
          modelCode: 'Part',
          modelGroup: 'part',
          selectFirstRow: false,
          loadData: async () => {
            return Promise.resolve(dataSource)
          },
          // 选中当前网格已经存在的数据
          onModelUpdated: (event: KDataGridModelUpdatedEvent) => {
            const allRowIds = this.refDataGrid.value?.getRows().map(row => row.id)
            const selectRowIds = dataSource.filter(row => allRowIds!.includes(row.branch.id)).map(row => row.id)
            if (selectRowIds.length > 0) {
              event.api?.setSelectedRow(selectRowIds, true)
            }
          }
        },
        onClosing: async (event: KDialogClosingEvent) => {
          const selectorViewModel = event.viewModel as KCollectRelatedPartViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return

          // 根据viewModel获取到当前选中的数据
          const selectedRows = selectorViewModel.getSelectedRows()
          if (!selectedRows || selectedRows?.length == 0) {
            return
          }
          const allRows = this.refDataGrid.value?.getRows()
          const rowIds = allRows?.map(row => row.id)
          const noExistRows = selectedRows.filter(row => !rowIds?.includes(row.branch.id))

          if (noExistRows.length == 0) {
            return
          }

          // 校验对象是否已经加入流程
          const params = noExistRows.map(row => {
            return {
              objId: row.branch.id,
              objClsCode: row.rdmExtensionType,
              name: row.name!,
              number: row.number!
            }
          })
          const result = await WorkflowClientSrv.checkWorkflowAndChangeManaged(params)
          if (!result) {
            event.cancel = true
            return
          }

          const insertRows = noExistRows?.map(row => {
            return {
              id: row.branch.id,
              objID: row.branch.id,
              objClsCode: row.rdmExtensionType,
              name: row.name,
              number: row.number,
              version: row.version,
              status: row.lifecycleStateCode,
              targetViewId: row.partView.id
            }
          })
          this.refDataGrid.value?.insertRow(insertRows!)
        }
      })
    }
  }

  /**
   * 零部件收集相关零部件
   */
  async partCollectRelatedPart() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    const part = await PartClientSrv.getLatestByBranchIds([rows![0].id])
    const allRowIds = this.refDataGrid.value?.getRows().map(row => row.id)
    const that = KDialog.show({
      title: '相关零部件',
      size: { width: 1200, height: 800 },
      showFooter: false,
      floatFooter: true,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KCollectRelatedPart,
      props: {
        objParam: {
          id: part[0].id,
          rdmExtensionType: rows[0].objClsCode
        },
        defaultSelectRows: allRowIds,
        templateKey: this.props.templateKey,
        onCancel: function() {
          that.close({ dialogResult: EnumDialogResult.Cancel })
        },
        onConfirm: function() {
          that.close({ dialogResult: EnumDialogResult.Confirm })
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KCollectRelatedPartViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()
        if (!selectedRows || selectedRows?.length == 0) {
          return
        }
        const allRows = this.refDataGrid.value?.getRows()
        const rowIds = allRows?.map(row => row.id)
        const noExistRows = selectedRows.filter(row => !rowIds?.includes(row.branch.id))

        if (noExistRows.length == 0) {
          return
        }

        // 校验对象是否已经加入流程
        const params = noExistRows.map(row => {
          return {
            objId: row.branch.id,
            objClsCode: row.rdmExtensionType,
            name: row.name!,
            number: row.number!
          }
        })
        const result = await WorkflowClientSrv.checkWorkflowAndChangeManaged(params)
        if (!result) {
          event.cancel = true
          return
        }
        const insertRows = noExistRows?.map(row => {
          return {
            id: row.branch.id,
            objID: row.branch.id,
            objClsCode: row.rdmExtensionType,
            name: row.name,
            number: row.number,
            version: row.version,
            status: row.lifecycleStateCode,
            targetViewId: row.partView.id
          }
        })
        this.refDataGrid.value?.insertRow(insertRows!)
      }
    })
  }
  /**
   * 收集文档
   */
  async collectRelatedDoc() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    const partBranchIds = rows.map(row => row.id)
    const part = await PartClientSrv.getLatestByBranchIds(partBranchIds)
    const parts = part.map((row: any) => {
      return {
        id: row.id,
        clazz: row.objClsCode
      }
    })

    // 收集相关文档
    let dataSource = [] as any[]
    const reqParam = {
      data: [parts]
    }
    const result = await Api.post('part', 'PartDescribeLink', 'listDocumentByParts', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const docs = result.data.filter(
        (data: any) => data.docViewDTO.lifecycleStateCode == 'InWork' && data.docViewDTO.workingState == EnumWorkState.CHECKED_IN
      )
      dataSource = docs
    } else {
      KNotification.error({
        title: '查询失败',
        content: result.message,
        details: result.detail
      })
    }
    // 如果未查询到数据，则显示空页面
    if (dataSource.length == 0) {
      KDialog.show({
        title: '相关文档',
        size: { width: 1200, height: 800 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        getContainer: this.refDataGrid.value?.getContainer(),
        content: KEmpty,
        props: {
          description: '未查询到文档信息'
        },
        onClosing: async (event: KDialogClosingEvent) => {
          return
        }
      })
    } else {
      KDialog.show({
        title: '相关文档',
        size: { width: 1200, height: 800 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KObjectClassGrid,
        props: {
          modelCode: 'PartDescribeLink',
          modelGroup: 'part',
          selectFirstRow: false,
          loadData: async () => {
            return Promise.resolve(dataSource)
          },
          // 选中当前网格已经存在的数据
          onModelUpdated: (event: KDataGridModelUpdatedEvent) => {
            const allRowIds = this.refDataGrid.value?.getRows().map(row => row.id)
            const selectRowIds = dataSource.filter(row => allRowIds!.includes(row.docViewDTO.branch.id)).map(row => row.id)
            if (selectRowIds.length > 0) {
              event.api?.setSelectedRow(selectRowIds, true)
            }
          }
        },
        onClosing: async (event: KDialogClosingEvent) => {
          const selectorViewModel = event.viewModel as KCollectRelatedPartViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return

          // 根据viewModel获取到当前选中的数据
          const selectedRows = selectorViewModel.getSelectedRows()
          if (!selectedRows || selectedRows?.length == 0) {
            return
          }
          const allRows = this.refDataGrid.value?.getRows()
          const rowIds = allRows?.map(row => row.id)
          const noExistRows = selectedRows.filter(row => !rowIds?.includes(row.docViewDTO.branch.id))

          if (noExistRows.length == 0) {
            return
          }

          // 校验对象是否已经加入流程
          const params = noExistRows.map(row => {
            return {
              objId: row.docViewDTO.branch.id,
              objClsCode: row.docViewDTO.rdmExtensionType,
              name: row.docViewDTO.name!,
              number: row.docViewDTO.number!
            }
          })
          const result = await WorkflowClientSrv.checkWorkflowAndChangeManaged(params)
          if (!result) {
            event.cancel = true
            return
          }

          const insertRows = noExistRows?.map(row => {
            return {
              id: row.docViewDTO.branch.id,
              objID: row.docViewDTO.branch.id,
              objClsCode: row.docViewDTO.rdmExtensionType,
              name: row.docViewDTO.name,
              number: row.docViewDTO.number,
              version: row.docViewDTO.version,
              status: row.docViewDTO.lifecycleStateCode
            }
          })
          this.refDataGrid.value?.insertRow(insertRows!)
        }
      })
    }
  }
  /**
   * 删除对象
   */
  delete() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows.length == 0) {
      return
    }
    const ids = rows.map(row => row.id)
    this.refDataGrid.value?.removeRow(ids)
  }
  /**
   * 刷新网格
   */
  refresh() {}

  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (row.targetViewId) {
      const view = partView.filter(item => item.id == row.targetViewId)[0].description
      return '(' + view + ')'
    }
    return ''
  }

  /**
   * 获取行数据
   * @returns 行数据
   */
  getRows() {
    return this.refDataGrid.value?.getRows()
  }
}

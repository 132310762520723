import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  DataGridLoadResult,
  EnumDialogResult,
  KDataGridRowClickEvent,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KFormViewModel,
  KNotification,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { WorkflowClientSrv } from '../../client-srv'
import { KCollectRelatedObjectViewModel } from './collect-related-object'
import { IExecutor, IWorkflow, KObjectAddWorkflowEmitsType, KObjectAddWorkflowPropType } from './interface'
import KWorkflowTemplateSelector, { KWorkflowTemplateSelectorViewModel } from './workflow-template-selector'

/** KObjectAddWorkflow */
export default class KObjectAddWorkflowViewModel extends BaseViewModel<KObjectAddWorkflowEmitsType, KObjectAddWorkflowPropType> {
  /**表单viewModel */
  refForm = ref<KFormViewModel>()
  /**表单数据 */
  formData = ref<IWorkflow>({
    templateId: '',
    name: '',
    code: ''
  })
  /**tab页key */
  activeKey = ref<string>('1')
  /** 工作流模板名称 */
  workflowTemplateName = ref<string>()
  /**图片地址 */
  imageSrc = ref<string>()
  /** 执行人网格 */
  refExecutorDataGrid = ref<KDataGridViewModel>()
  /** 用户网格 */
  refUserDataGrid = ref<KDataGridViewModel>()
  /** 步骤 */
  processNodes = ref<any[]>([])

  /** 收集零部件组件 */
  refCollectRelatedObject = ref<KCollectRelatedObjectViewModel>()

  /**
   * 是否显示相关对象
   */
  showRelatedObject = ref<boolean>(true)

  refFrame = ref<HTMLIFrameElement>()

  constructor(options: ViewModelOptions<KObjectAddWorkflowPropType>) {
    super(options)
    watch(
      () => this.formData.value.templateId,
      () => {
        if (this.formData.value.templateId != '' && this.formData.value.templateId != null) {
          this.refreshWorkflowPicture()
          this.refExecutorDataGrid.value?.refresh()
        }
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.initWorkflowCode()
  }
  /**
   * 初始化流程编码（自动生成）
   */
  async initWorkflowCode() {
    const param = {
      data: []
    }
    const result = await Api.post('official', 'ProcessContentService', 'autoCreateWfCode', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.formData.value.code = result.data
    }
  }
  /**
   * 打开工作流选择面板
   */
  openWorkflowTemplateSelector() {
    KDialog.show({
      title: '选择流程模板',
      content: KWorkflowTemplateSelector,
      props: {
        objParams: this.props.objParams
      },
      size: { width: 768, height: 500 },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const vm = event.viewModel as KWorkflowTemplateSelectorViewModel
        const workflowTemplate = vm.getWorkTemplate()
        this.workflowTemplateName.value = workflowTemplate?.name
        this.formData.value.templateId = workflowTemplate!.id
        this.formData.value.templateKey = workflowTemplate!.templateKey
        // 调用后端接口自动生成名称
        const param = {
          data: [
            {
              templateName: workflowTemplate?.name,
              number: this.props.objParams?.length > 0 ? this.props.objParams[0].number : null,
              name: this.props.objParams?.length > 0 ? this.props.objParams[0].name : null,
              version: this.props.objParams?.length > 0 ? this.props.objParams[0].version : null
            }
          ]
        }
        const result = await Api.post('official', 'ProcessContentService', 'autoCreateWfName', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.formData.value.name = result.data
          this.refForm.value?.validate()
          const objClsCodes = this.props.objParams.map(obj => obj.objClsCode)
          if (
            objClsCodes.includes('PMS_PROJECT') ||
            objClsCodes.includes('PMS_PLAN') ||
            objClsCodes.includes('PMS_ISSUE') ||
            objClsCodes.includes('PMS_RISK')
          ) {
            this.showRelatedObject.value = false
          } else {
            this.showRelatedObject.value = true
          }
        } else {
          KNotification.error({
            title: '自动获取名称失败',
            content: result.message,
            details: result.detail
          })
          return
        }
      }
    })
  }

  /**
   * 加载执行人网格数据
   */
  loadDataExecutor(): Promise<DataGridLoadResult> {
    return new Promise((resolve, reject) => {
      const param = {
        data: [{ id: this.formData.value.templateId }]
      }
      Api.post('official', 'ProcessInstanceService', 'getProcessNodes', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          this.processNodes.value = res.data
          const result = res.data.map((data: any) => {
            return {
              ...data,
              executorId: '',
              executorName: '',
              id: utils.uuid()
            } as IExecutor
          }) as Array<IExecutor>

          resolve(result)
        } else {
          resolve([])
        }
      })
    })
  }
  /**
   * 加载用户网格
   */
  loadDataUser(): Promise<DataGridLoadResult> {
    return new Promise((resolve, reject) => {
      const param = {
        data: []
      }
      Api.post('common', 'UserManage', 'listUserByCurrentTenant', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          const result = res.data.map((data: any) => {
            return {
              id: data.id,
              name: data.name,
              email: data.email
            }
          })
          resolve(result)
        } else {
          resolve([])
        }
      })
    })
  }
  /**
   * 刷新用户网格
   */
  refreshUserGrid(event: KDataGridRowClickEvent<any>) {
    // this.refUserDataGrid.value?.refresh()
    this.refUserDataGrid.value?.clearSelectedRows()
    const row = this.refExecutorDataGrid.value!.getSelectedRows()[0]
    const userId = row.executorId
    if (userId) {
      this.refUserDataGrid.value?.setSelectedRow(userId, true)
    }
  }
  /**
   * 刷新流程图
   */
  async refreshWorkflowPicture() {
    WorkflowClientSrv.getWorkflowTemplatePicture(this.formData.value.templateId).then((res: string) => {
      this.imageSrc.value = res
      if (!this.imageSrc.value) {
        this.imageSrc.value = res
        return
      }
      this.refFrame.value!.src = res
    })
  }
  /**
   * 移除流程活动用户
   */
  delete(id: string) {
    const deleteRow = this.refExecutorDataGrid.value?.getRow(id)!
    const rows = this.refExecutorDataGrid.value?.getRows()
    const commonRows = rows?.filter(row => row.name == deleteRow.name)
    if (commonRows?.length == 1) {
      commonRows[0].executorId = ''
      commonRows[0].executorName = ''
      this.refExecutorDataGrid.value?.refreshRow(commonRows[0])
      this.refUserDataGrid.value?.clearSelectedRows()
      return
    }
    this.refExecutorDataGrid.value?.removeRow(id)
    this.refUserDataGrid.value?.clearSelectedRows()
  }

  /**
   * 添加执行人
   */
  addExecutor() {
    const workflowActivity = this.refExecutorDataGrid.value?.getSelectedRows()
    if (!workflowActivity || workflowActivity.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个流程活动'
      })
      return
    }
    if (workflowActivity[0].multiInstance) {
      const users = this.refUserDataGrid.value?.getSelectedRows()
      if (!users || users.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个用户'
        })
        return
      }

      // 1、校验用户是否已经加入选中的流程活动,过滤出未加入的用户
      const executorIds = this.refExecutorDataGrid.value
        ?.getRows()
        .filter(row => row.name == workflowActivity[0].name)
        .map(row => row.executorId)
      const insertUser = users.filter(user => !executorIds?.includes(user.id))
      // 2、构建row
      let newRows = insertUser.map(user => {
        const row = {
          ...workflowActivity[0],
          executorId: user.id,
          executorName: user.name,
          id: utils.uuid()
        }
        return row
      })
      let removeFirstRowFlag = false
      if (!workflowActivity[0].executorId || workflowActivity[0].executorId == '') {
        const updateRow = {
          ...workflowActivity[0],
          executorId: newRows[0].executorId,
          executorName: newRows[0].executorName
        }
        this.refExecutorDataGrid.value?.refreshRow(updateRow)
        removeFirstRowFlag = true
      }
      const index = this.refExecutorDataGrid.value?.getRowIndex(workflowActivity[0].id)

      if (removeFirstRowFlag) {
        newRows = newRows.slice(0)
        newRows.shift()
      }
      this.refExecutorDataGrid.value?.insertRow(newRows, index!)
    } else {
      const users = this.refUserDataGrid.value?.getSelectedRows()
      if (!users || users.length != 1) {
        KNotification.warn({
          message: '系统提示',
          description: '当前流程活动不支持多人操作'
        })
        return
      }

      // 1、校验用户是否已经加入选中的流程活动,过滤出未加入的用户
      const updateRow = {
        ...workflowActivity[0],
        executorId: users[0].id,
        executorName: users[0].name
      }
      this.refExecutorDataGrid.value?.refreshRow(updateRow)
    }
  }
  /**
   * 校验表单
   * @returns 校验结果
   */
  async verify() {
    const verifyExecutorFlag = await this.verifyExecutor()
    const verifyFormDataFlag = await this.verifyFormData()
    const verifyFormCollectRelatedFlag = await this.verifyFormCollectRelated()
    return verifyExecutorFlag && verifyFormDataFlag && verifyFormCollectRelatedFlag
  }

  /**
   * 校验活动执行人
   */
  async verifyExecutor() {
    let verifyFlag = true
    // 校验流程活动执行人是否为空
    const rows = this.refExecutorDataGrid.value?.getRows()
    if (rows && rows.length > 0) {
      rows.forEach(row => {
        if (!row.executorId || row.executorId == '') {
          verifyFlag = false
        }
      })
    } else {
      verifyFlag = false
    }
    return verifyFlag
  }

  async verifyFormData() {
    let flag = false
    this.refForm.value?.validate()
    if (this.formData.value.name && this.formData.value.code && this.formData.value.templateId) {
      flag = true
    }
    return flag
  }

  async verifyFormCollectRelated() {
    const rows = this.refCollectRelatedObject.value?.getRows()
    if (!rows) {
      return true
    }
    if (rows.length != 0) {
      return true
    }
    return false
  }

  /**
   * 获取表单数据
   */
  getValue() {
    const rows = this.refExecutorDataGrid.value?.getRows()!
    const groupedData = rows.reduce((groups, item) => {
      const assignVariable = item.assignVariable
      const multiInstance = item.multiInstance
      if (multiInstance) {
        if (!groups[assignVariable]) {
          groups[assignVariable] = []
        }
        groups[assignVariable].push(item.executorId)
        return groups
      } else {
        if (!groups[assignVariable]) {
          groups[assignVariable] = ''
        }
        groups[assignVariable] = item.executorId
        return groups
      }
    }, {})
    const objList = this.refCollectRelatedObject.value?.getRows() || []
    const objParams = objList?.map(obj => {
      return {
        workObjId: obj.id,
        workObjClsCode: obj.objClsCode
      }
    })
    return {
      ...this.formData.value,
      objParams: objParams,
      variables: groupedData
    }
  }
}


import { defineView, KSelect } from '@kmsoft/upf-core'
import { KObjectDynamicCreatePanelPropOptions, KObjectDynamicCreatePanelEventEmits } from './interface'
import KObjectDynamicCreatePanelViewModel from './KObjectDynamicCreatePanelViewModel'
import { createVNode, resolveComponent, withDirectives, vShow, ref, nextTick } from 'vue'
import { KObjectClassTreeSelector } from '../editors/form/object-class-tree-selector'

export default defineView({
  name: 'KObjectDynamicCreatePanel',
  props: KObjectDynamicCreatePanelPropOptions,
  emits: KObjectDynamicCreatePanelEventEmits,
  viewModel: KObjectDynamicCreatePanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    let showPanel = ref(true)
    const objSelect = createVNode(KObjectClassTreeSelector, {
      value: vm.currentClsCode.value,

      'onUpdate:value': (value: string) => {
        /**
         * 每次选中都重新渲染组件，确定vue页面与vm实例是一对一
         * part与standardPart用的同一个vue页面，二者切换时只有一个实例，需要先销毁，再重新渲染
         */
        showPanel.value = false
        vm.currentClsCode.value = value
        nextTick(() => {
          showPanel.value = true
        })
      },
      showObjClsCodes: props.showObjClsCodes,
      hideObjClsCodes: props.hideObjClsCodes
    })
    const createPanel = () => {
      if (vm.createPageName.value) {
        const folderId = props.folderId
        console.log('aaaaa')
        console.log(vm.createPageName.value)
        console.log(resolveComponent(vm.createPageName.value))
        return createVNode(resolveComponent(vm.createPageName.value), {
          formValue: { folder: { id: folderId }, classCode: vm.currentClsCode.value },
          ref: vm.refCreatePanelViewModel,
          modelCode: vm.currentClsCode.value
        })
      } else {
        return <k-empty description="请选择对象类" />
      }
    }
    const selectorContainerVNode = props.showObjClsSelector ? <div style="padding:10px">{objSelect}</div> : <div></div>
    return () => {
      return (
        <div class="k-object-create-panel">
          {selectorContainerVNode}
          <div>{showPanel.value ? createPanel() : ''}</div>
        </div>
      )
    }
  }
})

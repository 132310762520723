import { utils } from '@kmsoft/upf-core'

class SystemSrv {
  isSSOLogin() {
    const url = location.href
    const ulrList = utils.getQueryObjList(url) as Array<any>
    const state = ulrList.find(obj => Object.prototype.hasOwnProperty.call(obj, 'state'))
    const code = ulrList.find(obj => Object.prototype.hasOwnProperty.call(obj, 'code'))
    const loginType = !!(state && code)
    if (loginType) {
      return true
    }
    return false
  }

  isAgentLogin(){
    const url = location.href
    const ulrList = utils.getQueryObjList(url) as Array<any>
    const type = ulrList.find(obj => Object.prototype.hasOwnProperty.call(obj, 'loginType'))
    const loginType = !!(type && type.loginType.includes('agentLogin'))
    if (loginType) {
      return true
    }
    return false
  }
}
export const sysSrv = new SystemSrv()

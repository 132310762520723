import { Api, EnumRequestCode, clsCodeMap } from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  DataGridLoadResult,
  EnumDialogResult,
  KDataGridColumn,
  KDataGridRowSelectedEvent,
  KDataGridTextBoxColumn,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KPropertySettingsFrom, KPropertySettingsFromViewModel } from '../../form/property-settings-from'
import {
  IColumnDefinition,
  IRootTreeNodeParam,
  KBomCompareResultPanelEmitsType,
  KBomCompareResultPanelPropType,
  initColumns
} from './interface'

/** KBomCompareResultPanel */
export default class KBomCompareResultPanelViewModel extends BaseViewModel<
  KBomCompareResultPanelEmitsType,
  KBomCompareResultPanelPropType
> {
  /**
   * 网格组件
   */
  refDataGrid = ref<KDataGridViewModel>()

  /** 列定义 */
  columns = ref<Array<KDataGridColumn>>([])
  /**隐藏的列 */
  hideColumns = ref<Array<IColumnDefinition>>([])
  /**显示的列 */
  showColumns = ref<Array<IColumnDefinition>>([])
  /**比较方式 */
  comparisonMode = ref<string | undefined>()
  /**差异件是否展开 */
  expandDifferentialParts = ref<boolean | undefined>(false)
  /**是否忽略分支节点 */
  ignoringBranchNodes = ref<boolean | undefined>(false)
  /**源对象 */
  source = ref<IRootTreeNodeParam>()
  /** 目标对象 */
  target = ref<IRootTreeNodeParam>()
  /**
   * 比较规则是否应用到根
   */
  applyToRoot = ref<boolean>()

  constructor(options: ViewModelOptions<KBomCompareResultPanelPropType>) {
    super(options)
    this.comparisonMode.value = options.props.comparisonMode
    this.expandDifferentialParts.value = options.props.expandDifferentialParts
    this.ignoringBranchNodes.value = options.props.ignoringBranchNodes
    this.source.value = options.props.source
    this.target.value = options.props.target
    this.initColumn()
  }

  viewDidMount() {}

  loadData(): Promise<DataGridLoadResult> {
    return new Promise(resolve => {
      if (this.comparisonMode.value) {
        // todo 根据sourceId、targetId、comparisonMode、expandDifferentialParts、ignoringBranchNodes调用后端获取比较结果
        const param = {
          data: [
            {
              treeExpandList: [
                {
                  root: {
                    id: this.source.value?.root.id,
                    clazz: this.source.value?.root.modelCode
                  },
                  expandType: this.comparisonMode.value == 'AGGREGATE_LIST' ? 'AGGREGATE_LIST' : 'FORWARD_EXPANSION',
                  queryRule: this.source.value?.queryRule,
                  view: this.source.value?.view,
                  maxLevel: 99,
                  applyToRoot: this.source.value?.applyToRoot
                },
                {
                  root: {
                    id: this.target.value?.root.id,
                    clazz: this.target.value?.root.modelCode
                  },
                  expandType: this.comparisonMode.value == 'AGGREGATE_LIST' ? 'AGGREGATE_LIST' : 'FORWARD_EXPANSION',
                  queryRule: this.target.value?.queryRule,
                  view: this.target.value?.view,
                  maxLevel: 99
                }
              ],
              bomCompareType: this.comparisonMode.value,
              compareSub: this.expandDifferentialParts.value,
              ignoreInternalNode: this.ignoringBranchNodes.value,
              compareFiledList: this.showColumns.value.map(column => {
                return column.value
              })
            }
          ]
        }
        Api.post('part', 'Part', 'bomCompare', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
            const result = [] as Array<any>
            res.data.forEach((item: any) => {
              if (item.userObject.diff) {
                // 实体类型由前端进行拼接处理
                const leftClass = item.userObject.diff.leftClassAndView ? item.userObject.diff.leftClassAndView.split('(') : ''
                const rightClass = item.userObject.diff.rightClassAndView ? item.userObject.diff.rightClassAndView.split('(') : ''
                item.userObject.diff.className = `${leftClass ? clsCodeMap.get(leftClass[0]) + '(' + leftClass[1] : '无'} -> ${
                  rightClass ? clsCodeMap.get(rightClass[0]) + '(' + rightClass[1] : '无'
                }`
                result.push(item.userObject.diff)
              }
            })
            this.emit('loadReady', result)
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: res.message,
              details: res.detail
            })
            resolve([])
          }
        })
      } else {
        resolve([])
        this.emit('loadReady', [])
      }
    })
  }

  /**
   * 属性设置
   */
  propertySettings() {
    KDialog.show({
      title: '属性设置',
      content: KPropertySettingsFrom,
      props: {
        selectedRows: this.showColumns.value
      },
      size: { width: 550, height: 400 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as KPropertySettingsFromViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectRow = formViewModel.getSelectRows()
        const unSelectRow = formViewModel.getUnSelectRows()
        //设置显示列
        selectRow.forEach((row: IColumnDefinition) => {
          this.refDataGrid.value?.hideColumn(row.code, false)
        })
        this.showColumns.value = selectRow
        //设置隐藏列
        unSelectRow.forEach((row: IColumnDefinition) => {
          this.refDataGrid.value?.hideColumn(row.code, true)
        })
        this.hideColumns.value = unSelectRow
        this.refDataGrid.value?.refresh()
      }
    })
  }
  /**
   * 导出excel
   */
  export() {
    const hideColumnsCode = this.hideColumns.value.map(column => column.code)
    this.refDataGrid.value?.exportDataAsExcel({
      fileName: 'bom比较结果',
      sheetName: 'bom比较结果',
      columnKeys: this.columns.value.filter(column => !hideColumnsCode.includes(column.id)).map(column => column.id),
      processCellCallback: (args: any) => args.value
    })
  }

  /**
   * 设置选中行
   */
  setSelectRow(key: string) {
    this.refDataGrid.value?.setSelectedRow(key, true)
  }

  /**
   * 设置树选中
   */
  setTreeSelect(event: KDataGridRowSelectedEvent<any>) {
    const rows = this.refDataGrid.value?.getSelectedRows()
    this.emit('selectRowsChange', rows)
  }
  /**
   * 清除网格所有数据
   */
  clearGrid() {
    this.refDataGrid.value?.clear()
  }

  /**
   * 初始化网格
   */
  initColumn() {
    // 获取所有属性
    const columns = [
      {
        code: 'displayUnit',
        name: '单位',
        value: 'UNIT'
      },
      {
        code: 'displayOccurrence',
        name: '位号',
        value: 'OCCURRENCE'
      },
      {
        code: 'displayPhase',
        name: '阶段',
        value: 'PHASE'
      },
      {
        code: 'displayView',
        name: '视图',
        value: 'VIEW'
      },
      {
        code: 'displayAssemblyMode',
        name: '装配模式',
        value: 'ASSEMBLYMODE'
      },
      {
        code: 'displaySource',
        name: '来源',
        value: 'SOURCE'
      }
    ]
    initColumns.forEach(column => {
      const dataColumn = new KDataGridTextBoxColumn()
      dataColumn.id = column.code
      dataColumn.headerAlign = 'center'
      dataColumn.headerText = column.name
      dataColumn.dataPropertyName = column.code
      dataColumn.width = 'auto'
      dataColumn.hide = false
      this.columns.value.push(dataColumn)
    })

    columns.forEach(column => {
      const dataColumn = new KDataGridTextBoxColumn()
      dataColumn.id = column.code
      dataColumn.headerAlign = 'center'
      dataColumn.headerText = column.name
      dataColumn.dataPropertyName = column.code
      dataColumn.width = 'auto'
      dataColumn.hide = true
      this.columns.value.push(dataColumn)
    })
  }
  /**
   * 刷新网格
   */
  refresh() {
    this.refDataGrid.value?.refresh()
  }
  /**
   * 根据条件重新加载网格
   */
  reload(
    source: IRootTreeNodeParam,
    target: IRootTreeNodeParam,
    comparisonMode: string,
    expandDifferentialParts: boolean,
    ignoringBranchNodes: boolean
  ) {
    this.comparisonMode.value = comparisonMode
    this.expandDifferentialParts.value = expandDifferentialParts
    this.ignoringBranchNodes.value = ignoringBranchNodes
    this.source.value = source
    this.target.value = target
    this.refDataGrid.value?.refresh()
  }
  /**
   * 获取所有行数据
   */
  getRows() {
    return this.refDataGrid.value?.getRows()
  }
}

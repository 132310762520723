import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumPurviewType, PurviewSrv, VerifyPurviewParams } from '../../../common'
import { EnumSupportObjectNumMode, ObjectToolStripItem, ToolStripFilterParams } from '../types'
import { ClassMetaClientSrv } from '../../../client-srv'
import { localStorageCache, sysSrv } from '../../../index'

export class ToolStripFilter {
  /** 需要应用的字段 */
  static readonly configMap = ['text', 'visible', 'index']

  //#region 工具栏过滤方法

  /**
   * 过滤工具栏条目
   * @param toolStripItems
   * @param params
   */
  static async filterToolStripItems(
    toolStripMap: Map<string, ObjectToolStripItem>,
    params: ToolStripFilterParams,
    limitList: string[]
  ) {
    let templateId = ''

    // 如果有对象类Id
    if (params.modelCode) {
      //获取对象类的设计态编码
      const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(params.modelCode)
      templateId = designerCode!
    }

    for (const item of toolStripMap) {
      /** 工具栏Id */
      const itemId = item[0]
      /** 工具栏条目 */
      const toolStripItem = item[1]
      /** 是否更改过属性 */
      let changed = false

      // 如果基本条件下不支持，不继续判断
      if (toolStripItem.visible == false && changed) {
        continue
      }

      // 2: 应用基础配置
      changed = this.applyItemProperties(toolStripItem, {
        ...params,
        templateId
      })

      // 如果基本条件下不支持，不继续判断
      if (!toolStripItem.visible && changed) {
        continue
      }

      // 3: 根据选择对象数量判断是否隐藏
      if (params.selectedDatas) {
        // 如果传入了选择对象数据
        changed = this.applySupportMode(toolStripItem, params.selectedDatas.length)

        // 如果根据选择数量判断不支持，不继续判断
        if (!toolStripItem.visible && changed) {
          continue
        }

        // 如果设置了启用条件
        changed = this.applyEnabledMode(toolStripItem, params.selectedDatas.length)
      }

      // 4: 判断是否有权限
      if (toolStripItem.visible && toolStripItem.purviewId && sysSrv.isSSOLogin()) {
        toolStripItem.visible = limitList.includes(toolStripItem.purviewId)
        changed = true
      }

      // 如果自定义规则不通过，不继续判断
      if (!toolStripItem.visible && changed) {
        continue
      }
    }
  }

  /** 根据基础定义应用属性 */
  private static applyItemProperties(item: ObjectToolStripItem, params: ToolStripFilterParams & { templateId: string }) {
    let changed = false

    // 清除状态
    item.isValidatedObjClsTemplet = undefined

    // 根据模板Id判断是否显示
    if (params.templateId) {
      if (item.supportedObjClsTempletID && item.supportedObjClsTempletID.length > 0) {
        item.isValidatedObjClsTemplet = item.visible = item.supportedObjClsTempletID.indexOf(params.templateId) >= 0
        changed = true
      } else if (item.unSupportedObjClsTempletID && item.unSupportedObjClsTempletID.length > 0) {
        item.isValidatedObjClsTemplet = item.visible = item.unSupportedObjClsTempletID.indexOf(params.templateId) < 0
        changed = true
      }
    }

    // 如果对象类已经不支持，不再继续判断
    if (item.visible == false && changed) {
      return changed
    }

    // 清除状态
    item.isValidatedEnvironment = undefined

    // 根据环境判断是否支持
    if (params.environment) {
      if (item.supportedEnvironment) {
        item.isValidatedEnvironment = item.visible = item.supportedEnvironment.indexOf(params.environment) >= 0
        changed = true
      } else if (item.unSupportedEnvironment) {
        item.isValidatedEnvironment = item.visible = item.unSupportedEnvironment.indexOf(params.environment) < 0
        changed = true
      }
    }

    return changed
  }

  /**
   * 根据选择数量应用模式
   * @param item 工具栏条目
   * @param selectNum 选择数量
   */
  private static applySupportMode(item: ObjectToolStripItem, selectNum: number) {
    let changed = false
    // 清除状态
    item.isValidatedObjNumMode = undefined

    switch (item.supportedObjNumMode) {
      case EnumSupportObjectNumMode.Any:
      default:
        break
      case EnumSupportObjectNumMode.Single:
        item.isValidatedObjNumMode = item.visible = selectNum == 1
        changed = true
        break
      case EnumSupportObjectNumMode.Multiple:
        item.isValidatedObjNumMode = item.visible = selectNum > 1
        changed = true
        break
      case EnumSupportObjectNumMode.Single_Multiple:
        item.isValidatedObjNumMode = item.visible = selectNum > 0
        changed = true
        break
    }
    return changed
  }

  /**
   * 根据选择数量是否启用
   * @param item 工具栏条目
   * @param selectNum 选择数量
   */
  private static applyEnabledMode(item: ObjectToolStripItem, selectNum: number): boolean {
    let changed = false
    if (item.compType == EnumToolStripCompType.CONTEXT_MENU) {
      return changed
    }
    switch (item.enabledObjNumMode) {
      case EnumSupportObjectNumMode.Any:
      default:
        break
      case EnumSupportObjectNumMode.Single:
        item.disabled = selectNum != 1
        changed = true
        break
      case EnumSupportObjectNumMode.Multiple:
        item.disabled = selectNum <= 1
        changed = true
        break
      case EnumSupportObjectNumMode.Single_Multiple:
        item.disabled = selectNum <= 0
        changed = true
        break
    }
    return changed
  }

  //#endregion
}

import { Api, EnumPurviewCode, EnumRequestCode } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  TreeViewLoadDataFunc,
  TreeViewSelectEventArgs,
  ToolStripButtonItem,
  EnumToolStripCompType,
  ToolStripItem,
  KTreeNode
} from '@kmsoft/upf-core'
import { UserClientSrv } from '../../../../../ebf-folder/src/client-srv'
import { KRequireWorkspacePropOptions } from '../../interface'

/** 参数 **/
export const KRequireTreePropOptions = {
  ...BaseViewPropOptions,
  ...KRequireWorkspacePropOptions,

  /** 展开节点时加载子节点的数据库请求方法，返回promise **/
  loadData: VuePropTypes.func<TreeViewLoadDataFunc>().def()
}

/** 参数类型 **/
export type KRequireTreePropType = ViewPropsTypeExtract<typeof KRequireTreePropOptions>

/** 事件 */
export const KRequireTreeEventEmits = {
  ...BaseViewEventEmits,
  afterSelect: (event: TreeViewSelectEventArgs<any>) => true
}

/** 事件类型 **/
export type KRequireTreeEmitsType = ViewEmitsTypeExtract<typeof KRequireTreeEventEmits>

/**
 * 普通文件夹目录树节点定义
 */
export class RequireTreeNode extends KTreeNode {
  /**
   * 编码
   */
  nameEn: string
  /**
   * 图标
   */
  iconType: string
  /**
   * 备注
   */
  description: string
  /**
   * 创建人id
   */
  creator: string

  /***
   * 创建时间
   */
  createTime: string

  /**
   * 类型
   */
  type: string

  /**
   * 父id
   */
  parentId: string
  /**
   * 根节点类型
   */
  rootNodeType?: string

  /**
   * 模型编码
   */
  modelCode: string

  constructor(data: any) {
    super()
    this.id = data.id
    this.nameEn = data.nameEn
    this.name = data.name
    this.leaf = !data.hasChildren
    this.iconType = 'folder'
    this.description = data.description
    this.createTime = data.createTime
    this.creator = data.creator
    this.type = data.type
    this.parentId = data.parentId
    this.rootNodeType = data.rootNodeType
    this.modelCode = data.type
  }
  async populateChildren(): Promise<Array<KTreeNode>> {
    const newChild = [] as any
    // if (this.leaf) {
    //   return newChild
    // }
    const param = {
      data: [this.id]
    }
    const result = await Api.post('requirement', this.type, 'listChildNode', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        const childNode = new RequireTreeNode(item as any)
        childNode.modelCode = this.type
        newChild.push(childNode)
      }
    }
    return newChild
  }
  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        name: EnumToolStripItemKeys.NEW,
        title: '新建',
        visible: true,
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.DELETE,
        title: '删除',
        visible: true,
        purviewId: EnumPurviewCode.DeleteFolder,
        icon: 'delete',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.REFRESH,
        title: '刷新',
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.SORT,
        title: '排序',
        icon: 'ordered-list',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+f'
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem.filter(item => !item.purviewId || UserClientSrv.verifyPurview(item.purviewId))
  }
}

export enum EnumToolStripItemKeys {
  /** 查询 */
  FIND = 'find',
  /** 新建 */
  NEW = 'new',
  /** 编辑 */
  EDIT = 'edit',
  /** 新窗口中打开 */
  OPEN_NEW_TAB = 'openNewTab',
  /** 删除 */
  DELETE = 'delete',
  /** 重命名 */
  RENAME = 'rename',
  /** 复制 */
  COPY = 'copy',
  /** 剪切 */
  CUT = 'cut',
  /** 粘贴 */
  PASTE = 'paste',
  /** 排序 */
  SORT = 'sort',
  /** 刷新 */
  REFRESH = 'refresh'
}

/**
 * 属性目录定义
 */
export interface IFolder {
  /**
   * 目录Id
   */
  id: string
  /**
   * 父目录Id
   */
  parentId: string
  /**
   * 目录名称
   */
  name: string
  /**
   * 编码
   */
  nameEn: string
  /**
   * 类型
   */
  type: string
  /**
   * 根节点类型
   */
  rootNodeType?: string
  /**
   * 描述
   */
  description: string

  /**
   * 创建时间
   */
  createTime: string

  /**
   * 创建人id
   */
  creator: string

  /**
   * 创建人名称
   */
  creatorName: string
  /**
   * 是否叶子节点
   */
  leafFlag: boolean
  /**
   * 图标
   */
  iconType?: string

  /**
   * 需求类型
   */
  modelCode?: string
}

export enum CopyOrCutEnum {
  /**
   * 没有发生复制剪切的操作
   */
  NOTHING = 0,
  /**
   * 在剪切中
   */
  IN_CUT = 1,
  /**
   * 在粘贴中
   */
  IN_COPY = 2
}

/**
 * 查询数据返回类型
 */
export interface IQueryData {
  /**
   * 目录Id
   */
  directoryId: string
  /**
   * 目录路径
   */
  directoryPath: string
}

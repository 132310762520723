import { BaseViewModel, GlobalException, KNotification, SelectOption, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectWorkSpaceSearchEmitsType, KObjectWorkSpaceSearchPropType, VersionTypeEnum } from './interface'
import { computed, ref, watch } from 'vue'
import { KRuleDefinerViewModel } from '../rule-definer'
import { CommonSearcherLifeStateForm, KCommonSearcherViewModel, KQuickSearcherViewModel } from '../object-searcher/tabs'
import {
  ClassMetaClientSrv,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  LoginClientSrv,
  MetaProperty,
  ObjectClientSrv,
  QueryCondition
} from '../../client-srv'
import { EnumObjectSearcherTab } from '../object-searcher'
import { Api, FolderTypeEnum } from '../../../../ebf-common'
import { get } from 'lodash'
import { KObjectViewSelectorViewModel } from '../object-view-selector'
import { EnumDocDependencyType } from '../../types/enums'

/** KObjectWorkSpaceSearch */
export default class KObjectWorkSpaceSearchViewModel extends BaseViewModel<
  KObjectWorkSpaceSearchEmitsType,
  KObjectWorkSpaceSearchPropType
> {
  /** 高级查询引用 */
  refKRuleDefine = ref<KRuleDefinerViewModel>()
  /** 通用查询引用 */
  refKCommonSearcher = ref<KCommonSearcherViewModel>()
  /** 快速查询引用 */
  refKQuickSearcher = ref<KQuickSearcherViewModel>()
  /** 分类ref */
  refCategory = ref<any>()
  /** 一般查询的表单 */
  refConfigForm = ref<any>()

  /** 固定对象类Id */
  freezeClassCode = computed(() => this.props.showObjClsCodes.length == 1)
  /** 类ID */
  modelCode = ref<string>()
  /** 分类下拉 */
  clsAttrs = ref<Array<Record<string, any>>>([])
  /** 类名 */
  className = ref<string | undefined>()
  /** 加载状态 */
  isLoading = ref<boolean>(false)
  /** 对象分组 */
  modelGroup = ref<string>()
  /** 是否显示通用查询页签 */
  isAllowCommonSearch = ref(false)
  /** 是否显示快速查询页 */
  isAllowQuickSearch = ref(false)
  /** 拓展对象属性列表 */
  fields = ref<Array<MetaProperty>>([])
  /** 标签页 */
  tabKey = ref<EnumObjectSearcherTab>(EnumObjectSearcherTab.COMMON_SEARCH)
  /** 包含子类 */
  isIncludeChildren = ref<boolean>(false)

  /** 通用搜索配置 */
  commonCondition = ref<{ condition: QueryCondition; lifeState: CommonSearcherLifeStateForm }>()
  /** 高级搜索配置 */
  advancedCondition = ref<QueryCondition>()
  /** 快速搜索配置 */
  quickSearchCondition = ref<QueryCondition>()
  /**选中文件夹id */
  selectFolderId = ref<string>('')
  /** 默认展开树节点 */
  defaultExpandedKey = ref<string[]>([])
  /** 工作区对象下拉树选择 */
  targetClsObjectTreeData = ref<any[]>([])
  /** 分类组件置灰 */
  isCategoryDisabled = ref<Boolean>(true)
  /** 视图选择器 */
  refViewSelector = ref<KObjectViewSelectorViewModel>()
  /** 视图 */
  refView = ref<Record<string, any>>()
  /**工作区类型 */
  workspaceType = ref<FolderTypeEnum>()

  /**包含子类是否可用 */
  hasChild = ref<boolean>(false)
  /**
   * 工作区候选值
   */
  options = ref<Array<SelectOption>>([
    {
      label: '产品工作区',
      value: FolderTypeEnum.PRODUCT
    },
    {
      label: '项目工作区',
      value: FolderTypeEnum.PROJECT
    },
    {
      label: '公共工作区',
      value: FolderTypeEnum.PUBLIC
    },
    {
      label: '个人工作区',
      value: FolderTypeEnum.PERSONAL
    },
    {
      label: 'CAD工作区',
      value: FolderTypeEnum.CAD
    },
    {
      label: '标准件',
      value: FolderTypeEnum.STANDARD_PARTS
    },
    {
      label: '元器件',
      value: FolderTypeEnum.ELECTRONIC_COMPONENTS
    },
    {
      label: '通用件',
      value: FolderTypeEnum.COMMON_PARTS
    }
  ])

  /**版本查询是否显示 */
  refVersionTypeShow = ref<boolean>(false)
  /**视图查询是否显示 */
  refViewShow = ref<boolean>(false)
  /**版本类型 */
  refVersionType = ref<VersionTypeEnum>(VersionTypeEnum.LATEST)
  /**
   * 版本类型候选值
   */
  versionTypes = ref<Array<SelectOption>>([
    {
      label: '最新版本',
      value: 'latest'
    },
    {
      label: '全部版本',
      value: 'all'
    }
  ])

  /** 分类绑定值 */
  configFormValue = ref<any>({ 'lifecycleState.nameEn': ['InWork', 'UnderReview', 'Released', 'Realizing', 'Closed', 'Yiquma'] })
  /** 表单配置 */
  groupList = ref<Array<Record<string, any>>>([
    {
      label: '基本属性',
      props: 'basic',
      children: [
        {
          label: '名称',
          props: 'name',
          name: 'commonSearchNames',
          attrs: {
            placeholder: '请输入名称'
          }
        },
        {
          label: '编码',
          props: 'number',
          name: 'commonSearchIds',
          attrs: {
            placeholder: '请输入编码'
          }
        }
      ]
    },
    {
      label: '通用属性',
      props: 'common',
      style: 'margin-top: -15px',
      children: [
        {
          label: '创建时间',
          props: 'createTime',
          type: 'k-date-time',
          name: 'commonSearchCreateTimes',
          attrs: {
            type: 'range'
          }
        },
        {
          label: '修改时间',
          props: 'lastUpdateTime',
          type: 'k-date-time',
          name: 'commonSearchLastUpdateTimes',
          attrs: {
            type: 'range'
          }
        },
        {
          label: '生命周期状态',
          props: 'lifecycleState.nameEn',
          type: 'k-select',
          name: 'commonSearchPhases',
          attrs: {
            placeholder: '请选择生命周期状态',
            mode: 'multiple',
            options: [
              {
                id: 'gEgz8yapVY',
                label: '工作中',
                value: 'InWork'
              },
              {
                id: 'k6vfbM4G0q',
                label: '审核中',
                value: 'UnderReview'
              },
              {
                id: '9eddexAjaD',
                label: '已发布',
                value: 'Released'
              },
              {
                id: '9eddexAjaZ',
                label: '实现中',
                value: 'Realizing'
              },
              {
                id: '9eddeDAjaD',
                label: '已关闭',
                value: 'Closed'
              },
              {
                id: '9eddexAjaa',
                label: '已废弃',
                value: 'Obsoleted'
              },
              {
                id: '9eddexAjab',
                label: '已取码',
                value: 'Yiquma'
              }
            ]
          }
        }
      ]
    }
  ])

  categoryAttrsField: string
  constructor(options: ViewModelOptions<KObjectWorkSpaceSearchPropType>) {
    super(options)
    this.modelCode.value = options.props.modelCode

    watch(
      () => this.props.modelCode,
      newValue => {
        this.modelCode.value = newValue
      }
    )

    watch(
      this.workspaceType,
      (newValue, oldValue) => {
        if (newValue) {
          this.selectFolderId.value = ''
          this.loadTreeOptions()
        }
      },
      {
        immediate: true
      }
    )

    watch(
      this.modelCode,
      async (newValue, oldValue) => {
        if (newValue) {
          this.emit('update:modelCode', newValue ?? '')
          this.refKRuleDefine.value?.clear()
          this.refKCommonSearcher.value?.clear()
          this.refKQuickSearcher.value?.clear()
          this.fetchMetaData()
          // await setOption()
          // this.onSearch()
          const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.modelCode.value as any)
          if ('Part' == designerCode) {
            this.refViewShow.value = true
            this.refView.value = { name: 'Design' }
          } else {
            this.refViewShow.value = false
            this.refView.value = {}
          }
          if (['Document', 'Part'].includes(designerCode!)) {
            this.refVersionTypeShow.value = true
            this.refVersionType.value = VersionTypeEnum.LATEST
          } else {
            this.refVersionType.value = VersionTypeEnum.INIT
            this.refVersionTypeShow.value = false
          }
          this.categoryAttrsField = newValue == 'Part' ? 'Classification' : 'catAttr'
        }
      },
      {
        immediate: true
      }
    )
    watch(this.selectFolderId, (newValue, oldValue) => {
      if (newValue != '') {
        if (this.modelCode.value !== '') {
          const query = {
            joiner: 'and',
            conditions: [{ conditionName: 'folder.id', operator: 'in', conditionValues: [this.selectFolderId.value] }]
          } as QueryCondition
          // TODO 这里注释掉工作区选择自动查询
          // this.emit('search', query)
        }
      } else {
        this.emit('search')
      }
    })

    this.isCategoryDisabled = computed(() => {
      return !this.modelCode.value
    })
  }

  viewDidMount() {}

  /** 异步加载对象属性，对象拓展属性列表 */
  async fetchMetaData() {
    try {
      this.isLoading.value = true
      if (this.modelCode.value) {
        const metaClass = await ClassMetaClientSrv.getClassByClsCode(this.modelCode.value)
        this.className.value = metaClass?.name
        this.modelGroup.value = await ObjectClientSrv.getModelGroupByCode(this.modelCode.value)
        this.fields.value = (await ClassMetaClientSrv.getClassProperties(this.modelCode.value)!) as Array<MetaProperty>
      }
    } catch (exception) {
      GlobalException.getException('获取属性列表时异常')
      this.modelCode.value = undefined
    } finally {
      this.isLoading.value = false
    }
  }

  /**
   * 生成高级查询表单
   */
  getCondition(): QueryCondition | undefined {
    switch (this.tabKey.value) {
      case EnumObjectSearcherTab.COMMON_SEARCH:
        return this.refConfigForm.value?.getValue()
      case EnumObjectSearcherTab.ADVANCED_SEARCH:
        return this.refKRuleDefine.value?.getCondition()
      case EnumObjectSearcherTab.QUICK_SEARCH:
        return this.refKQuickSearcher.value?.getCondition()
    }
  }

  //#region 事件
  /** 当搜索按下时，提交搜索事件 */
  async onSearch() {
    if (!this.modelCode.value) {
      KNotification.info({
        message: '请选择对象类！'
      })
    }
    if (this.workspaceType.value && !this.selectFolderId.value) {
      KNotification.info({
        message: '请选择存储位置！'
      })
      return
    }
    const condition = (await this.getCondition()) as any
    const extAttrs = this.refCategory.value.getValue()
    // 分类属性单独数据处理
    if (extAttrs?.length && get(extAttrs, '[0].value', '')) {
      condition.conditions.push({
        conditionName: `extAttrs.${this.categoryAttrsField}`,
        operator: '=',
        conditionValues: [get(extAttrs, '[0].value', '')]
      })
    }
    //视图处理
    const partView = this.refViewSelector.value?.getValue()
    if (partView && Object.keys(partView).length > 0) {
      condition.conditions.push({
        conditionName: 'partView.name',
        operator: '=',
        conditionValues: [partView.name]
      })
    }
    // 版本查询处理
    const versionType = this.refVersionType.value
    let versionCondition = null
    if (versionType == VersionTypeEnum.LATEST) {
      const user = LoginClientSrv.getUserIdentity()
      if (!user) {
        return
      }
      //最新版本
      versionCondition = {
        conditions: [
          {
            conditionName: 'latestVersion',
            operator: EnumQueryConditionOperator.EQUAL,
            conditionValues: ['true']
          },
          {
            conditionName: 'latestIteration',
            operator: EnumQueryConditionOperator.EQUAL,
            conditionValues: ['true']
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['CHECKED_IN'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditions: [
                  {
                    conditionName: 'workingState',
                    conditionValues: ['CHECKED_OUT'],
                    operator: EnumQueryConditionOperator.EQUAL
                  },
                  {
                    conditions: [
                      {
                        conditionName: 'checkOutUserName',
                        conditionValues: [''],
                        operator: EnumQueryConditionOperator.IS_NULL
                      },
                      {
                        conditionName: 'checkOutUserName',
                        conditionValues: [user.name],
                        operator: EnumQueryConditionOperator.NOT_EQUAL
                      }
                    ],
                    joiner: EnumQueryConditionJoiner.Or
                  }
                ],
                joiner: EnumQueryConditionJoiner.And
              },
              {
                conditions: [
                  {
                    conditionName: 'workingState',
                    conditionValues: ['INWORK'],
                    operator: EnumQueryConditionOperator.EQUAL
                  },
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [user.name],
                    operator: EnumQueryConditionOperator.EQUAL
                  }
                ],
                joiner: EnumQueryConditionJoiner.And
              }
            ],
            joiner: EnumQueryConditionJoiner.Or
          }
        ],
        joiner: EnumQueryConditionJoiner.And
      }
    } else if (versionType == VersionTypeEnum.ALL) {
      const user = LoginClientSrv.getUserIdentity()
      if (!user) {
        return
      }
      //全部版本
      versionCondition = {
        conditions: [
          {
            conditionName: 'latestIteration',
            operator: EnumQueryConditionOperator.EQUAL,
            conditionValues: ['true']
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['CHECKED_IN'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditions: [
                  {
                    conditionName: 'workingState',
                    conditionValues: ['CHECKED_OUT'],
                    operator: EnumQueryConditionOperator.EQUAL
                  },
                  {
                    conditions: [
                      {
                        conditionName: 'checkOutUserName',
                        conditionValues: [''],
                        operator: EnumQueryConditionOperator.IS_NULL
                      },
                      {
                        conditionName: 'checkOutUserName',
                        conditionValues: [user.name],
                        operator: EnumQueryConditionOperator.NOT_EQUAL
                      }
                    ],
                    joiner: EnumQueryConditionJoiner.Or
                  }
                ],
                joiner: EnumQueryConditionJoiner.And
              },
              {
                conditions: [
                  {
                    conditionName: 'workingState',
                    conditionValues: ['INWORK'],
                    operator: EnumQueryConditionOperator.EQUAL
                  },
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [user.name],
                    operator: EnumQueryConditionOperator.EQUAL
                  }
                ],
                joiner: EnumQueryConditionJoiner.And
              }
            ],
            joiner: EnumQueryConditionJoiner.Or
          }
        ],
        joiner: EnumQueryConditionJoiner.And
      }
    } else if (versionType == VersionTypeEnum.LATEST_RELEASED) {
      //最新发布版本
      versionCondition = {
        conditions: [
          {
            conditionName: 'latestVersion',
            operator: EnumQueryConditionOperator.EQUAL,
            conditionValues: ['true']
          },
          {
            conditionName: 'lifecycleState.nameEn',
            operator: EnumQueryConditionOperator.LIKE,
            conditionValues: ['Released']
          }
        ],
        joiner: EnumQueryConditionJoiner.And
      }
    }
    if (versionCondition) {
      condition.conditions.push(versionCondition)
    }
    // 类型单独处理
    if (this.modelCode.value) {
      let childClass = [] as Array<string>
      if (this.isIncludeChildren.value) {
        const res = await ClassMetaClientSrv.getClassAndChild(this.modelCode.value)
        childClass = res.map(item => item.code)
      }
      condition.conditions.push({
        conditionName: 'rdmExtensionType',
        operator: childClass.length > 0 ? 'in' : '=',
        conditionValues: [this.modelCode.value, ...childClass]
      })
      if (this.modelCode.value == 'Document') {
        condition.conditions.push({
          conditionName: 'master.documentType',
          operator: EnumQueryConditionOperator.NOT_IN,
          conditionValues: [
            EnumDocDependencyType.SIGNATURE,
            EnumDocDependencyType.PRINT,
            EnumDocDependencyType.BROWSE,
            EnumDocDependencyType.SOLIDWORKS_2D
          ]
        })
      }
      const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.modelCode.value as any)
      // 零部件过滤掉工作副本
      if (['Part'].includes(designerCode!)) {
        condition.conditions.push({
          conditionName: 'workingCopy',
          operator: EnumQueryConditionOperator.EQUAL,
          conditionValues: ['false']
        })
      }
    }
    if (!condition || !condition.conditions || condition.conditions.length <= 0) {
      return
    }
    if (this.selectFolderId.value) {
      const docCondition = {
        conditionName: 'folder.id',
        operator: EnumQueryConditionOperator.IN,
        conditionValues: [this.selectFolderId.value]
      }
      condition.conditions.push(docCondition)
      this.emit('search', condition)
    } else {
      this.emit('search', condition)
    }
  }

  /** 取消 */
  onCancel() {
    this.emit('cancel')
  }

  /** 分类下拉选择 */
  async selectCategory(value: any) {
    const commonArr = {
      changeable: null,
      class: 'com.kmsoft.common.core.dme.dto.DataModelAttributeDTO',
      classCode: null,
      classId: null,
      defaultValue: null,
      enumInfo: null,
      id: null,
      index: null,
      length: 50,
      name: null,
      nullable: true,
      precision: 0,
      propArgs: null,
      readonly: true,
      referenceClassCode: null,
      scale: null,
      tenantId: '-1',
      type: 50,
      visible: null
    }
    const category = value.getValue()
    const res = (await Api.post('common', 'ClassificationNode', 'getCategoryNodeInfo', {
      data: [get(category, 'value', '')]
    })) as any
    const result = (get(res, 'data[0].attrs') || []).map((arr: any) => {
      return {
        code: get(arr, 'nameEn', ''),
        columnName: `clsAttrs.${this.categoryAttrsField}.` + get(arr, 'nameEn', ''),
        displayName: get(arr, 'name', ''),
        ...commonArr
      }
    })
    this.clsAttrs.value = result
    //若选择了分类则无法选择包含子类
    this.hasChild.value = true
    this.isIncludeChildren.value = false
  }

  /** 清除下拉 */
  async clearCategory() {
    this.clsAttrs.value = []
    //清除时,接触包含子类的禁用
    this.hasChild.value = false
  }

  /** 表单数据格式化 */
  async configFormGetValue(value: any) {
    const result = [] as any
    const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.modelCode.value as any)
    Object.keys(value).forEach(async (key: any) => {
      if (value[key]) {
        // 创建时间、最后更新时间单独处理
        if (['createTime', 'lastUpdateTime'].includes(key)) {
          result.push(
            {
              conditionName: key,
              operator: '>=',
              conditionValues: [`${value[key][0]} 00:00:00`]
            },
            {
              conditionName: key,
              operator: '<=',
              conditionValues: [`${value[key][1]} 24:59:59`]
            }
          )
          return
        }
        if (key == 'lifecycleState.nameEn') {
          if (value[key].length > 0) {
            result.push({
              conditionName: key,
              operator: 'in',
              conditionValues: value[key]
            })
          }
          return
        }

        let displayName = key

        if (['Document', 'Part'].includes(designerCode!) && ['name', 'number'].includes(key)) {
          displayName = 'master.' + key
        }
        result.push({
          conditionName: displayName,
          operator: 'like',
          // operator: '=',
          conditionValues: [value[key]]
        })
      }
    })
    return { conditions: result, joiner: 'and' }
  }

  /**
   * 工作区候选值加载
   */
  async loadTreeOptions() {
    switch (this.workspaceType.value) {
      //个人
      case FolderTypeEnum.PERSONAL:
        {
          const param = {
            data: []
          }
          const res = await Api.post('folder', 'Folder', 'getPersonalWorkspace', param)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            const node = {
              id: res.data.id,
              pId: 0,
              title: '个人工作区',
              isLeaf: res.data.leafFlag,
              value: res.data?.id
            }
            this.targetClsObjectTreeData.value = [node]
            await this.loadTreeData(node)
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取个人工作区数据异常',
              content: res.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      // 公共
      case FolderTypeEnum.PUBLIC:
        {
          const param = {
            data: []
          }
          const res = await Api.post('folder', 'Folder', 'getPublicWorkspace', param)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            const node = {
              id: res.data.id,
              pId: 0,
              title: '公共工作区',
              isLeaf: res.data.leafFlag,
              value: res.data?.id
            }
            this.targetClsObjectTreeData.value = [node]
            await this.loadTreeData(node)
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取公共工作区数据异常',
              content: res.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      //产品
      case FolderTypeEnum.PRODUCT:
        {
          const param = {
            data: [
              {
                filter: {},
                isNeedTotal: true,
                sort: 'DESC',
                orderBy: 'createTime'
              },
              {
                maxPageSize: 1000,
                curPage: 1,
                pageSize: 50,
                totalRows: 0,
                totalPages: 0,
                startIndex: 0,
                endIndex: 0
              }
            ]
          }
          const result = await Api.post('folder', 'Folder', 'listProductFolder', param)
          if (result && result.code == EnumRequestCode.SUCCESS) {
            const nodes = result.data.data.map((data: any) => {
              return {
                id: data.id,
                pId: 1,
                title: data.name,
                isLeaf: data.leafFlag,
                value: data?.id
              }
            })
            const node = {
              id: '1',
              pId: 0,
              title: '产品工作区',
              isLeaf: result.data.page.totalRows == 0 ? true : false,
              value: '1',
              disabled: true
            }
            nodes.push(node)
            this.targetClsObjectTreeData.value = nodes
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取产品工作区数据异常',
              content: result.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      // CAD
      case FolderTypeEnum.CAD:
        {
          const param = {
            data: []
          }
          const res = await Api.post('folder', 'Folder', 'getCADWorkspace', param)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            const node = {
              id: res.data.id,
              pId: 0,
              title: 'CAD工作区',
              isLeaf: res.data.leafFlag,
              value: res.data?.id
            }
            this.targetClsObjectTreeData.value = [node]
            await this.loadTreeData(node)
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取CAD工作区数据异常',
              content: res.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      case FolderTypeEnum.PROJECT:
        {
          const param = {
            data: [
              {
                filter: {},
                isNeedTotal: true,
                sort: 'DESC',
                orderBy: 'createTime'
              },
              {
                maxPageSize: 1000,
                curPage: 1,
                pageSize: 50,
                totalRows: 0,
                totalPages: 0,
                startIndex: 0,
                endIndex: 0
              }
            ]
          }
          const result = await Api.post('folder', 'Folder', 'listProjectWorkspace', param)
          if (result && result.code == EnumRequestCode.SUCCESS) {
            const nodes = result.data.data.map((data: any) => {
              return {
                id: data.id,
                pId: 1,
                title: data.name,
                isLeaf: data.leafFlag,
                value: data?.id
              }
            })
            const node = {
              id: '1',
              pId: 0,
              title: '项目工作区',
              isLeaf: result.data.page.totalRows == 0 ? true : false,
              value: '1',
              disabled: true
            }
            nodes.push(node)
            this.targetClsObjectTreeData.value = nodes
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取项目工作区数据异常',
              content: result.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      case FolderTypeEnum.STANDARD_PARTS:
        {
          const param = {
            data: [FolderTypeEnum.STANDARD_PARTS]
          }
          const res = await Api.post('folder', 'Folder', 'getFolderByType', param)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            const node = {
              id: res.data.id,
              pId: 0,
              title: '标准件',
              isLeaf: res.data.leafFlag,
              value: res.data?.id
            }
            this.targetClsObjectTreeData.value = [node]
            await this.loadTreeData(node)
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取标准件数据异常',
              content: res.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      case FolderTypeEnum.ELECTRONIC_COMPONENTS:
        {
          const param = {
            data: [FolderTypeEnum.ELECTRONIC_COMPONENTS]
          }
          const res = await Api.post('folder', 'Folder', 'getFolderByType', param)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            const node = {
              id: res.data.id,
              pId: 0,
              title: '元器件',
              isLeaf: res.data.leafFlag,
              value: res.data?.id
            }
            this.targetClsObjectTreeData.value = [node]
            await this.loadTreeData(node)
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取元器件数据异常',
              content: res.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      case FolderTypeEnum.COMMON_PARTS:
        {
          const param = {
            data: [FolderTypeEnum.COMMON_PARTS]
          }
          const res = await Api.post('folder', 'Folder', 'getFolderByType', param)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            const node = {
              id: res.data.id,
              pId: 0,
              title: '通用件',
              isLeaf: res.data.leafFlag,
              value: res.data?.id
            }
            this.targetClsObjectTreeData.value = [node]
            await this.loadTreeData(node)
            this.defaultExpandedKey.value = [node.id]
          } else {
            KNotification.error({
              title: '获取通用件数据异常',
              content: res.message!
            })
            this.targetClsObjectTreeData.value = []
            this.defaultExpandedKey.value = []
          }
        }
        break
      default:
        break
    }
  }

  /**
   * 异步加载子节点
   */
  async loadTreeData(treeNode: Record<string, any>) {
    return new Promise((resolve, reject) => {
      const param = {
        data: [
          treeNode.id,
          {
            rdmPageVO: {
              maxPageSize: 1000,
              curPage: 1,
              pageSize: 1000,
              totalRows: 0,
              totalPages: 0,
              startIndex: 0,
              endIndex: 0
            }
          }
        ]
      }
      Api.post('folder', 'Folder', 'listChildFolder', param).then(result => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          const node = this.targetClsObjectTreeData.value
          for (const item of result.data!) {
            node.push({
              id: item.id!,
              pId: treeNode.id,
              title: item.name,
              isLeaf: item.leafFlag,
              value: item.id
            })
          }
          this.targetClsObjectTreeData.value = node
          resolve(true)
        } else {
          KNotification.error({
            title: '获取子节点失败',
            content: result.message!,
            details: result.detail!
          })
        }
        resolve([])
      })
    })
  }
}

import {
  IKTreeNode,
  KNotification,
  KTreeViewViewModel,
  SimpleViewModel,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import lodash from 'lodash'
import { ref, watch } from 'vue'
import { Api, EnumRequestCode } from '../../client-srv'
import { KBasePropertyEditorViewModel } from '../editors/base'
import { CodeGenerateParams, KCodeGeneratorEmitsType, KCodeGeneratorPropType } from './interface'

/** KCodeGenerator */
export default class KCodeGeneratorViewModel extends KBasePropertyEditorViewModel<
  KCodeGeneratorEmitsType,
  KCodeGeneratorPropType
> {
  refClassInput = ref<SimpleViewModel>()
  /**编码规则树 */
  refCodeRuleTree = ref<KTreeViewViewModel>()
  /** 模态框状态 */
  expand = ref(false)
  /** 是否禁用确认按钮 */
  isDisableConfirm = ref(false)
  /** 选择的树节点 */
  selectedNode = ref<IKTreeNode>()
  /** 编码 */
  code = ref<string>('')
  /** 编码生成参数 */
  params = ref<CodeGenerateParams>()

  constructor(options: ViewModelOptions<KCodeGeneratorPropType>) {
    super(options)
    if (options.props.params) {
      this.params.value = options.props.params
    }

    watch(
      () => options.props.value,
      newValue => {
        this.setCode(newValue)
      },
      { immediate: true }
    )
  }

  viewDidMount() {}
  /**
   * 加载编码规则树数据
   */
  loadData(): Promise<Array<IKTreeNode>> {
    if (this.props.isDesigner) return Promise.resolve([])
    return new Promise((resolve, reject) => {
      const param = {
        data: []
      }
      Api.post('code', 'CodeManagement', 'getCodeRuleList', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          const children = res.data.map((data: any) => {
            return {
              id: data.ruleCode,
              name: data.ruleName,
              code: data.ruleCode
            }
          }) as Array<IKTreeNode>
          resolve(children)
        } else {
          KNotification.error({
            title: '获取失败',
            content: res.message || '获取编码规则错误',
            details: res.detail
          })
          reject(void 0)
        }
      })
    })
  }
  /**
   * 设置编码
   * @param newValue
   */
  setCode(newValue: string) {
    this.code.value = lodash.cloneDeep(newValue)
    this.refClassInput.value?.setValue(newValue)
  }

  /**
   * 选择节点以后
   * @param event
   */
  afterSelect(event: TreeViewSelectEventArgs) {
    this.selectedNode.value = event.node as IKTreeNode
  }
  /**
   * 根据节点信息更新数据
   * @description
   */
  updateValueByNode() {
    // 返回值
    this.emit('update:value', this.code.value)
    this.refClassInput.value?.setValue(this.code.value!)
  }

  /** 确认选择 */
  async onConfirm() {
    if (!this.selectedNode.value) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个编码规则'
      })
      return
    }

    // 如果外界没有传入参数，则从面板中获取生成编码所需参数
    if (!this.params.value) {
      const version = this.api.getPropertyValue('version')
      const iteration = this.api.getPropertyValue('iteration')
      if (version && iteration) {
        this.params.value = {
          version: version,
          iteration: iteration
        }
      } else {
        // 如果未获取到参数，则初始化参数
        this.params.value = {
          version: 'A',
          iteration: '1'
        }
      }
    }

    //调用编码生成器接口
    const param = {
      data: [
        {
          codeRule: this.selectedNode.value.code,
          params: this.params.value
        }
      ]
    }
    const result = await Api.post('code', 'CodeManagement', 'generateCode', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.code.value = result.data
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '生成编码失败',
        details: result.detail
      })
      return
    }
    // 不显示模态框
    this.expand.value = false
    this.updateValueByNode()
  }

  public setValue(newValue: any, setChanged?: boolean) {
    this.setCode(newValue)
  }

  public getValue() {
    return this.code.value
  }
}

import {
  AppContext,
  BaseViewModel,
  KNotification,
  KSegmentViewModel,
  KToolStripCheckboxItemViewModel,
  PageManager,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KChangeRequestCreateEmitsType, KChangeRequestCreatePropType } from './interface'
import { ref } from 'vue'
import { Api, CommonClientSrv, EnumRequestCode, KFolderSelectorViewModel, KObjectCreatePanelViewModel } from '@kmsoft/ebf-common'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { KIssueReportAffectedViewsGridViewModel } from '../../../controls/issue-report-affected-views'
import KIssueReportEdit from '../change-request-edit'
import { reject } from 'lodash'

/** KChangeRequestCreate */
export default class KChangeRequestCreateViewModel extends BaseViewModel<
  KChangeRequestCreateEmitsType,
  KChangeRequestCreatePropType
> {
  formData = ref<any>({})
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()

  /**
   * 继续创建下一个
   */
  createNext = ref<boolean>(false)
  /**
   * 打开对象
   */
  openObj = ref<boolean>(true)

  /**
   * 是否正在保存中
   */
  isConfirming = ref<boolean>(false)

  /**
   * 是否从工作区通用创建入口而来
   */
  isFromCommonCreate = ref<boolean>(false)

  constructor(options: ViewModelOptions<KChangeRequestCreatePropType>) {
    super(options)
    this.initForm()
  }

  initForm() {
    this.formData = ref<any>({
      number: '',
      name: '',
      type: 'DESIGN_CHANGE',
      businessDecisionCategory: 'APPROVED',
      description: '',
      proposedSolution: '',
      priority: 'LOW',
      complexity: 'Low',
      recurringCostEst: '',
      nonRecurringCostEst: '',
      needDate: null,
      changeIssueViews: [],
      folder: {
        id: '',
        clazz: ''
      },
      secondary: null
    })
  }

  viewDidMount() {
    /**
     * 场景1：从导航菜单进入, 不携带任何参数
     * 场景2：从工作区-新建按钮进入, 携带[文件夹]参数
     * 场景3：从工作区-创建变更请求按钮进入, 携带[文件夹、受影响对象]参数
     * 场景4：从工作区-编辑按钮-创建变更请求按钮进入，携带【文件夹】参数
     */
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
      this.isFromCommonCreate.value = true
    }
    if (this.props.formValue?.plmChangeIssueAffectedViews) {
      this.formData.value.plmAffectedTargetViews = this.props.formValue.plmChangeIssueAffectedViews || []
    }
  }

  /** 校验 */
  async validate() {
    let validFlag = this.refObjectCreatePanel.value?.validateFormValue()
    const saveValue = this.getValue() as any
    const plmAffectedTargetViewsCur = saveValue.plmAffectedTargetViews as any
    if (plmAffectedTargetViewsCur != null && plmAffectedTargetViewsCur.length > 0) {
      const invalidItem = plmAffectedTargetViewsCur.find((m: { handlingMethod: any }) => !m.handlingMethod)
      if (invalidItem) {
        KNotification.warning({
          title: '属性空',
          content: `处理方式不能为空`
        })
        validFlag = false
      }
    }
    return validFlag
  }

  public getValue() {
    let createObject = this.refObjectCreatePanel.value?.getValue() as any
    // 类型
    // const rdmExtensionType = this.rdmExtensionType.value
    createObject = { ...createObject }

    // 从变更问题报告编辑页面来, 携带变更问题报告对象参数, 自动补充
    if (this.props.formValue?.changeIssueViews) {
      createObject['changeIssueViews'] = this.props.formValue.changeIssueViews
    }

    return createObject
  }

  public async save(): Promise<any> {
    if (await this.validate()) {
      const saveValue = this.getValue() as any
      const plmAffectedTargetViewsCur = saveValue.plmAffectedTargetViews as any
      const plmAffectedTargetViewsNew = [] as any
      if (plmAffectedTargetViewsCur != null && plmAffectedTargetViewsCur.length > 0) {
        // const invalidItem = plmAffectedTargetViewsCur.find((m: { handlingMethod: any }) => !m.handlingMethod);
        // if (invalidItem) {
        //   KNotification.warning({
        //     title: '属性空',
        //     content: `处理方式不能为空`
        //   })
        //   return false;
        // }

        plmAffectedTargetViewsCur.forEach(function(item: any) {
          plmAffectedTargetViewsNew.push({
            target: {
              id: item.id,
              rdmExtensionType: item.rdmExtensionType
            },
            handlingMethod: item.handlingMethod
          })
        })
        saveValue.plmChangeRequestAffectedViews = plmAffectedTargetViewsNew
        delete saveValue.plmAffectedTargetViewsNew
      }
      if (saveValue!.secondary) {
        const secondary = saveValue!.secondary.map((element: string) => {
          return { id: element }
        })
        saveValue!.secondary = secondary
      }
      const params = {
        data: [
          {
            ...saveValue
          }
        ]
      }

      // 是否是自定义保存按钮
      const isConfirming = this.props.showExtButton
      if (isConfirming) {
        this.setLoading(true)
      }
      const result = await Api.post('change', 'ChangeRequest', 'create', params)
      this.setLoading(false)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        if (this.isFromCommonCreate.value) {
          return result
        }
        KNotification.success({
          title: '系统提示',
          content: '新建成功'
        })
        if (this.props.showAddButton) {
          // 保存成功后，初始化状态
          this.cleanAll()
        }
        return result
      } else {
        KNotification.error({
          title: '操作失败',
          content: result.message || '新建失败',
          details: result.detail
        })
        return result
      }
    }
    return Promise.resolve(false)
  }

  cleanAll() {
    this.initForm()
    this.refObjectCreatePanel.value?.refresh()
    const refFolder = this.refObjectCreatePanel.value?.getByRecursion('_#folder#1726821445452') as KFolderSelectorViewModel
    const refObjGrid = this.refObjectCreatePanel.value?.getByRecursion(
      'KChangeRequestAffectedViewsGrid#1726821598204'
    ) as KIssueReportAffectedViewsGridViewModel
    refFolder.cleanForce()
    refObjGrid.cleanAllForce()
    // TODO: 后续附件实现后, 此处还需要清理附件内容
  }

  onCancel() {
    this.emit('cancel')
  }

  onConfirm() {
    this.emit('confirm', {
      createNext: this.createNext.value,
      openObj: this.openObj.value
    })
  }

  setLoading(status: boolean) {
    this.isConfirming.value = status
  }
}

import { BaseViewModel, ViewModelOptions, KDataGridViewModel, SelectOption, request, KNotification } from '@kmsoft/upf-core'
import { KAddPlanDialogEmitsType, KAddPlanDialogPropType } from './interface'
import { configSrv } from '@kmsoft/ebf-common'
import { ref } from 'vue'
import { debounce } from 'lodash'

/** KAddPlanDialog */
export default class KAddPlanDialogViewModel extends BaseViewModel<KAddPlanDialogEmitsType, KAddPlanDialogPropType> {
  refPlanGrid = ref<KDataGridViewModel>()
  selectValue = ref<string>()
  inputValue = ref<string>()
  selectOptions = ref<SelectOption[]>([])
  pmsServerUrl = ref<string>('')

  statusList = [
    { label: '未开始', value: 10 },
    { label: '进行中', value: 20 },
    { label: '已完成', value: 30 },
    { label: '暂停', value: 40 },
    { label: '终止', value: 50 }
  ]

  planStatusList = [
    { label: '已创建', value: 10 },
    { label: '评审中', value: 20 },
    { label: '已发布', value: 30 },
    { label: '评审终止', value: 40 }
  ]

  planTypeList = [
    { label: '一般计划', value: 10 },
    { label: '独立任务', value: 20 },
    { label: '里程碑计划', value: 30 },
    { label: '阶段计划', value: 40 },
    { label: '项目计划', value: 0 }
  ]

  constructor(options: ViewModelOptions<KAddPlanDialogPropType>) {
    super(options)
    /** 获取pms域名 */
    const host = window.location.host
    const pmsServer = configSrv.pmsServer()
    const hosts = host.split('.')
    this.pmsServerUrl.value = `${pmsServer}.${hosts.length <= 1 ? 'dev.kmsaasdev.com' : hosts.slice(1).join('.')}`
  }

  viewDidMount() {
    this.getProductList()
  }

  async getProductList(value?: string) {
    const param = {
      classCode: 'PMS_PROJECT',
      filters: [{ key: 'CNAME', operator: 'include', value }],
      sort: { orders: [] },
      page: { pageIndex: 1, pageSize: 100 },
      rowGroupCols: {}
    }
    const res = await request.post(`${this.pmsServerUrl.value}/kmPmsApi/project/listAll`, param)
    const options = res?.data?.rows.map((item: any) => {
      return { label: item.CNAME, value: item.CVERSION_GROUP }
    })
    this.selectOptions.value = options
  }

  handleSearch = debounce((value: string) => {
    this.getProductList(value)
  }, 500)

  queryData() {
    if (!this.selectValue.value) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择项目'
      })
      return
    }
    this.refPlanGrid.value?.clearSelectedRows()
    this.refPlanGrid.value?.refresh()
  }

  async loadData() {
    if (!this.selectValue.value) return []
    const param = {
      projectVersionGroupId: this.selectValue.value ? Number(this.selectValue.value) : null,
      keyWord: this.inputValue.value
    }
    const res = await request.get(`${this.pmsServerUrl.value}/kmPmsApi/planTask/listTaskByProject`, param)
    if (res?.data) {
      return res.data
    } else {
      KNotification.error({
        title: '系统提示',
        content: '查询失败'
      })
      return []
    }
  }

  getValue() {
    const data = this.refPlanGrid.value?.getSelectedRows()
    return data
  }
}


import { watch, ref, Ref, computed } from 'vue'
import { KObjectPropertyEditorEventEmits, KObjectPropertyEditorPropOptions } from './interface'
import { ValueChangeEventArgs, defineView } from '@kmsoft/upf-core'
import KObjectPropertyEditorViewModel from './KObjectPropertyEditorViewModel'
import { EnumPropertyType, SelectOption, getComponentInfoByEditor, getComponentEditor } from './utils'

/**
 * 属性值编辑器，根据编辑类型分发
 */
export default defineView({
  inheritAttrs: true,
  name: 'KObjectPropertyEditor',
  props: KObjectPropertyEditorPropOptions,
  emits: KObjectPropertyEditorEventEmits,
  viewModel: KObjectPropertyEditorViewModel,
  setup(props, { emit }) {
    /** 下拉选项 */
    const options = ref<SelectOption<string>[]>()
    const localValue: Ref<any> = ref<any>(props.value)
    const componentType = computed(() => {
      const editorInfo = props.field

      if (!editorInfo) {
        console.warn('规则值组件选择: 未设置编辑类型, 默认使用input组件。')
      }

      const componentInfo = getComponentEditor(props.modeCode, editorInfo.type, editorInfo) //getComponentInfoByEditor(editorInfo)
      options.value = componentInfo.options

      return componentInfo.componentType
    })

    watch(localValue, (newValue, oldValue) => {
      emit('update:value', newValue || null)
      emit('update', newValue || null)
    })

    watch(
      () => props.value,
      newValue => {
        localValue.value = newValue
      }
    )

    /**
     * 可编辑下拉框的值变化
     */
    const onEditableSelectChange = (c: ValueChangeEventArgs) => {
      if (c.newValue.length > 0) {
        localValue.value = c.newValue[c.newValue.length - 1]
      }
    }

    return {
      localValue,
      EnumPropertyType,
      componentType,
      options,
      onEditableSelectChange
    }
  }
})

import { EnumSupportObjectNumMode, EnumToolStripItemKeys, ObjectToolStripItem } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  KTreeNode,
  TreeViewSelectEventArgs,
  TreeViewLoadDataFunc,
  EnumToolStripCompType
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFolderManagePropOptions = {
  ...BaseViewPropOptions,
  folderId: VuePropTypes.string().def(),
  /** 展开节点时加载子节点的数据库请求方法，返回promise **/
  loadData: VuePropTypes.func<TreeViewLoadDataFunc>().def(),
  /** 是否显示CAD操作按钮 */
  showCADOperation: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KFolderManagePropType = ViewPropsTypeExtract<typeof KFolderManagePropOptions>

/** 事件 */
export const KFolderManageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFolderManageEmitsType = ViewEmitsTypeExtract<typeof KFolderManageEventEmits>

/**
 * 生命周期状态枚举
 */
export const LifecycleStateEnum = [
  {
    label: '开始',
    value: 'Start'
  },
  {
    label: '结束',
    value: 'End'
  },
  {
    label: '已发布',
    value: 'Released'
  },
  {
    label: '审核中',
    value: 'UnderReview'
  },
  {
    label: '工作中',
    value: 'InWork'
  },
  {
    label: '已废弃',
    value: 'Obsoleted'
  },
  {
    label: '已创建',
    value: 'Openned'
  },
  {
    label: '已处理',
    value: 'Processed'
  },
  {
    label: '处理中',
    value: 'Processing'
  },
  {
    label: '已采纳',
    value: 'Accepted'
  },
  {
    label: '评估中',
    value: 'Evaluated'
  },
  {
    label: '已识别',
    value: 'Recognized'
  },
  {
    label: '验证中',
    value: 'Validating'
  },
  {
    label: '已确认',
    value: 'Confirmed'
  },
  {
    label: '确认中',
    value: 'Confirming'
  },
  {
    label: '已提出',
    value: 'Raised'
  },
  {
    label: '已关闭',
    value: 'Closed'
  },
  {
    label: '已废弃',
    value: 'Obsoleted'
  },
  {
    label: '已取码',
    value: 'Yiquma'
  }
]

/**
 * 工作状态枚举
 */
export const WorkingStateEnum = [
  {
    label: '工作中',
    value: 'INWORK'
  },
  {
    label: '已检入',
    value: 'CHECKED_IN'
  },
  {
    label: '已检出',
    value: 'CHECKED_OUT'
  }
]

/**
 * 工作状态枚举
 */
export const WorkingStateDesc = [
  {
    label: '本人检出',
    value: 'INWORK'
  },
  {
    label: '已检入',
    value: 'CHECKED_IN'
  },
  {
    label: '他人检出',
    value: 'CHECKED_OUT'
  }
]

import { KObjectPropertyPanelViewModel } from '@kmsoft/ebf-common'
import { Api } from '@kmsoft/ebf-common/src/client-srv/base/Api'
import { BaseViewModel, SimpleViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { nextTick, ref, watch } from 'vue'
import { KCategoryRenderViewModel } from '../../../controls/category-render'
import { KAttrsCategoryRenderViewModel } from '../../../controls/attrs-category-render'
import { KCategorySelectorViewModel } from '../../../controls/category-selector'
import { KPartFormEmitsType, KPartFormPropType } from './interface'

/** KPartForm */
export default class KPartFormViewModel extends BaseViewModel<KPartFormEmitsType, KPartFormPropType> {
  classCode = ref<string>()
  newModelCode = ref<string>()
  // 表单响应式数据
  formData = ref<any>({
    rdmExtensionType: 'Part',
    number: '',
    name: '',
    version: 'A',
    assemblyMode: 'Component',
    partViewEnum: 'DESIGN',
    phase: 'Design',
    defaultUnitEnum: 'EA',
    source: 'Make',
    lifecycleStateCode: 'InWork',
    extAttrs: [],
    attachment: [],
    partView: { name: 'Design' }
  })

  private categorySelectorViewModel: SimpleViewModel

  constructor(options: ViewModelOptions<KPartFormPropType>) {
    super(options)
    // 获取外部传值
    this.formData.value = { ...this.formData.value, ...options.props.formValue }
    watch(
      () => options.props.modelCode,
      () => {
        this.newModelCode.value = options.props.modelCode
      },
      {
        immediate: true
      }
    )
    watch(
      () => this.classCode.value,
      () => {
        this.newModelCode.value = this.classCode.value
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.newModelCode.value = this.props.modelCode
  }

  // 通用布局

  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()

  public async save() {
    let plmPartCreateObject = this.getValue()

    const folderId = plmPartCreateObject?.folder.id

    plmPartCreateObject = {
      ...plmPartCreateObject,
      folder: {
        id: folderId === '' ? '-1' : folderId,
        clazz: 'Folder'
      }
    } as any
    if (plmPartCreateObject.attachment) {
      plmPartCreateObject.attachment = plmPartCreateObject.attachment.map((element: string) => {
        return { id: element }
      })
    }

    return Api.post('part', 'Part', 'create', { data: [plmPartCreateObject] })
  }

  /**
   * 设置数据源
   * @param value 表单的值
   * @returns
   */
  public setValue(value: any) {
    this.formData.value = { ...value, extAttrsList: value?.extAttrs }
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    let plmPartCreateObject = this.refObjectCreatePanel.value?.getValue() as any
    if (plmPartCreateObject?.extAttrsList?.length) {
      plmPartCreateObject.extAttrs = plmPartCreateObject.extAttrs
        ? plmPartCreateObject.extAttrs.concat(plmPartCreateObject?.extAttrsList)
        : plmPartCreateObject?.extAttrsList
    }
    delete plmPartCreateObject.extAttrsList
    // 零部件类型
    plmPartCreateObject = { ...plmPartCreateObject, rdmExtensionType: plmPartCreateObject.classCode } as any

    if (this.props.classTreeSelectDisplay) {
      const classCode = this.classCode.value
      plmPartCreateObject = { ...plmPartCreateObject, rdmExtensionType: classCode } as any
    }

    return plmPartCreateObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }
  loaded() {
    // 分类组件
    this.categorySelectorViewModel = this.getByRecursion('_#Classification#1726825933692', this) as
      | KCategorySelectorViewModel
      | any
    this.categorySelectorViewModel?.on('select' as any, (changedEventArgs: any) => {
      this.refObjectCreatePanel.value?.openCategoryAttributes(changedEventArgs.getValue()?.value)
    })
    // 分类清除事件
    this.categorySelectorViewModel?.on('clear' as any, () => {
      this.refObjectCreatePanel.value?.openCategoryAttributes(null)
    })
  }
}

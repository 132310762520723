
import { defineView } from '@kmsoft/upf-core'
import { KPartMaterialBomFormPropOptions, KPartMaterialBomFormEventEmits } from './interface'
import KPartMaterialBomFormViewModel from './KPartMaterialBomFormViewModel'
import { clsCodeMap, EnumLifecycleState, EnumWorkingState } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPartMaterialBomForm',
  props: KPartMaterialBomFormPropOptions,
  emits: KPartMaterialBomFormEventEmits,
  viewModel: KPartMaterialBomFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const sourceOptions = [
      { label: '自制', value: 'Make' },
      { label: '采购', value: 'Buy' },
      { label: '购买-单一供应源', value: 'Singlesource' }
    ]
    const assemblyModeOptions = [
      { label: '可分离', value: 'Separable' },
      { label: '不可分离', value: 'Inseparable' },
      { label: '组件', value: 'Component' }
    ]
    const phaseOptions = [
      { label: '设计', value: 'D' },
      { label: '试制', value: 'T' },
      { label: '量产', value: 'S' }
    ]
    const lifecycleStateOptions = EnumLifecycleState._list
    const workingStateOptions = EnumWorkingState._list
    return { sourceOptions, assemblyModeOptions, phaseOptions, lifecycleStateOptions, workingStateOptions, clsCodeMap }
  }
})

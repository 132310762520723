import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  SimpleViewModel,
  ViewModelOptions,
  MemoryCacheFactory,
  SelectOption
} from '@kmsoft/upf-core'
import { KChangeOrderAffectedObjectGridEmitsType, KChangeOrderAffectedObjectGridPropType } from './interface'
import { computed, nextTick, ref, watch } from 'vue'
import {
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  KObjectSelectorViewModel,
  ObjectClientSrv,
  EnumWorkingState,
  EnumLifecycleState,
  PartViewMemoryCache,
  EnumHandingMethod
} from '@kmsoft/ebf-common'
import { KAffectedObjectCreateActivity } from './affected-object-create-activity'
import { KAffectedObjectCreateActivityViewModel } from './affected-object-create-activity'
import lodash from 'lodash'
import { KChangeOrderActivityPlanGridViewModel } from '../change-order-activity-plan-grid'
import { KAppendChangeActivity, KAppendChangeActivityViewModel } from './append-change-activity'
import { KChangeOrderActivityPlanGridDataInter } from '../change-order-activity-plan-grid/interface'
import {
  KIssueReportRelationPart,
  KIssueReportRelationPartViewModel
} from '../issue-report-affected-views/issue-report-relation-part'
import {
  KIssueReportRelationDoc,
  KIssueReportRelationDocViewModel
} from '../issue-report-affected-views/issue-report-relation-doc'
import _ from 'lodash'

/** KChangeOrderAffectedObjectGrid */
export default class KChangeOrderAffectedObjectGridViewModel extends SimpleViewModel<
  KChangeOrderAffectedObjectGridEmitsType,
  KChangeOrderAffectedObjectGridPropType
> {
  gridData = ref<any[]>([])
  refDataGrid = ref<KDataGridViewModel>()
  modified = ref<boolean>(false)
  originData = ref<any[]>([])
  isEdit = ref<boolean>(false)

  handingMethodOptions = ref<Array<SelectOption>>([
    { label: '报废', value: 1 },
    { label: '继续使用', value: 2 }
  ])

  cellEditingStarted(event: any) {
    this.isEdit.value = true
  }
  cellEditingStopped() {
    this.isEdit.value = false
  }

  associateChangeActivity = computed(() => {
    const activityPlanDataList = this.getRefActivityPlan().getValue() || []
    let allActivityPlanIds = activityPlanDataList.reduce((arr: string[], cur: any) => {
      const ids = cur.affectedObjectList.map((r: any) => r.id) || []
      arr.push(...ids)
      return arr
    }, [])
    allActivityPlanIds = [...new Set(allActivityPlanIds)]

    return (row: any) => {
      return allActivityPlanIds.includes(row.id) ? '是' : '否'
    }
  })

  constructor(options: ViewModelOptions<KChangeOrderAffectedObjectGridPropType>) {
    super(options)
    watch(
      () => this.props.readonly,
      (newValue, oldVal) => {
        !newValue ? this.refDataGrid.value?.beginEdit() : this.refDataGrid.value?.endEdit()
        // this.refObjGrid.value?.setToolStripDisabled(newValue as boolean)
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  getRefActivityPlan() {
    return this.getByRecursion(
      'KChangeOrderActivityPlanGrid',
      this.props.api.getCurrentPanelViewModel() as BaseViewModel
    ) as KChangeOrderActivityPlanGridViewModel
  }

  public setValue(newValue: any, setChanged?: boolean) {
    if (!this.isEdit.value) {
      //没有结束编辑时不要将新的网格数据更新到原始数据中
      this.originData.value = _.cloneDeep(newValue)
    }
    this.gridData.value = lodash.cloneDeep(newValue)
  }

  /** 添加 **/
  addData() {
    if (!this.props.readonly) {
      this.refDataGrid.value?.beginEdit()
    }

    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part', 'Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'lifecycleState.internalName',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: ['Released']
            }
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Part', 'Document']
      },
      getContainer: this.refDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        // 获取当前弹出组件实例的ViewModel
        const selectorViewModel = event.viewModel as KObjectSelectorViewModel
        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows() || []
        const hasRepeat = this.checkRepeat(selectedRows)
        if (hasRepeat) {
          event.cancel = true
          return
        }
        this.addGridData(selectedRows)
      }
    })
  }

  checkRepeat(selectData: Record<string, any>[]) {
    let hasRepeat = false
    let hasNoReleased = false
    const selectMasterId = selectData.map(item => {
      if (item.lifecycleStateCode !== 'Released') hasNoReleased = true
      if (!hasRepeat) {
        hasRepeat = this.gridData.value.some(data => data.targetMasterId == item.master.id || data?.master?.id == item.master.id)
      }
      return item.master.id
    })
    if (selectMasterId.length != new Set(selectMasterId).size) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在重复数据'
      })
      return true
    }
    if (hasRepeat) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在已添加的数据'
      })
      return true
    }
    if (hasNoReleased) {
      KNotification.warn({
        message: '系统提示',
        description: '只能添加已发布的数据'
      })
      return true
    }
    return false
  }

  addGridData(gridData: any[]) {
    const addDataList = this.gridDataFilter(gridData)
    if (addDataList.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行已在受影响对象中'
      })
      return
    }
    this.modified.value = true
    this.gridData.value.push(...addDataList)
    this.refresh()
  }

  gridDataFilter(gridData: any[]) {
    // 去重
    const addDataList = gridData.map((item: any) => this.propertyConvert(item)) || []
    if (!this.isEdit.value) {
      //没有结束编辑时不要将新的网格数据更新到原始数据中
      this.originData.value = _.cloneDeep(gridData)
    }
    return addDataList
  }

  propertyConvert(item: any) {
    // 状态
    item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
    // 检入标记
    item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
    return item
  }

  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !selectedRows.some(row => row.id === item.id))
      //删除活动计划中的受影响对象
      this.getRefActivityPlan().removeAffectedObjectByIds(selectedRows.map(row => row.id))
    }
  }
  /** 刷新 **/
  refresh() {
    this.refDataGrid?.value?.refresh()
  }
  /** 相关零部件 **/
  async relatedPart() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows && rows.length !== 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const className = await ObjectClientSrv.getModelGroupByCode(rows[0].rdmExtensionType!)
    if (className !== 'part') {
      KNotification.warn({
        message: '系统提示',
        description: '只能选择零部件行'
      })
      return
    }

    KDialog.show({
      title: '相关零部件',
      size: { width: 1200, height: 800 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.refDataGrid.value?.getContainer(),
      content: KIssueReportRelationPart,
      props: {
        objParam: {
          id: rows[0].targetId || rows[0].id,
          rdmExtensionType: rows[0].targetExtensionType || rows[0].rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      },
      extraButtons: ['添加'],
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KIssueReportRelationPartViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()
        const hasRepeat = this.checkRepeat(selectedRows)
        if (hasRepeat) {
          event.cancel = true
          return
        }
        this.addGridData(selectedRows)

        if (event.dialogResult === '添加') {
          event.cancel = true
        }
      }
    })
  }

  filterReleasedData(selectedRows: any, showWarn: boolean) {
    const filterReleasedRows: Array<Record<string, any>> = []
    if (selectedRows != null && selectedRows.length > 0) {
      selectedRows.forEach((item: any) => {
        // 选择零部件时, 通过返回的lifecycleStateCode属性判断
        if (item.lifecycleStateCode == 'Released') {
          filterReleasedRows.push(item)
        }
      })
    }

    if (filterReleasedRows.length == 0 && showWarn) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中没有已发布数据'
      })
      return []
    }
    if (filterReleasedRows.length != selectedRows.length && showWarn) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中包含未发布数据'
      })
      return []
    }
    return filterReleasedRows
  }
  /** 相关文档 **/
  relatedDocument() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows && rows.length !== 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.show({
      title: '相关文档',
      size: { width: 1000, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.refDataGrid.value?.getContainer(),
      content: KIssueReportRelationDoc,
      props: {
        objParam: {
          id: rows[0].targetId || rows[0].id,
          rdmExtensionType: rows[0].targetExtensionType || rows[0].rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KIssueReportRelationDocViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()
        const hasRepeat = this.checkRepeat(selectedRows)
        if (hasRepeat) {
          event.cancel = true
          return
        }
        this.addGridData(selectedRows)
      }
    })
  }
  /** 创建变更活动 **/
  createChangeActivity() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      if (!this.hasAssociateChangeActivity(selectedRows)) {
        return
      }
      KDialog.show({
        title: '创建变更活动',
        size: { width: 800, height: 600 },
        props: {},
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KAffectedObjectCreateActivity,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return

          const formViewModel = event.viewModel as KAffectedObjectCreateActivityViewModel
          let changeActivityObject = formViewModel.getValue()
          changeActivityObject = lodash.cloneDeep(changeActivityObject)
          //添加选中的受影响对象
          changeActivityObject!.affectedObjectList = selectedRows
          changeActivityObject!.id = lodash.uniqueId()
          changeActivityObject!.className = 'ChangeActivity'
          // 添加活动
          this.getRefActivityPlan().addActivity(changeActivityObject)
        }
      })
    } else {
      KNotification.info({
        message: '至少要勾选一条数据操作！'
      })
    }
  }
  /** 添加至变更活动 **/
  appendChangeActivity() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    const refActivityPlan = this.getRefActivityPlan()
    const dataSource = refActivityPlan.getValue()
    if (selectedRows.length > 0) {
      if (!this.hasAssociateChangeActivity(selectedRows)) {
        return
      }
      KDialog.show({
        title: '添加至变更活动',
        size: { width: 800, height: 600 },
        props: {
          dataSource
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KAppendChangeActivity,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return

          const formViewModel = event.viewModel as KAppendChangeActivityViewModel
          const activitySelectedRows = formViewModel.getSelectedRows()
          const activitySelectedRow = activitySelectedRows.length == 0 ? null : activitySelectedRows[0]

          if (activitySelectedRow) {
            refActivityPlan.addActivityItem(activitySelectedRow.id, selectedRows)
          }
        }
      })
    } else {
      KNotification.info({
        message: '至少要勾选一条数据操作！'
      })
    }
  }

  /** 判断是否关联变更活动 **/
  hasAssociateChangeActivity(rows: any[]): boolean {
    const associateChangeActivity: string[] = []
    rows.forEach(row => {
      if (this.associateChangeActivity.value(row) === '是') {
        associateChangeActivity.push(row.name)
      }
    })

    if (associateChangeActivity.length > 0) {
      KNotification.info({
        message: `【${associateChangeActivity.join('、')}】已关联变更活动`
      })
      return false
    }

    return true
  }

  isModified(): boolean {
    // 编辑时默认认为修改
    // return this.modified.value

    this.refDataGrid.value?.endEdit()
    const data = this.getValue()
    const compare = this.compareData(data, this.originData.value)
    if (compare && !this.modified.value) {
      return false
    }
    return true
  }

  compareData(newData: any, oldData: any) {
    return JSON.stringify(newData) == JSON.stringify(oldData)
  }

  getModifiedValue() {
    return this.getValue()
  }

  public getValue() {
    return this.gridData.value || []
  }
  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: Record<string, any>) {
    const param = {
      id: row?.targetId || row.id!,
      modelCode: row.rdmExtensionType!,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row.rdmExtensionType!)
    }
    ObjectClientSrv.openObj(param)
  }

  getView(row: any) {
    if (row.partView?.description) {
      return '(' + row.partView?.description + ')'
    } else if (row.targetViewId) {
      const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
      const partView = cacheInstance.getAll()
      if (row.targetViewId) {
        const view = partView.filter(item => item.id == row.targetViewId)[0].description
        return '(' + view + ')'
      }
    }
    return ''
  }
}

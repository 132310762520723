import { IKClassDefinition, IKMetaPropertyDefinition } from './interface'
import { utils } from '@kmsoft/upf-core'

/**对象类枚举 */
const enum EnumClass {
  ChangeActivity = 'ChangeActivity',
  ChangeOrder = 'ChangeOrder',
  PromoteActivity = 'PromoteActivity',
  ChangeIssue = 'ChangeIssue',
  ChangeRequest = 'ChangeRequest',
  Distribution = 'Distribution',
  ManagedBaseline = 'ManagedBaseline',
  BOMLink = 'BOMLink',
  BOMSubstituteLink = 'BOMSubstituteLink'
}

/**待增加的属性集合    */
const PropertiesList = [
  {
    nullable: 0,
    defaultValue: null,
    displayName: '编码',
    clsPropId: '1831929372781522949',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '编码',
    predefined: 1,
    propCode: 'Number',
    classId: '673281138176827392',
    readonly: 1,
    editType: 11,
    columnWidth: null,
    id: '1837056798424666112',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: '{}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 50,
    column: 'number',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: '{}',
    changeable: 0,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 1,
    defaultValue: null,
    displayName: '名称',
    clsPropId: '1831929372802494472',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '名称',
    predefined: 1,
    propCode: 'Name',
    classId: '673281138176827392',
    readonly: 1,
    editType: 1,
    columnWidth: null,
    id: '1837056798391111680',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: null,
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 50,
    column: 'name',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: null,
    changeable: null,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 1,
    defaultValue: null,
    displayName: '描述',
    clsPropId: '1831929379328831494',
    className: '文档',
    remark: null,
    alignType: 1,
    propName: '描述',
    predefined: 1,
    propCode: 'Description',
    classId: '673281139388981248',
    readonly: 1,
    editType: 1,
    columnWidth: null,
    id: '1834485238813749248',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: null,
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 50,
    column: 'description',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: null,
    changeable: null,
    belongClsId: '673281139388981248'
  },
  {
    nullable: 1,
    defaultValue: null,
    displayName: '数据状态',
    clsPropId: '1833741509036548100',
    className: '文档',
    remark: null,
    alignType: 1,
    propName: '数据状态',
    predefined: 1,
    propCode: 'LifecycleStateCode',
    classId: '673281139388981248',
    readonly: 1,
    editType: 27,
    columnWidth: null,
    id: '1834108891953049600',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: '{"refClsCode":"LifecycleState"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 50,
    column: 'lifecycleStateCode',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: '{"refClsCode":"LifecycleState"}',
    changeable: 0,
    belongClsId: '673281139388981248'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '来源',
    clsPropId: '1831929372781522947',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '来源',
    predefined: 1,
    propCode: 'Source',
    classId: '673281138176827392',
    readonly: 1,
    editType: 7,
    columnWidth: null,
    id: '1837056798420471809',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs:
      '{"optionType":"options","options":[{"id":"PTlnLBpfQ1","label":"自制","value":"Make"},{"id":"bzA2z8wWjN","label":"采购","value":"Buy"},{"id":"bzA2z8wWjZ", "label": "购买-单一供应源", "value": "Singlesource"}]}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 50,
    column: 'source',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs:
      '{"optionType":"options","options":[{"id":"PTlnLBpfQ1","label":"自制","value":"Make"},{"id":"bzA2z8wWjN","label":"采购","value":"Buy"},{"id":"bzA2z8wWjZ", "label": "购买-单一供应源", "value": "Singlesource"}]}',
    changeable: 0,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 1,
    defaultValue: null,
    displayName: '工作状态',
    clsPropId: '1831929372798300164',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '工作状态',
    predefined: 1,
    propCode: 'WorkingState',
    classId: '673281138176827392',
    readonly: 1,
    editType: 7,
    columnWidth: null,
    id: '1837317136231534592',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs:
      '{"refClsCode":"WorkingState","optionType":"options","options":[{"id":"dm1806QHlB","label":"工作中","value":"INWORK"},{"id":"irJET875fe","label":"已检入","value":"CHECKED_IN"},{"id":"QBLD9OHTty","label":"已检出","value":"CHECKED_OUT"}]}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 100,
    column: 'workingState',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs:
      '{"refClsCode":"WorkingState","optionType":"options","options":[{"id":"dm1806QHlB","label":"工作中","value":"INWORK"},{"id":"irJET875fe","label":"已检入","value":"CHECKED_IN"},{"id":"QBLD9OHTty","label":"已检出","value":"CHECKED_OUT"}]}',
    changeable: 0,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 1,
    defaultValue: null,
    displayName: '检出时间',
    clsPropId: '1831929372789911552',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '检出时间',
    predefined: 1,
    propCode: 'CheckOutTime',
    classId: '673281138176827392',
    readonly: 1,
    editType: 3,
    columnWidth: null,
    id: '1837313776799551488',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: null,
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 40,
    column: 'checkOutTime',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: null,
    changeable: null,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 1,
    defaultValue: null,
    displayName: '检出时间',
    clsPropId: '1831929372789911552',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '检出时间',
    predefined: 1,
    propCode: 'checkOutTime',
    classId: '673281138176827392',
    readonly: 1,
    editType: 3,
    columnWidth: null,
    id: '1837313776799551488',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: null,
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 40,
    column: 'checkOutTime',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: null,
    changeable: null,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '修订版本',
    clsPropId: '1831929372785717254',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '修订版本',
    predefined: 1,
    propCode: 'Version',
    classId: '673281138176827392',
    readonly: 1,
    editType: 1,
    columnWidth: null,
    id: '1837313776786968577',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: null,
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 30,
    column: 'version',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: null,
    changeable: null,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '版本',
    clsPropId: '1831929372785717254',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '版本',
    predefined: 1,
    propCode: 'VersionInfo',
    classId: '673281138176827392',
    readonly: 1,
    editType: 1,
    columnWidth: null,
    id: '1837313776786968578',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: null,
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 30,
    column: 'versionInfo',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: null,
    changeable: null,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '迭代版本',
    clsPropId: '1831929372785717254',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '迭代版本',
    predefined: 1,
    propCode: 'Iteration',
    classId: '673281138176827392',
    readonly: 1,
    editType: 1,
    columnWidth: null,
    id: '1837313776786968576',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: null,
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 30,
    column: 'iteration',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: null,
    changeable: null,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '是否已检出',
    clsPropId: '1831929372798300169',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '是否已检出',
    predefined: 1,
    propCode: 'WorkingCopy',
    classId: '673281138176827392',
    readonly: 1,
    editType: 13,
    columnWidth: null,
    id: '1837313776770191360',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: '{"indeterminate":"0"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 10,
    column: 'workingCopy',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: '{"indeterminate":"0"}',
    changeable: 0,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '是否最新版本',
    clsPropId: '1831929372806688773',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '是否最新版本',
    predefined: 1,
    propCode: 'Latest',
    classId: '673281138176827392',
    readonly: 1,
    editType: 13,
    columnWidth: null,
    id: '1837316420674883584',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: '{"indeterminate":"0"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 10,
    column: 'latest',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: '{"indeterminate":"0"}',
    changeable: 0,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '检入标记',
    clsPropId: '1831929372806688773',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '是否最新版本',
    predefined: 1,
    propCode: 'CheckInState',
    classId: '673281138176827392',
    readonly: 1,
    editType: 13,
    columnWidth: null,
    id: '1837316420674883585',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: '{"indeterminate":"0"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 10,
    column: 'checkInState',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs: '{"indeterminate":"0"}',
    changeable: 0,
    belongClsId: '673281138176827392'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '零部件源对象',
    clsPropId: '1831929379320442885',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '零部件源对象',
    predefined: 1,
    propCode: 'SourcePart',
    classId: '673281139388981248',
    readonly: 1,
    editType: 23,
    columnWidth: null,
    id: '1834774218366230528',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs:
      '{"refClsCode":"Part","refObjCodes":["Number","Name","Version","drawingNumber","AssemblyMode","PartView","Source","Phase","DefaultUnitEnum","Attachment","factory","procurementtype","material","Classification","RdmExtensionType","Folder","Owner","Creator","LastUpdateTime","Description","Iteration","Modifier","CreateTime","checkOutTime","LifecycleStateCode","checkOutUserName","WorkingCopy","Latest","WorkingState","StartTime","EndTime"],"refObjNames":""}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 80,
    column: 'sourcePart',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs:
      '{"refClsCode":"Part","refObjCodes":["Number","Name","Version","drawingNumber","AssemblyMode","PartView","Source","Phase","DefaultUnitEnum","Attachment","factory","procurementtype","material","Classification","RdmExtensionType","Folder","Owner","Creator","LastUpdateTime","Description","Iteration","Modifier","CreateTime","checkOutTime","LifecycleStateCode","checkOutUserName","WorkingCopy","Latest","WorkingState","StartTime","EndTime"],"refObjNames":""}',
    changeable: 0,
    belongClsId: '673281139388981248'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '零部件目标对象',
    clsPropId: '1831929379320442885',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '零部件目标对象',
    predefined: 1,
    propCode: 'TargetPart',
    classId: '673281139388981248',
    readonly: 1,
    editType: 23,
    columnWidth: null,
    id: '1834774218366230529',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs:
      '{"refClsCode":"Part","refObjCodes":["Number","Name","Version","drawingNumber","AssemblyMode","PartView","Source","Phase","DefaultUnitEnum","Attachment","factory","procurementtype","material","Classification","RdmExtensionType","Folder","Owner","Creator","LastUpdateTime","Description","Iteration","Modifier","CreateTime","checkOutTime","LifecycleStateCode","checkOutUserName","WorkingCopy","Latest","WorkingState","StartTime","EndTime"],"refObjNames":"文档类型"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 80,
    column: 'targetPart',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs:
      '{"refClsCode":"Part","refObjCodes":["Number","Name","Version","drawingNumber","AssemblyMode","PartView","Source","Phase","DefaultUnitEnum","Attachment","factory","procurementtype","material","Classification","RdmExtensionType","Folder","Owner","Creator","LastUpdateTime","Description","Iteration","Modifier","CreateTime","checkOutTime","LifecycleStateCode","checkOutUserName","WorkingCopy","Latest","WorkingState","StartTime","EndTime"],"refObjNames":"文档类型"}',
    changeable: 0,
    belongClsId: '673281139388981248'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '零部件替换件',
    clsPropId: '1831929379320442885',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '零部件替换件',
    predefined: 1,
    propCode: 'PartViewDTO',
    classId: '673281139388981248',
    readonly: 1,
    editType: 23,
    columnWidth: null,
    id: '1834774218366230530',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs:
      '{"refClsCode":"Part","refObjCodes":["Number","Name","Version","drawingNumber","AssemblyMode","PartView","Source","Phase","DefaultUnitEnum","Attachment","factory","procurementtype","material","Classification","RdmExtensionType","Folder","Owner","Creator","LastUpdateTime","Description","Iteration","Modifier","CreateTime","checkOutTime","LifecycleStateCode","checkOutUserName","WorkingCopy","Latest","WorkingState","StartTime","EndTime"],"refObjNames":"文档类型"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 80,
    column: 'partViewDTO',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs:
      '{"refClsCode":"Part","refObjCodes":["Number","Name","Version","drawingNumber","AssemblyMode","PartView","Source","Phase","DefaultUnitEnum","Attachment","factory","procurementtype","material","Classification","RdmExtensionType","Folder","Owner","Creator","LastUpdateTime","Description","Iteration","Modifier","CreateTime","checkOutTime","LifecycleStateCode","checkOutUserName","WorkingCopy","Latest","WorkingState","StartTime","EndTime"],"refObjNames":"文档类型"}',
    changeable: 0,
    belongClsId: '673281139388981248'
  },
  {
    nullable: 0,
    defaultValue: null,
    displayName: '零部件文档关联',
    clsPropId: '1831929379320442885',
    className: 'Part',
    remark: null,
    alignType: 1,
    propName: '零部件文档关联',
    predefined: 1,
    propCode: 'DocViewDTO',
    classId: '673281139388981248',
    readonly: 1,
    editType: 23,
    columnWidth: null,
    id: '1834774218366230530',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs:
      '{"refClsCode":"Document","refObjCodes":["Number","Secondary","Name","Primary","LifecycleStateCode","Version","Modifier","LatestIteration","Owner","Creator","LastUpdateTime","checkOutTime","CreateTime","checkOutUserName","Folder","WorkingCopy","Iteration","Description","DocumentType","RdmExtensionType","WorkingState","Latest"],"refObjNames":"文档类型"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 80,
    column: 'docViewDTO',
    sample: null,
    propSource: 'BASE',
    unit: null,
    editArgs:
      '{"refClsCode":"Document","refObjCodes":["Number","Secondary","Name","Primary","LifecycleStateCode","Version","Modifier","LatestIteration","Owner","Creator","LastUpdateTime","checkOutTime","CreateTime","checkOutUserName","Folder","WorkingCopy","Iteration","Description","DocumentType","RdmExtensionType","WorkingState","Latest"],"refObjNames":"文档类型"}',
    changeable: 0,
    belongClsId: '673281139388981248'
  },

  {
    nullable: 1,
    defaultValue: null,
    displayName: '时间有效性-开始时间',
    clsPropId: '1831929435226320901',
    className: '时间有效性',
    remark: null,
    alignType: 1,
    propName: '开始时间',
    predefined: 1,
    propCode: 'StartTime',
    classId: '673281170804318208',
    readonly: 1,
    editType: 3,
    columnWidth: null,
    id: '1834104808710025216',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: '{"format":"YYYY-MM-DD HH:mm:ss","sample":"2024-09-12 13:41:46"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 40,
    column: 'startTime',
    sample: null,
    propSource: null,
    unit: null,
    editArgs: '{"format":"YYYY-MM-DD HH:mm:ss","sample":"2024-09-12 13:41:46"}',
    changeable: 0,
    belongClsId: '673281170804318208'
  },
  {
    nullable: 1,
    defaultValue: null,
    displayName: '时间有效性-结束时间',
    clsPropId: '1831929435226320900',
    className: '时间有效性',
    remark: null,
    alignType: 1,
    propName: '结束时间',
    predefined: 1,
    propCode: 'EndTime',
    classId: '673281170804318208',
    readonly: 1,
    editType: 3,
    columnWidth: null,
    id: '1834104808621944832',
    class: 'com.kmsoft.ebf.metamodel.dto.ClsLayoutPropDTO',
    classCode: null,
    propertyArgs: '{"format":"YYYY-MM-DD HH:mm:ss","sample":"2024-09-12 13:41:53"}',
    visible: 1,
    dataTypeName: null,
    dataLength: null,
    dataType: 40,
    column: 'endTime',
    sample: null,
    propSource: null,
    unit: null,
    editArgs: '{"format":"YYYY-MM-DD HH:mm:ss","sample":"2024-09-12 13:41:53"}',
    changeable: 0,
    belongClsId: '673281170804318208'
  }
]

/**对象类需要增加属性 */
const ClassProperties = new Map([
  [
    'ChangeActivity',
    ['LifecycleStateCode', 'Source', 'WorkingState', 'CheckOutTime', 'Iteration', 'Version', 'WorkingCopy', 'Latest']
  ],
  ['ChangeOrder', ['Source', 'WorkingState', 'CheckOutTime', 'Iteration', 'Version', 'WorkingCopy', 'Latest']],
  [
    'PromoteActivity',
    ['LifecycleStateCode', 'Source', 'WorkingState', 'CheckOutTime', 'Iteration', 'Version', 'WorkingCopy', 'Latest']
  ],
  ['ChangeIssue', ['CheckInState', 'VersionInfo']],
  ['BOMLink', ['Name', 'Number', 'Version', 'WorkingState', 'CheckOutTime', 'TargetPart', 'SourcePart']],
  ['BOMSubstituteLink', ['PartViewDTO', 'CheckOutTime']],
  ['PartAlterLink', ['PartViewDTO', 'CheckOutTime']],
  ['Part', ['StartTime', 'EndTime']],
  ['PartDescribeLink', ['DocViewDTO', 'CheckOutTime', 'Description']]
])
export function ClassTransition(objCls: IKClassDefinition) {
  /**
   * 优先将需要添加的属性添加到properties中
   * 1.获取原始properties
   * 2.获取需要添加的columns
   * 3.循环添加
   */
  let belongClsId = ''
  const className = objCls.className
  let classId = ''
  if (objCls.properties.length > 0) {
    belongClsId = (objCls.properties[0] as Record<string, any>)['belongClsId'] || ''
    classId = objCls.properties[0]['classId'] || ''
  }
  const objectClass = objCls
  const properties = objectClass.properties
  const columns = ClassProperties.get(objCls.classCode)
  columns &&
    columns.forEach(item => {
      const property = PropertiesList.find(x => x.propCode == item)!
      property.id = utils.uuid(16)
      property.belongClsId = belongClsId
      property.className = className
      property.classId = classId
      properties.push(property as any)
    })
  objectClass.properties = ColumnTransition(objCls.classCode, properties)
  return objectClass
}
/**字段转换 */
function ColumnTransition(classCode: string, params: Array<IKMetaPropertyDefinition>) {
  const properties = params
  const code = classCode as EnumClass
  switch (code) {
    // case EnumClass.ChangeActivity:
    // case EnumClass.ChangeIssue:
    // case EnumClass.ChangeOrder:
    // case EnumClass.ChangeRequest:
    // case EnumClass.PromoteActivity:
    //   {
    //     const property = properties.find(x => x.propCode == 'Secondary')!
    //     if (property) {
    //       property.propCode = 'SecondaryIds'
    //       property.column = 'secondaryIds'
    //     }
    //   }
    //   break
    // case EnumClass.Distribution:
    //   {
    //     const property = properties.find(x => x.propCode == 'Attachment')
    //     if (property) {
    //       property.propCode = 'SecondaryIds'
    //       property.column = 'secondaryIds'
    //     }
    //   }
    //   break
    case EnumClass.BOMLink:
    case EnumClass.BOMSubstituteLink:
      {
        const property = properties.find(x => x.propCode == 'Unit')
        if (property) {
          property.propCode = 'UnitEnum'
          property.column = 'unitEnum'
        }
      }

      break
    case EnumClass.ManagedBaseline:
      {
        const property = properties.find(x => x.propCode == 'LifecycleTemplate')
        if (property) {
          property.propCode = 'LifecycleStateTemplateDesc'
          property.column = 'lifecycleStateTemplateDesc'
        }
      }
      break
    default:
      break
  }
  return properties
}

import { computed, ref, watch } from 'vue'
import {
  ViewModelOptions,
  ToolStripItemClickedEventArgs,
  EnumToolStripCompType,
  KDataGridViewModel,
  KNotification,
  KDialog,
  SimpleViewModel,
  KOrderAdjustPanel,
  KDialogClosingEvent,
  EnumDialogResult
} from '@kmsoft/upf-core'
import { KDistributionContentObjGridEmitsType, KDistributionContentObjGridPropType } from './interface'
import {
  EnumToolStripItemKeys,
  Api,
  EnumLifecycleState,
  ObjectClientSrv,
  KObjectSelectorViewModel,
  KObjectClassGridViewModel,
  EnumWorkingState,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumClassTemplate
} from '@kmsoft/ebf-common'

/** KDistributionContentObjGrid */
export default class KDistributionContentObjGridViewModel extends SimpleViewModel<
  KDistributionContentObjGridEmitsType,
  KDistributionContentObjGridPropType
> {
  // 对象类网格
  refObjGrid = ref<KObjectClassGridViewModel>()
  modified = ref<boolean>(false)
  toolItem = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'minus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT,
      title: '排序',
      icon: 'ordered-list',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  gridData = ref<any[]>([])

  constructor(options: ViewModelOptions<KDistributionContentObjGridPropType>) {
    super(options)
    watch(
      () => this.props.readonly,
      newValue => {
        !newValue ? this.refObjGrid.value?.beginEdit() : this.refObjGrid.value?.endEdit()
        if (newValue) this.refObjGrid.value?.setToolStripDisabled(newValue as boolean)
      }
    )
  }

  viewDidMount() {
    // todo 设置工具栏直读
  }

  /**
   * 工具栏按钮点击事件
   * @param event
   */
  toolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (this.props.isDesigner) return
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.add()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.remove()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT:
        this.sort()
        break
      default:
        break
    }
  }

  /**
   * 刷新
   */
  public refresh() {
    this.refObjGrid.value?.refresh()
  }

  /**
   * 添加
   */
  add() {
    // 打开对象查找选择框，但仅限于文档跟零部件
    const objectClassTreeProps = {
      showObjClsCodes: ['Part', 'Document', 'ManagedBaseline', 'ChangeItem'],
      defaultSelectClsCode: 'Part',
      filter: {
        joiner: EnumQueryConditionJoiner.And,
        conditions: [
          {
            conditionName: 'lifecycleState.internalName',
            operator: EnumQueryConditionOperator.EQUAL,
            conditionValues: ['Released']
          }
        ]
      }
    }
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      objectClassManageQueryParam: objectClassTreeProps,
      objectSearchQueryParam: objectClassTreeProps,
      getContainer: this.refObjGrid.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 获取当前弹出组件实例的ViewModel
        const selectorViewModel = event.viewModel as KObjectSelectorViewModel
        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()

        const filterReleasedRows = this.filterReleasedData(selectedRows, true)
        if (filterReleasedRows.length == 0) {
          return
        }

        this.addGridData(filterReleasedRows)

        //刷新重新渲染
        this.refresh()
      }
    })
  }

  filterReleasedData(selectedRows: any, showWarn: boolean) {
    const filterReleasedRows: Array<Record<string, any>> = []
    if (selectedRows != null && selectedRows.length > 0) {
      selectedRows.forEach((item: any) => {
        if (item.lifecycleStateCode == 'Released') {
          filterReleasedRows.push(item)
        }
      })
    }

    if (filterReleasedRows.length == 0 && showWarn) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中没有已发布数据'
      })
      return []
    }
    return filterReleasedRows
  }

  setGridData(gridData: any[]) {
    this.gridData.value = [...gridData]
    this.gridDataFilter()
  }

  addGridData(gridData: any[]) {
    this.modified.value = true
    this.gridData.value.push(...gridData)
    this.gridDataFilter()
  }

  gridDataFilter() {
    // 去重
    const gridDataMap = this.gridData.value.reduce((acc, current) => {
      // 版本
      current.versionInfo = `${current.version}.${current.iteration}`
      // 状态
      current.state = current.state
        ? current.state
        : current.lifecycleStateCode
        ? EnumLifecycleState[current.lifecycleStateCode]
        : '--'
      // 检入标记
      current.checkInState = current.workingState
        ? EnumWorkingState[current.workingState as keyof typeof EnumWorkingState]
        : current.checkInState
        ? current.checkInState
        : '--'

      acc[current.id] = current
      return acc
    }, {})

    this.gridData.value = Object.values(gridDataMap)
    this.refresh()
  }

  /**
   * 移除零部件或者文档
   */
  remove() {
    const selectedRows = this.refObjGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      const rowIds = selectedRows.map((row: any) => row.id)
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !rowIds.some((row: any) => row === item.id))
      this.refObjGrid.value?.removeRow(rowIds)
      // 底层存在800延时, 这里设置801延时, 如果不设置延时, 会出现删除双倍数据的问题
      // 但是存在延时时, 会存在两次刷新的问题, 属于正常现象
      setTimeout(() => {
        this.refresh()
      }, 801)
      // 第一次刷新为removeRow的效果, 第二次刷新为refresh的效果
    }
  }

  loadData(qry: any) {
    return this.listDistributionContents(qry)
  }

  /**
   * 查询零部件列表
   *
   * @returns
   */
  async listDistributionContents(qry: any) {
    // 先不要分页
    return this.gridData.value
  }

  isModified(): boolean {
    // 编辑时默认认为修改
    return this.modified.value
  }

  getModifiedValue() {
    return this.getValue()
  }

  public setValue(newValue: any, setChanged?: boolean) {
    // 过滤掉非发布状态的数据
    // const filterReleasedRows = this.filterReleasedData(newValue, false)
    this.setGridData(newValue)
  }

  public getValue() {
    return this.gridData.value
  }

  /**
   * 排序
   */
  public async sort() {
    const rows = this.refObjGrid.value?.getRows()
    const { result } = await KOrderAdjustPanel.show({
      rowKey: 'id',
      list: rows,
      fields: [
        { title: '编码', id: 'number' },
        { title: '名称', id: 'name' }
      ]
    })

    const sortedItems = result.sortedItems
    this.modified.value = true
    this.gridData.value = Object.values(sortedItems)
    this.refresh()
  }

  /**
   * 强制刷新
   */
  public cleanAllForce() {
    this.setGridData([])
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.targetId || row.id,
      modelCode: row!.targetExtensionType || row.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType || row.rdmExtensionType!)
    }
    ObjectClientSrv.openObj(param)
  }
}

import lodash, { isNull } from 'lodash'
import { ref, computed, watch } from 'vue'
import { BaseViewModel, ViewModelOptions, Guid, KDialog, SelectOption } from '@kmsoft/upf-core'
import {
  EnumFilterLogicalOperation,
  EnumFilterOperatorCode,
  FILTER_LOGICAL_OPERATION_COMPARISONS,
  FILTER_OPERATOR_CODE_COMPARISONS,
  KRulePropertyEmitsType,
  KRulePropertyPropType,
  RuleItem,
  RuleResult
} from './interface'
import { EnumDataType, MetaProperty } from '../../../../client-srv'
import { OPERATORS, PropertyConverter, expressionToAst, identSet } from './tools'
import label from '@kmsoft/upf-core/src/web-controls/label'
import _ from 'lodash'

/** 规则定义 */
export default class KRulePropertyViewModel extends BaseViewModel<KRulePropertyEmitsType, KRulePropertyPropType> {
  /** 生命周期状态 */
  lifecycleOptions = ref<any>([
    {
      label: '工作中',
      value: 'InWork'
    },
    {
      label: '审核中',
      value: 'UnderReview'
    },
    {
      label: '已发布',
      value: 'Released'
    },
    {
      label: '实现中',
      value: 'Realizing'
    },
    {
      label: '已关闭',
      value: 'Closed'
    },
    {
      label: '已废弃',
      value: 'Obsoleted'
    },
    {
      id: '9eddexAjab',
      label: '已取码',
      value: 'Yiquma'
    }
  ])
  /** 复杂查询过滤的字段 */
  filterFieldArray = ref<any>([
    'IterationNote', // 迭代备注
    'lockDate', // 锁定日期
    'locker', // 锁定者
    'lockNote', // 锁定说明
    'planActivityId', // 任务活动ID
    'authorize', // 授权
    'Iteration', // 迭代版本
    'VersionCode', // 业务版本内码
    'preVersionId', // 前序版本实例ID
    'KIAGUID', // 关键信息资产ID
    'RdmVersion', // 系统版本
    'ClassName', // 类名
    'AclEntry',
    'LeafFlag',
    'RootNode',
    'FullPath',
    'ClsAttrs',
    'rdmDeleteFlag',
    'hibernateversion',
    'RdmExtensionType'
  ])
  lifecycleStates = ref<any>(['InWork', 'UnderReview', 'Released'])
  /** 规则 */
  ruleResult = ref<RuleResult>({ expression: '', rules: [] })
  /** 是否包含子类 */
  includeChildren = ref<boolean>()
  /** 过滤字段 */
  filterFields = computed((): MetaProperty[] => {
    /** 过滤掉图片列、对象列等 */
    const noDisplayFieldDataTypes = [
      EnumDataType.IMAGE,
      EnumDataType.OBJECT,
      EnumDataType.TYPE,
      EnumDataType.ENUM,
      EnumDataType.USER,
      EnumDataType.UNKNOWN
    ]
    console.log('filterFields', this.props.fields)

    const fields = this.props.fields.filter(a => !noDisplayFieldDataTypes.includes(a.type))

    //对象类型已经确定、过滤掉实体字段
    const newFiled = fields.filter((f: any) => !this.filterFieldArray.value.includes(f.code))

    newFiled.map((it: any) => {
      if (it.category === 'EXTEND') {
        it.columnName = `extAttrs.${it.columnName}`
      }
    })
    return newFiled
  })
  /** 属性值的可选项 */
  propOptions = ref<any>()
  /** 表单设置，动态计算操作符的可选项，选项是否启用 */
  formConfigs = computed(() => {
    const rules = this.ruleResult.value.rules.filter((r: RuleItem) => r.key !== 'lifecycleState.internalName')
    return rules.map(r => {
      /** 根据 属性Id 查找属性定义 */
      const field = this.getFieldByPropertyCode(r.key)
      /** 支持的获取操作符 */
      const operators = this.resolveOperatorOptions(field)
      /** 生成操作符列表 */
      return operators.map(operator => {
        const label = FILTER_OPERATOR_CODE_COMPARISONS[operator]
        return { label: label, value: operator }
      })
    })
  })
  /** 操作符 */
  logicOperators = [
    {
      label: FILTER_LOGICAL_OPERATION_COMPARISONS[EnumFilterLogicalOperation.And],
      value: EnumFilterLogicalOperation.And
    },
    {
      label: FILTER_LOGICAL_OPERATION_COMPARISONS[EnumFilterLogicalOperation.Or],
      value: EnumFilterLogicalOperation.Or
    }
  ]

  constructor(options: ViewModelOptions<KRulePropertyPropType>) {
    super(options)

    // 仅首次初始化 后续修改不刷新信息
    this.initData()

    watch(
      () => options.props.includeChild,
      (newValue, oldValue) => {
        this.onUpdateIncludeChildren(newValue)
        this.updateRule()
      },
      {
        deep: true,
        immediate: true
      }
    )

    watch(
      this.ruleResult,
      (newValue, oldValue) => {
        this.onUpdateRuleResult(newValue)
      },
      {
        deep: true
      }
    )
  }

  viewDidMount() {}

  //#region 公共方法
  /** 清空列表 */
  clear() {
    this.ruleResult.value = { expression: '', rules: [] }
  }

  /** 添加一条高级查询规则 */
  addRule() {
    // 如果过滤字段没有
    if (this.filterFields.value.length <= 0) {
      return
    }

    /** 生成唯一Id */
    const cid = Guid.newGuidString()
    /** 规则 */
    const rules = this.ruleResult.value.rules

    rules.push({
      id: cid,
      key: this.propOptions.value[0].columnName,
      dataType: this.filterFields.value[0].type,
      value: null as any,
      operator: EnumFilterOperatorCode.EQUAL,
      LogicOperator: EnumFilterLogicalOperation.And,
      isUsing: true,
      disabled: false
    })
  }

  /**
   * 检查表单完整性，包括表达式是否合法以及是否有未填写值
   * @param showMsg 是否显示消息
   * @returns
   */
  validate(showMsg: boolean = true): boolean {
    /** 规则 */
    const rules = this.ruleResult.value.rules
    /** 表达式 */
    const expression = this.ruleResult.value.expression

    // 循环规则列表
    // for (let index = 0; index < this.ruleResult.value.rules.length; index += 1) {
    //   const rule = rules[index]
    //   if (lodash.isEmpty(rule.value)) {
    //     showMsg &&
    //       KDialog.error({
    //         title: '规则错误',
    //         content: `规则【${index + 1}】值不能为空`
    //       })
    //     return false
    //   }
    // }

    // 如果列表为空则不用检查
    if (!expression || expression.length === 0) {
      return true
    }

    const maybeAst = expressionToAst(expression)

    if (maybeAst.ok) {
      const idents = identSet(maybeAst.val)
      for (const id of idents) {
        const idAsNumber = Number.parseInt(id)
        if (Number.isNaN(idAsNumber)) {
          showMsg && KDialog.error({ content: `表达式${expression}中存在未定义的标识符【${id}】` })
          return false
        }
        if (idAsNumber > rules.length) {
          showMsg && KDialog.error({ content: `表达式${expression}中引用了未定义的表达式序号【${id}】` })
          return false
        }
      }
    } else {
      showMsg && KDialog.error({ content: `表达式${expression}不合法：${maybeAst.val}` })
      return false
    }

    showMsg && KDialog.success({ content: `表单检查通过` })

    return true
  }

  /**
   * 获取过滤条件
   * @param showValidateMsg 是否显示消息
   * @returns
   */
  private getRuleResult(showValidateMsg: boolean = false): RuleResult | undefined {
    /** 检查表单 */
    const result = this.validate(showValidateMsg)

    // 如果检查不通过
    if (!result) {
      return
    }

    /** 表达式 */
    let expression = this.ruleResult.value.expression || ''
    /** 规则 */
    const rules = this.ruleResult.value.rules
    /** 过滤器 */
    const ruleItems: Array<RuleItem> = []

    // 循环规则列表
    for (const rule of rules) {
      // 如果没有使用 则不加入结果列表
      if (!rule.isUsing) {
        continue
      }

      const value = rule.value

      // 如果值为空则跳过
      if (value === undefined) {
        continue
      }

      ruleItems.push({
        ...rule
      })
    }

    // 没有表达式，就从设置的过滤器构建
    if (!expression && ruleItems.length > 0) {
      /** 操作符 */
      const collector: Array<EnumFilterLogicalOperation> = []

      // 循环规则
      for (const rule of rules) {
        if (!rule.isUsing) {
          continue
        }
        // 默认为and
        collector.push(rule.LogicOperator ?? EnumFilterLogicalOperation.And)
      }

      // 最后一个不生成连接操作符
      collector.pop()

      /** 索引 */
      let ident = 1

      // 先加入第一个对象
      expression = `${ident}`

      if (collector.length > 0) {
        for (const op of collector) {
          ident += 1
          expression += ` ${op} ${ident}`
        }
      }
    }

    return {
      rules: ruleItems,
      expression
    }
  }
  //#endregion

  //#region 私有方法

  /**
   * 初始化数据
   * @description 更新数据
   */
  initData() {
    let ruleResult: RuleResult

    // 如果外部传入了条件
    if (this.props.condition) {
      const data = PropertyConverter.convertConditionToRule(this.props.condition)

      ruleResult = { expression: data.expression, rules: data.rules }
    } else {
      ruleResult = { expression: '', rules: [] }
    }

    this.ruleResult.value = ruleResult
  }

  /**
   * 根据属性 Id 获取属性定义
   * @param columnPropId
   */
  getFieldByPropertyCode(code: string) {
    if (!code) {
      return undefined
    }
    return this.filterFields.value.find(f => f.columnName === code)!
  }

  /**
   * @param field 对象字段
   * @return 支持的操作符
   */
  private resolveOperatorOptions(field: MetaProperty | undefined) {
    if (field === undefined) {
      return []
    }
    if (field.type === EnumDataType.DATE) {
      return OPERATORS['time']
    }
    if (field.type === EnumDataType.FLOAT) {
      return OPERATORS['number']
    }
    if (field.type === EnumDataType.INT || field.type === EnumDataType.LONG) {
      return OPERATORS['number']
    }
    return OPERATORS['string']
  }

  //#endregion

  //#region 事件
  /** 检查 */
  onCheck() {
    this.validate(true)
  }

  /**
   * 属性选择以后清空文本框值
   * @param index
   */
  onNameChange(item: RuleItem) {
    // 防止 string类型值 切换到 日期编辑器 时导致日期编辑器解析出错
    item.value = undefined
    // ECOLPLM-1001【前端】对象管理-查询-复杂查询中，在首次进行运算符为“包含”的查询后，不更换运算符，仅变更查询列及查询内容，界面报错
    item.operator = this.formConfigs.value[0][0].value
  }

  /**
   * 删除该条高级查询规则
   * @param item 要删除的规则
   */
  onRemoveRule(item: RuleItem) {
    /** 规则 */
    const rules = this.ruleResult.value.rules
    /** 索引 */
    const index = rules.findIndex((r: RuleItem) => r.id === item.id)

    // 移除条目
    if (index !== -1) {
      rules.splice(index, 1)
    }
  }

  /**
   * 过滤器选项校验
   * @private
   */
  onFilterOption(input: string, option: { label: string; value: string }): boolean {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  /**
   * 规则更新事件
   * @param value
   */
  onUpdateRuleResult(value: RuleResult) {
    /** 规则结果 */
    const ruleResult = this.getRuleResult(false)

    // 如果校验不通过
    if (!ruleResult) {
      this.emit('update:condition', undefined)
      return
    }

    /** 将 Rule 转换为 Condition */
    const condition = PropertyConverter.convertRuleToCondition(ruleResult)
    this.emit('update:condition', condition)
  }

  /**
   * 包含子类更新事件
   * @param value
   */
  onUpdateIncludeChildren(value: boolean) {
    //若勾选了包含子类,则移除所有扩展属性
    if (value) {
      this.propOptions.value = this.filterFields.value
        .map(x => {
          return {
            ...x,
            label: x.displayName,
            value: x.columnName
          }
        })
        .filter((x: Record<string, any>) => !x.columnName.includes('extAttrs'))
    } else {
      this.propOptions.value = this.filterFields.value.map(x => {
        return {
          ...x,
          label: x.displayName,
          value: x.columnName
        }
      })
    }
    this.includeChildren.value = value
    this.emit('update:includeChild', value)
  }

  /** 获取输入框的值 */
  getJoiner() {
    const rulesResult = this.getRuleResult(false)
    if (this.lifecycleStates.value.length) {
      const cid = Guid.newGuidString()
      rulesResult &&
        rulesResult.rules.push({
          id: cid,
          key: 'lifecycleState.internalName',
          dataType: EnumDataType.TYPE,
          value: this.lifecycleStates.value,
          operator: EnumFilterOperatorCode.IN,
          LogicOperator: EnumFilterLogicalOperation.And,
          disabled: false,
          isUsing: true
        })
    }
    const rules = rulesResult?.rules!
    for (const rule of rules) {
      if (isNull(rule.value)) {
        rule.operator = EnumFilterOperatorCode.IS_NULL
      }
    }
    return rulesResult
  }

  /** 特殊处理,在规则中的删除生命周期状态 */
  filtrate() {
    const rules = this.ruleResult.value.rules
    const index = rules.findIndex((r: RuleItem) => r.key === 'lifecycleState.internalName')

    // 移除条目
    if (index !== -1) {
      rules.splice(index, 1)
    }

    return rules as Array<RuleItem>
  }

  /** 添加生命周期状态 */
  onUpdateJoiner() {
    console.log('onUpdateJoiner', this.lifecycleStates.value)

    const rules = this.ruleResult.value.rules
    const index = rules.findIndex(r => r.key === 'lifecycleState.internalName')
    if (index !== -1) {
      !this.lifecycleStates.value ? rules.splice(index, 1) : (rules[index].value = this.lifecycleStates.value)
    } else {
      const cid = Guid.newGuidString()
      rules.push({
        id: cid,
        key: 'lifecycleState.internalName',
        dataType: EnumDataType.TYPE,
        value: this.lifecycleStates.value,
        operator: EnumFilterOperatorCode.IN,
        LogicOperator: EnumFilterLogicalOperation.And,
        disabled: false,
        isUsing: true
      })
    }
  }
  updateRule() {
    this.ruleResult.value.rules = this.ruleResult.value.rules.map(x => {
      if (x.key.includes('extAttrs') && this.includeChildren.value) {
        return {
          ...x,
          isUsing: false,
          disabled: true
        }
      } else {
        return {
          ...x,
          disabled: false
        }
      }
    })
  }
  //#endregion
}

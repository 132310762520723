import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full flex-start-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_select, {
      value: _ctx.$vm.selectedValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.selectedValue) = $event)),
      style: {"width":"100%"},
      options: _ctx.$vm.userList,
      disabled: _ctx.disabled,
      readonly: _ctx.readonly
    }, null, 8, ["value", "options", "disabled", "readonly"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_class_grid, {
      ref: _ctx.$vm.refObjGrid,
      modelCode: "BOMLink",
      modelGroup: "part",
      readonly: true,
      onToolStripItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event)),
      toolStripItems: _ctx.$vm.toolItem,
      loadData: _ctx.$vm.loadData,
      schemaType: "BOMLink_struct-grid"
    }, null, 8, ["toolStripItems", "loadData"])
  ]))
}
import {
  Api,
  ComponentClientSrv,
  defineToolStripData,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  TREE_VIEW_ROOT_NODE_KEY
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumDialogResult,
  EnumTreeViewNodeRefreshType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KOrderAdjustPanel,
  KTreeViewViewModel,
  ToolStripItemClickedEventArgs,
  TreeViewMenuEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import lodash from 'lodash'
import { ref, watch } from 'vue'
import { KStructuredDocForm, KStructuredDocFormViewModel } from '../structured-doc-form'
import { KStructuredDocTreeEmitsType, KStructuredDocTreePropType } from './interface'
import { StructuredDocTreeNode } from './node/StructuredDocTreeNode'
import { getToolStrip } from './toolStrip'
import { EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import { SortChildPartItem } from '../../../../../ebf-part/src'
import _ from 'lodash'
import { StructureView } from '../../../client-srv'

/** KStructuredDocTree */
export default class KStructuredDocTreeViewModel extends BaseViewModel<KStructuredDocTreeEmitsType, KStructuredDocTreePropType> {
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /**数控件引入 */
  refTreeView = ref<KTreeViewViewModel>()
  /** 选择严格受控,待确定 */
  notCheckStrictly = ref<boolean>(true)
  /** 是否是多选 */
  isMultipleSelection = ref<boolean>(false)
  /** 右键菜单 */
  toolStripItems = defineToolStripData(getToolStrip())
  // toolStripItems = defineToolStripData([])

  /** 根节点 */
  rootNode: StructuredDocTreeNode
  /** 根节点 */
  highlightNodes: Array<StructuredDocTreeNode> = []
  /** 排序方式 */
  sortMode: string = ''

  /** 当前操作的节点 */
  operateNode: StructuredDocTreeNode
  /**版本规则 */
  structuredDocViewMode = ref<StructureView>()
  constructor(options: ViewModelOptions<KStructuredDocTreePropType>) {
    super(options)
    this.isMultipleSelection.value = options.props.isMultipleSelection
    watch(
      () => options.props.structuredDocViewMode,
      newValue => {
        this.refTreeView.value?.refresh()
      },
      {
        deep: true
      }
    )
  }

  get treeView() {
    return this.refTreeView.value
  }
  viewDidMount() {
    // AppContext.current
    //   .getIocContainer()
    //   .registerSingleton(EBF_IOC_KEY.SET_NODE_HIGH_LIGHT, { setSelectNode: this.setSelectNodes.bind(this) })
  }

  setSelectNodes(event: any) {
    if (event.environment != 'Document') return
    const path = event.path
    const ids = []
    const rootNode = this.refTreeView.value?.getRootNodes()[0] as StructuredDocTreeNode
    for (const id_index of path) {
      if (id_index == 'root') {
        ids.push(rootNode!.id)
      } else {
        const id = id_index.split('_')[0]
        ids.push(id)
      }
    }
    this.refTreeView.value?.expandByPath(rootNode!, ids.join(','), true)
  }

  /**
   * 菜单弹出之前
   * @param event
   */
  async onBeforeMenuPopup(event: TreeViewMenuEventArgs<StructuredDocTreeNode>) {
    /** 节点 */
    const node = event.attachParams.node as StructuredDocTreeNode
    /** 节点Id */
    const nodeId = node.id
    /** 是否是根节点 */
    const isRoot = nodeId == TREE_VIEW_ROOT_NODE_KEY
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE, !isRoot)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT, !node.leaf)
    if (node.disabled) {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH, false)
    }
    // 抛出事件
    this.emit('beforeContextMenuPopup', { ...event, node: node })
  }

  /**工具栏事件 */
  async onToolStripClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onToolStripItemClicked', event.name)
    /** 获取操作的节点 */
    const operationConfig = ComponentClientSrv.getOperationTreeNodes<StructuredDocTreeNode>(this.refTreeView.value, event)
    /** 当前右键菜单按钮操作的节点 */
    const selectedNodes = operationConfig.selectedNodes
    this.operateNode = selectedNodes[0]

    // 如果没有选中任何节点
    if (selectedNodes.length == 0) {
      return
    }
    const eventName = event.name

    const nodeKey = event.attachParams?.node?.key
    const objParam = await this.getOperationNodeObjParams(nodeKey)
    switch (eventName) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        await this.doNewStructuredDoc(objParam[0])
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        await this.doAddStructuredDoc(objParam[0])
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        await this.doRemove(event.attachParams?.node.id)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT:
        await this.doSort(objParam[0])
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refreshNode(selectedNodes[0], EnumTreeViewNodeRefreshType.NODE_CHILD)
        break
      default:
        break
    }
  }

  /**
   * 新建结构化文档分解要素
   * @param objParam 文档标识
   */
  async doNewStructuredDoc(objParam: ObjBusinessParam) {
    KDialog.show({
      title: '添加分解要素',
      size: { width: 400, height: 300 },
      props: {
        // id: row?.master?.id,
        // className: row?.className
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KStructuredDocForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const formViewModel = event.viewModel as KStructuredDocFormViewModel
        const formValue = formViewModel.getFormValue()
        //TODO 增加必填提示
        const param = {
          data: [
            {
              modelCode: 'Document',
              modelGroup: 'doc',
              documentType: 'BREAKDOWN_ELEMENTS',
              number: formValue.number,
              name: formValue.name,
              version: 'A',
              lifecycleStateCode: 'InWork',
              description: '',
              secondary: [],
              rdmExtensionType: 'Document',
              docList: [
                {
                  nodeId: 0,
                  isRoot: true,
                  name: formValue.name,
                  code: formValue.number,
                  secondary: []
                }
              ],
              folder: { id: this.operateNode.docObjParam.folderId },
              lifecycleState: {}
            }
          ]
        }
        const createResult = await Api.post('doc', 'Document', 'create', param)
        if (createResult && createResult.code == EnumRequestCode.SUCCESS) {
          const child = this.operateNode.children as StructuredDocTreeNode[]
          let orderId = 1
          if (child) {
            const orderIds = child.map(item => item.docObjParam?.orderId)
            orderId = Math.max(...orderIds) + 1
          }
          const params = {
            data: [
              [
                {
                  sourceId: objParam.id,
                  targetId: createResult.data.master.id,
                  sortId: orderId
                }
              ]
            ]
          }
          const res = await Api.post('doc', 'Document', 'batchCreateDocumentUsageLink', params)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            /** 获取选中的节点 */
            await this.refreshNode(this.operateNode, EnumTreeViewNodeRefreshType.NODE_CHILD)
          }
          return
        } else {
          KNotification.error({
            title: '系统错误',
            content: createResult.message
          })
          return false
        }
      }
    })
    // const createResult = await ObjectClientSrv.openCreateDialog({
    //   showObjClsCodes: ['Document'],
    //   folderId: this.operateNode.docObjParam.folderId,
    //   defaultSelectClsCode: 'Document'
    // })
  }

  /**
   * 添加结构化文档分解要素
   * @param objParam 文档标识
   */
  doAddStructuredDoc(objParam: ObjBusinessParam) {
    // 打开对象查找选择框，但仅限于文档
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        defaultSelectClsCode: 'Document',
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'master.documentType',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: [EnumDocType.BREAKDOWN_ELEMENTS]
            },
            {
              conditionName: 'id',
              operator: EnumQueryConditionOperator.NOT_EQUAL,
              conditionValues: [objParam.id]
            }
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document'],
        defaultSelectClsCode: 'Document'
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        // 判断是否为分解要素类型文档
        if (targetParam.documentType != EnumDocType.BREAKDOWN_ELEMENTS) {
          KNotification.warn({ message: '请选择分解要素类型文档' })
          event.cancel = true
          return
        }
        const child = this.operateNode.children as StructuredDocTreeNode[]
        let orderId = 1
        if (child) {
          const orderIds = child.map(item => item.index)
          orderId = Math.max(...orderIds) + 1
        }
        const params = {
          data: [
            [
              {
                sourceId: objParam.id,
                targetId: targetParam.master.id,
                sortId: orderId
              }
            ]
          ]
        }
        const res = await Api.post('doc', 'Document', 'batchCreateDocumentUsageLink', params)
        if (res && res.code == EnumRequestCode.SUCCESS) {
          /** 获取选中的节点 */
          await this.refreshNode(this.operateNode, EnumTreeViewNodeRefreshType.NODE_CHILD)
        }
      }
    })
  }

  /**
   * 移除结构化文档分解要素
   */
  async doRemove(nodeId: string) {
    //TODO 将id更改为关系id
    const res = await Api.post('doc', 'Document', 'batchDeleteDocumentUsageLink', { data: [[nodeId]] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      /** 获取选中的节点 */
      await this.refreshNode(this.operateNode, EnumTreeViewNodeRefreshType.NODE_PARENT)
    }
  }

  /**
   * 刷新
   */
  async doRefresh(objParam: ObjBusinessParam) {
    const selectedNode = (await this.refTreeView.value?.getSelectedNode()) as StructuredDocTreeNode
    await this.refreshNode(selectedNode, EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF)
  }

  /**
   * 排序
   */
  async doSort(objParam: ObjBusinessParam) {
    const child = this.operateNode.children as StructuredDocTreeNode[]
    const sortList = child.map(a => {
      return { id: a.id, name: a.name } as SortChildPartItem
    })
    // const sortList = child?.map(item => ({ number: item.docObjParam?.number, name: item.name, id: item.id }))
    if (child) {
      const result = await KOrderAdjustPanel.show<SortChildPartItem>({
        fields: [{ title: '名称', id: 'name' }],
        list: sortList,
        rowKey: 'id'
      })
      if (result.dialogResult == EnumDialogResult.Confirm) {
        const sortedItems = result.result.sortedItems
        const params = {
          data: [sortedItems.map((item, index) => ({ id: item.id, sortId: index }))]
        }
        const res = await Api.post('doc', 'Document', 'orderDocumentUsageLink', params)
        if (res && res.code == EnumRequestCode.SUCCESS) {
          /** 获取选中的节点 */
          await this.refreshNode(this.operateNode, EnumTreeViewNodeRefreshType.NODE_CHILD)
        }
      }
    }
    //TODO 获取排序后的数据调用接口
  }

  // /**
  //  * 容器点击事件
  //  * @param e
  //  */
  // onContainerClick(e: MouseEvent) {
  //   // 按下指定按键才能打开
  //   if (e.shiftKey && e.ctrlKey) {
  //     this.refToolStrip.value?.openToolStripManage()
  //   }
  // }
  /**
   * 节点选择后事件
   * @param event
   */
  async onAfterSelect(event: TreeViewSelectEventArgs<StructuredDocTreeNode>) {
    if (!event.node) {
      return
    }

    // 发出事件
    this.emit('afterSelect', event)
  }

  /**重载节点 */
  async refreshNode(node: StructuredDocTreeNode, refreshType: EnumTreeViewNodeRefreshType, properties?: Record<string, any>) {
    //如果刷新根节点,直接刷新整个树
    if (node.id == TREE_VIEW_ROOT_NODE_KEY) {
      await this.refTreeView.value?.refresh(undefined, true)
      return
    }
    //刷新节点
    await this.refTreeView.value?.refreshNode(node, refreshType)

    //拼接节点路径
    if (refreshType == EnumTreeViewNodeRefreshType.NODE_CHILD || refreshType == EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF) {
      const nodePath = node.id + (properties ? ',' + properties['id'] : '')
      await this.refTreeView.value?.expandByPath(node, nodePath)
      return
    }

    if (refreshType == EnumTreeViewNodeRefreshType.NODE_PARENT) {
      if (node.parent) {
        const nodePath = node.parent.id + ',' + node.id
        await this.refTreeView.value?.expandByPath(node.parent!, nodePath)
      }
    }
  }

  /**
   * 刷新节点，自己和子节点
   * @param node
   */
  async refreshStructureNode(node: StructuredDocTreeNode) {
    await this.refreshNode(node, EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF)
  }

  /** 刷新树 */
  async refreshTree() {
    // 刷新树
    await this.refTreeView.value?.refresh(undefined, true)
    // 刷新后
    this.emit('refresh')
  }

  /** 清除高亮节点 */
  clearHighlight(): void {
    if (this.highlightNodes.length == 0) {
      return
    }
    this.highlightNodes.forEach(node => (node.highLight = false))
  }
  /**
   * 根据路径高亮
   * @param path
   */
  async setHighlightByPath(path: string) {
    /** 展开节点 */
    const resultNode = (await this.refTreeView.value?.expandByPath(this.rootNode, path, false)) as
      | StructuredDocTreeNode
      | undefined

    if (resultNode) {
      this.setHighlightNodes(resultNode, true)
    }
  }

  /**
   * 设置高亮节点
   * @param nodes
   * @returns
   */
  setHighlightNodes(nodes: StructuredDocTreeNode | Array<StructuredDocTreeNode>, isClear: boolean): void {
    // 清除所有高亮节点
    if (isClear) {
      this.clearHighlight()
    }

    if (!nodes) {
      return
    }

    if (!lodash.isArray(nodes)) {
      nodes = [nodes]
    }

    const datas = nodes.map(a => {
      return {
        key: a.key,
        highLight: true
      }
    })

    // 标记高亮
    this.refTreeView.value?.modifyNode(datas)

    // 滚动到节点
    this.refTreeView.value?.scrollTo(datas[0].key, 'auto')

    this.highlightNodes = nodes
  }
  /**是否多选 */
  getIsMultipleSelection(): boolean {
    return this.isMultipleSelection.value
  }
  /** 获取级联状态 */
  getNotCheckStrictly() {
    return this.notCheckStrictly.value
  }

  /**获取排序方式 */
  getSortMode(): string {
    return this.sortMode
  }
  /**加载跟数据 */
  async onLoadData() {
    const reqParam = {
      data: [{ id: this.props.objParams?.id }]
    }
    const result = await Api.post('doc', 'Document', 'getDocTreeRootNode', reqParam)
    const nodeData = result.data!
    let fileId = ''
    let filename = ''
    if (nodeData.primary) {
      fileId = nodeData.primary[0]?.id
      filename = nodeData.primary[0]?.name
    }

    this.rootNode = new StructuredDocTreeNode()
    this.rootNode.key = TREE_VIEW_ROOT_NODE_KEY
    this.rootNode.id = TREE_VIEW_ROOT_NODE_KEY
    this.rootNode.iconType = 'setting'
    this.rootNode.docObjParam = {
      modelCode: nodeData.rdmExtensionType,
      id: nodeData.id,
      masterId: nodeData.master.id,
      modelGroup: '',
      name: nodeData.master.name,
      code: nodeData.master.number,
      version: nodeData.version,
      iteration: nodeData.iteration,
      checkout: nodeData.workingCopy,
      checkoutPath: nodeData.checkoutPath,
      orderId: nodeData.orderId,
      selected: '',
      file: {
        id: fileId,
        md5: '',
        originalFileName: filename,
        size: '',
        location: '',
        displayLocation: '',
        uploadStartTime: '',
        uploadEndTime: ''
      },
      documentType: nodeData.documentType,
      folderId: nodeData.branch.folder.id,
      workingState: nodeData.workingState
    }
    this.rootNode.parentDocObjParam = this.rootNode.docObjParam // 根节点的父就是自己
    this.rootNode.name = nodeData.master.name!
    this.rootNode.leaf = !nodeData!.hasChildren
    this.rootNode.index = nodeData.orderId
    return [this.rootNode]
  }
  // /**获取当前选中节点--多选 */
  // getCheckedNodes() {
  //   return this.refTreeView.value?.getCheckedNodes()
  // }

  // 递归获取未展开节点集合
  getAllUnExpandNodes(node: StructuredDocTreeNode): Array<StructuredDocTreeNode> {
    const list: Array<StructuredDocTreeNode> = []
    const queue: Array<StructuredDocTreeNode> = []
    //初始化队列
    queue.push(node)
    while (queue.length) {
      //删除第一个循环项
      const curNode = queue.shift()
      //如果符合条件
      if (curNode && !curNode.leaf && !curNode.children) {
        list.push(curNode)
      }
      //如果有子项，加入循环队列
      if (curNode && curNode.children) {
        queue.push(...(curNode.children as Array<StructuredDocTreeNode>))
      }
    }
    return list
  }

  /**
   * 获取操作节点的零部件对象参数
   * @param operationNodeKey 当前右键菜单操作的零部件节点的Key
   * @returns
   */
  async getOperationNodeObjParams(operationNodeKey: string): Promise<Array<ObjBusinessParam>> {
    let objParams = [] as Array<ObjBusinessParam>

    if (operationNodeKey) {
      const node = this.refTreeView.value?.getNode(operationNodeKey)! as StructuredDocTreeNode
      objParams = [node.docObjParam]
    } else {
      const node = this.refTreeView.value?.getSelectedNode() as StructuredDocTreeNode
      objParams = node ? [node.docObjParam] : []
    }

    return objParams
  }
}

import {
  EnumLayoutSchemaType,
  ObjBusinessBase,
  ObjBusinessResult,
  ObjBusinessWrapper,
  ObjectPropertyConfig
} from '../../client-srv'
import {
  SimpleViewEventEmits,
  SimpleViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

//#region 其他定义
/** LoadData 加载参数 */
export type ObjectLoadDataProviderParams = {}

/** 面板值加载 */
export type ObjectLoadDataProvider = (params: ObjectLoadDataProviderParams) => Promise<ObjBusinessResult | undefined>
//#endregion

//#region 组件定义
/** 属性 */
export const KObjectPropertyPanelPropOptions = {
  ...SimpleViewPropOptions,
  /** 对象类ID */
  modelCode: VuePropTypes.string()
    .setRequired()
    .def(),
  /** 对象类分组 */
  modelGroup: VuePropTypes.string().def(),
  /** 布局类型 */
  schemaType: VuePropTypes.createType<EnumLayoutSchemaType>().def(EnumLayoutSchemaType.FormEdit),
  /** 面板值加载 */
  loadData: VuePropTypes.func<ObjectLoadDataProvider>()
    .setRequired()
    .def(),
  /** 列配置 */
  propertyConfig: VuePropTypes.array<ObjectPropertyConfig>().def(),
  /**是否只读 */
  readonly: VuePropTypes.bool().def(false),
  /** 是否添加扩展属性 **/
  extendedAttributes: VuePropTypes.bool().def(false),
  /** 扩展属性添加的位置 */
  extendedPosition: VuePropTypes.number().def()
}

/** 属性类型 */
export type KObjectPropertyPanelPropType = ViewPropsTypeExtract<typeof KObjectPropertyPanelPropOptions>

/** 事件 */
export const KObjectPropertyPanelEventEmits = {
  ...SimpleViewEventEmits,
  loaded: () => true
}

/** 基础视图模型事件协议 */
export type KObjectPropertyPanelEventEmitsType = ViewEmitsTypeExtract<typeof KObjectPropertyPanelEventEmits>
//#endregion

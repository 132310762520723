import {
  BaseViewModel,
  EnumItemChangeCancelType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KChangePromoteActivityEditEmitsType, KChangePromoteActivityEditPropType } from './interface'
import { ref } from 'vue'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { WorkflowClientSrv } from '../../../../../ebf-workflow/src/client-srv'

/** KPromoteActivityEdit */
export default class KPromoteActivityEditViewModel extends BaseViewModel<
  KChangePromoteActivityEditEmitsType,
  KChangePromoteActivityEditPropType
> {
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  taskParam = ref<ObjBusinessParam>()
  originFormData = ref<any>()
  toolStripItems = ref<ObjectToolStripItem[]>(ToolStripHelper.getChangePromoteActivityEditToolStripItems())

  constructor(options: ViewModelOptions<KChangePromoteActivityEditPropType>) {
    super(options)
    this.taskParam.value = options.props.objParam
  }

  viewDidMount() {}

  async loadData() {
    const result = (await Api.post('change', 'PromoteActivity', 'get', { data: [this.props.objParam.id] })) as any
    console.log(this.refObjectProperty.value)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data

      if (data.lifecycleStateCode === 'Released' || data.lifecycleStateCode === 'UnderReview') {
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
        this.disabledEdit()
      }
      if (data.needDate) {
        data.needDate = data.needDate.slice(0, 10)
      }
      this.originFormData.value = data
      return data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message,
        details: result.detail
      })
      return
    }
  }

  async loaded() {
    // 扩展属性不可编辑
    const extendAttrs = ['_#CurrentPhase', '_#TargetingPhase']
    extendAttrs.forEach(item => {
      const extendAttrsViewModel = this.getByRecursion(item) as any
      extendAttrsViewModel.setDisabled(true)
    })
  }

  /** 禁用编辑**/
  disabledEdit() {
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const buttonName = event.name as EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE
    const method = this[buttonName]
    if (typeof method === 'function') {
      method.call(this, event)
    }
  }

  /** 保存 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE](event: ToolStripItemClickedEventArgs) {
    this.save()
    // this.refObjectProperty.value?.setReadOnly(true)
  }

  /** 刷新 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH](event: ToolStripItemClickedEventArgs) {
    this.refObjectProperty.value?.refresh()
  }

  /** 加入流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.addWorkflow(
      this.taskParam.value!.id,
      'PromoteActivity',
      this.originFormData.value.lifecycleStateCode == 'Released' ? 'Released' : '',
      this.originFormData.value.name,
      this.originFormData.value.number
    )
  }

  /** 查看流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.viewWorkflow(this.taskParam.value!.id, 'PromoteActivity')
  }

  /** 修改状态 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE](event: ToolStripItemClickedEventArgs) {
    KNotification.info({
      message: '功能正在开发中，敬请期待'
    })
  }

  onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY) {
      if (event.value) {
        this.refObjectProperty.value?.setReadOnly(false)
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
      } else {
        this.save(false)
        this.refObjectProperty.value?.setReadOnly(true)
        event.itemCancelType = EnumItemChangeCancelType.CancelAll
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
      }
    }
  }

  async save(isSave: boolean = true) {
    const formValue = this.refObjectProperty.value?.getValue() as any
    const modifiedValue = this.refObjectProperty.value?.getModifiedValue() as any
    if (modifiedValue.extAttrs && modifiedValue.extAttrs.length > 0) {
      KNotification.warn({
        message: '系统提示',
        description: '当前阶段和目标阶段不能修改'
      })
      return
    }
    const extAttrs = formValue?.extAttrs
    let checkChangeBefore = false
    let checkChangeAfter = false
    //如果有修改对
    if (Object.keys(modifiedValue).length) {
      // 附件
      if (Object.keys(modifiedValue).includes('secondary')) {
        modifiedValue.secondaryIds = [...modifiedValue.secondary]
        Reflect.deleteProperty(modifiedValue, 'secondary')
      }

      if (Object.keys(formValue).includes('plmChangeBeforeTargetViews')) {
        modifiedValue!.changeBeforeLinkViews = (formValue!.plmChangeBeforeTargetViews as any[]).map(item => {
          if (item.className == 'Part' && item.phase != extAttrs[0].value) {
            checkChangeBefore = true
          }
          return {
            masterId: item?.master?.id,
            branchId: item?.branch?.id,
            target: {
              id: item.id,
              className: item.className,
              rdmExtensionType: item.rdmExtensionType
            }
          }
        })

        Reflect.deleteProperty(modifiedValue, 'plmChangeBeforeTargetViews')
      }

      if (Object.keys(formValue).includes('plmChangeAfterTargetViews')) {
        modifiedValue!.changeAfterLinkViews = (formValue!.plmChangeAfterTargetViews as any[]).map(item => {
          if (item.className == 'Part' && item.phase != extAttrs[1].value) {
            checkChangeAfter = true
          }
          return {
            masterId: item?.master?.id,
            target: {
              id: item?.branch?.id,
              rdmExtensionType: item?.branch?.rdmExtensionType
            }
          }
        })

        Reflect.deleteProperty(modifiedValue, 'plmChangeAfterTargetViews')
      }

      /** 检查改前改后对象与当前阶段和目标阶段是否一致 */
      if (checkChangeBefore) {
        KNotification.warn({
          message: '系统提示',
          description: '改前对象存在和当前阶段不一致的数据'
        })
        return
      }
      if (checkChangeAfter) {
        KNotification.warn({
          message: '系统提示',
          description: '改后对象存在和目标阶段不一致的数据'
        })
        return
      }

      const params = {
        data: [
          {
            id: this.props.objParam.id,
            ...modifiedValue
          }
        ]
      }

      const result = await Api.post('change', 'PromoteActivity', 'update', params)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.refObjectProperty.value?.refresh()
        KNotification.success('操作成功！')
      } else {
        KNotification.error({
          title: '系统错误',
          content: result.message
        })
      }
    } else {
      if (isSave)
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
    }
  }
}
